import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { hasRole } from "@/utils/groupUtils";
import { Role } from "@domain/enum/role";
import { useAnnuaireStore } from "./annuaireModule.pinia";
import userApi from "@/api/userApi";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { UserEventType } from "@domain/enum/userEventType";
import { UserAbsence } from "@domain/dto/userAbsence";
import { ThrottleResult } from "@/apiRequest";
import { UserWithAbsence } from "@domain/dto/userWithAbsence";
import { Group } from "@domain/dto/group";
import logger from "@/logger.ts";

type UserProfileStoreState = {
  user: UserWithAbsence;
};

const initialUserState: UserWithAbsence = {
  enabled: true,
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  jobTitle: "",
  idProfileImage: "",
  disabledNotifications: [],
  disabledEmails: [],
  disabledNotificationGroups: [],
  disabledEmailGroups: [],
  enabledSoundNotification: true,
  referentOnlyNotifications: false,
  groups: [],
  roles: [],
  enabledOtpMail: false,
  enabledOperationDocumentZipByCategory: false,
  absence: {
    enabled: false,
    idUser: "",
  },
};
const PROFILE_UPDATE_FAILURE_MESSAGE =
  "Une erreur est survenue lors de la modification de vos préférences.";

export const useUserProfileStore = defineStore(ModuleType.UserProfile, {
  state: (): UserProfileStoreState => ({
    user: initialUserState,
  }),
  getters: {
    getUserProfile(): UserWithAbsence {
      return this.user;
    },

    getRoles(): Role[] {
      return this.user.roles;
    },

    isProfilePool(): boolean {
      return hasRole(this.getRoles, Role.POOL);
    },

    isProfilePoolOnly(): boolean {
      return (
        hasRole(this.getRoles, Role.POOL) &&
        !hasRole(this.getRoles, Role.BANQUE)
      );
    },

    isProfileSuperUser(): boolean {
      return hasRole(this.user.roles, Role.SUPER_USER);
    },

    isProfileSignataire(): boolean {
      return hasRole(this.user.roles, Role.SIGNATAIRE);
    },

    isProfileGroupAdmin(): boolean {
      return hasRole(this.user.roles, Role.GROUP_ADMIN);
    },

    isUserProfileInformationReady(): boolean {
      return this.getUserProfile.id !== "";
    },

    isUserInParentGroup(): boolean {
      const idGroupList = this.getUserProfile.groups.map((group) => group.id);
      const groupTree = useAnnuaireStore().getPrimaryGroup;

      return (
        groupTree &&
        idGroupList.includes(groupTree.id) &&
        groupTree.children.length > 0
      );
    },

    getUserBanqueGroup(): Group | undefined {
      return this.getUserProfile.groups.find((group) =>
        group.roles.includes(Role.BANQUE)
      );
    },

    getUserBanqueOrPromoteurGroup(): Group | undefined {
      return this.getUserProfile.groups.find(
        (group) =>
          group.roles.includes(Role.BANQUE) ||
          group.roles.includes(Role.PROMOTEUR)
      );
    },
  },
  actions: {
    SetUserProfile(user: UserWithAbsence): void {
      this.user = user;
    },
    ResetUserProfile(): void {
      this.user = initialUserState;
    },

    async fetchUserProfileImage(idImage: string): Promise<string> {
      return userApi
        .fetchUserProfileImage(idImage)
        .then((response) => response.data)
        .catch(() => {
          return "";
        });
    },

    async deleteUserProfileImage(idImage: string): Promise<void> {
      if (idImage !== "") {
        return userApi
          .deleteUserProfileImage(this.user.id, idImage)
          .then(() =>
            toastSuccess("Votre photo de profil a été supprimé avec succès !")
          )
          .catch(() => {
            toastError(
              "Une erreur est survenue à la suppression de votre photo de profil"
            );
          });
      }
    },

    async updateUserProfileImage(payload: { file: Blob }): Promise<string> {
      return userApi
        .updateUserProfileImage(this.user.id, payload.file)
        .then(async (response) => {
          return response.data;
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue au chargement de votre photo de profil"
          );
          return "";
        });
    },

    async fetchUserProfile(idUser: string): Promise<void> {
      return userApi
        .fetchUserProfile(idUser)
        .then((response) => {
          this.SetUserProfile(response.data);
        })
        .catch(() => {
          toastError("Erreur, à la recherche de votre profil");
          this.ResetUserProfile();
        });
    },

    async updatePhoneNumber(payload: {
      idUser: string;
      phoneNumber: string;
    }): Promise<void> {
      const previousPhoneNumber = this.getUserProfile.phoneNumber;
      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          phoneNumber: previousPhoneNumber,
        });
      };

      this.SetUserProfile({
        ...this.getUserProfile,
        phoneNumber: payload.phoneNumber,
      });

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { phoneNumber: payload.phoneNumber }
        );

        if (response.status !== 200) {
          toastError(
            "Une erreur est survenue à la modification de votre numéro de telephone"
          );
          rollback();
          return;
        }

        toastSuccess("Votre numéro de téléphone a été modifié avec succès !");
      } catch (err) {
        rollback();
        toastError(
          "Une erreur est survenue à la modification de votre numéro de telephone"
        );
      }
    },

    async updateJobTitle(payload: {
      idUser: string;
      jobTitle: string;
    }): Promise<void> {
      const previousJobTitle = this.getUserProfile.jobTitle;
      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          jobTitle: previousJobTitle,
        });
      };
      this.SetUserProfile({
        ...this.getUserProfile,
        jobTitle: payload.jobTitle,
      });

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { jobTitle: payload.jobTitle }
        );

        if (response.status !== 200) {
          toastError(
            "Une erreur est survenue à la modification de votre fonction"
          );
          rollback();
          return;
        }

        toastSuccess("Votre fonction a été modifié avec succès !");
      } catch (err) {
        if (err !== ThrottleResult.THROTTLED) {
          rollback();
          toastError(
            "Une erreur est survenue à la modification de votre fonction"
          );
        }
      }
    },

    async updateAbsence(payload: { absence: UserAbsence }): Promise<void> {
      const previousAbsence = this.getUserProfile.absence;
      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          absence: previousAbsence,
        });
      };

      try {
        const response = await userApi.updateAbsence(
          this.getUserProfile.id,
          payload.absence
        );

        if (response.status !== 201) {
          toastError(
            "Une erreur est survenue à la modification de votre message d'absence"
          );
          rollback();
          return;
        }

        this.SetUserProfile({
          ...this.getUserProfile,
          absence: response.data,
        });
      } catch (err) {
        rollback();
        toastError(
          "Une erreur est survenue à la modification de votre message d'absence"
        );
      }
    },

    async togglePreferenceNotificationType(payload: {
      event: UserEventType;
      disabled: boolean;
    }): Promise<void> {
      const previousDisabledNotifications =
        this.getUserProfile.disabledNotifications;
      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          disabledNotifications: previousDisabledNotifications,
        });
      };

      const disabledNotifications: UserEventType[] = payload.disabled
        ? this.getUserProfile.disabledNotifications.filter(
            (disabledNotification: UserEventType) =>
              disabledNotification !== payload.event
          )
        : [...this.getUserProfile.disabledNotifications, payload.event];

      this.SetUserProfile({
        ...this.getUserProfile,
        disabledNotifications,
      });

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { disabledNotifications }
        );

        if (response.status !== 200) {
          rollback();
          toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },

    async togglePreferenceNotificationReferentOnly(payload: {
      referentOnlyNotifications: boolean;
    }): Promise<void> {
      this.SetUserProfile({
        ...this.getUserProfile,
        referentOnlyNotifications: payload.referentOnlyNotifications,
      });

      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          referentOnlyNotifications: !payload.referentOnlyNotifications,
        });
      };

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { referentOnlyNotifications: payload.referentOnlyNotifications }
        );

        if (response.status !== 200) {
          rollback();
          toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },

    async updatePreferenceOtpMailNotification(payload: {
      enabledOtpMail: boolean;
    }): Promise<void> {
      this.SetUserProfile({
        ...this.getUserProfile,
        enabledOtpMail: payload.enabledOtpMail,
      });

      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          enabledOtpMail: !payload.enabledOtpMail,
        });
      };

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { enabledOtpMail: payload.enabledOtpMail }
        );

        if (response.status !== 200) {
          rollback();
          toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },

    async updateNotificationSound(payload: {
      enabledSoundNotification: boolean;
    }): Promise<void> {
      this.SetUserProfile({
        ...this.getUserProfile,
        enabledSoundNotification: payload.enabledSoundNotification,
      });

      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          enabledSoundNotification: !payload.enabledSoundNotification,
        });
      };

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { enabledSoundNotification: payload.enabledSoundNotification }
        );

        if (response.status !== 200) {
          throw Error();
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },

    async togglePreferenceEmailType(payload: {
      event: UserEventType;
      enabled: boolean;
    }): Promise<void> {
      const previousDisabledEmails = this.getUserProfile.disabledEmails;
      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          disabledEmails: previousDisabledEmails,
        });
      };

      const disabledEmails: UserEventType[] = payload.enabled
        ? [...this.getUserProfile.disabledEmails, payload.event]
        : this.getUserProfile.disabledEmails.filter(
            (disabledNotification: UserEventType) =>
              disabledNotification !== payload.event
          );
      this.SetUserProfile({
        ...this.getUserProfile,
        disabledEmails: disabledEmails,
      });

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { disabledEmails }
        );

        if (response.status !== 200) {
          throw Error();
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },
    async togglePreferenceSectionNotificationType(payload: {
      eventList: UserEventType[];
      enabled: boolean;
    }): Promise<void> {
      const { eventList, enabled } = payload;

      const previousDisabledNotifications =
        this.getUserProfile.disabledNotifications;
      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          disabledNotifications: previousDisabledNotifications,
        });
      };

      const disabledNotifications: UserEventType[] = enabled
        ? [...this.getUserProfile.disabledNotifications, ...payload.eventList]
        : this.getUserProfile.disabledNotifications.filter(
            (disabledNotification: UserEventType) =>
              !eventList.includes(disabledNotification)
          );

      this.SetUserProfile({
        ...this.getUserProfile,
        disabledNotifications,
      });

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { disabledNotifications }
        );

        if (response.status !== 200) {
          rollback();
          toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },

    async togglePreferenceSectionEmailType(payload: {
      eventList: UserEventType[];
      enabled: boolean;
    }): Promise<void> {
      const { eventList, enabled } = payload;

      const previousDisabledEmails = this.getUserProfile.disabledEmails;
      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          disabledEmails: previousDisabledEmails,
        });
      };

      const disabledEmails: UserEventType[] = enabled
        ? [...this.getUserProfile.disabledEmails, ...eventList]
        : this.getUserProfile.disabledEmails.filter(
            (disabledNotification: UserEventType) =>
              !eventList.includes(disabledNotification)
          );
      this.SetUserProfile({
        ...this.getUserProfile,
        disabledEmails: disabledEmails,
      });

      try {
        const response = await userApi.updateAttributes(
          this.getUserProfile.id,
          { disabledEmails }
        );
        if (response.status !== 200) {
          throw Error();
        }
      } catch (error) {
        logger.error(error);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },
    async updatePreferenceEventGroup(payload: {
      idUser: string;
      disabledNotificationGroups: string[];
      disabledEmailGroups: string[];
    }): Promise<void> {
      const backupDisabledNotificationGroups = [
        ...this.getUserProfile.disabledNotificationGroups,
      ];
      const backupDisabledEmailGroups = [
        ...this.getUserProfile.disabledEmailGroups,
      ];

      this.SetUserProfile({
        ...this.getUserProfile,
        disabledNotificationGroups: payload.disabledNotificationGroups,
        disabledEmailGroups: payload.disabledEmailGroups,
      });

      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          disabledNotificationGroups: backupDisabledNotificationGroups,
          disabledEmailGroups: backupDisabledEmailGroups,
        });
      };

      try {
        const response = await userApi.updateAttributes(payload.idUser, {
          disabledNotificationGroups: payload.disabledNotificationGroups,
          disabledEmailGroups: payload.disabledEmailGroups,
        });

        if (response.status !== 200) {
          throw Error();
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },

    async updateEnabledOperationDocumentZipByCategory(
      idUser: string,
      enabledOperationDocumentZipByCategory: boolean
    ): Promise<void> {
      const previousEnabledOperationDocumentZipByCategory =
        this.getUserProfile.enabledOperationDocumentZipByCategory;

      this.SetUserProfile({
        ...this.getUserProfile,
        enabledOperationDocumentZipByCategory,
      });

      const rollback = (): void => {
        this.SetUserProfile({
          ...this.getUserProfile,
          enabledOperationDocumentZipByCategory:
            previousEnabledOperationDocumentZipByCategory,
        });
      };

      try {
        const response = await userApi.updateAttributes(idUser, {
          enabledOperationDocumentZipByCategory,
        });

        if (response.status !== 200) {
          throw Error();
        }
      } catch (err) {
        rollback();
        toastError(PROFILE_UPDATE_FAILURE_MESSAGE);
      }
    },
  },
});
