/** équivalent lodash.groupBy itérable de manière typée */
export function groupInMap<T, R>(
  collection: Array<T>,
  iteratee: (value: T) => R,
): Map<R, T[]> {
  const result = new Map<R, T[]>();

  for (const item of collection) {
    const key = iteratee(item);
    const group = result.get(key) ?? [];
    group.push(item);
    result.set(key, group);
  }

  return result;
}

/** équivalent lodash.groupBy itérable de manière typée avec des valeurs uniques */
export function groupUniqInMap<T, R>(
  collection: Array<T>,
  iteratee: (value: T) => R,
): Map<R, T> {
  const result = new Map<R, T>();

  for (const item of collection) {
    const key = iteratee(item);
    if (!result.has(key)) {
      result.set(key, item);
    }
  }

  return result;
}

export function setEmptyToNull<T extends Record<string, unknown>>(
  object: T,
): T {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      value === "" ? null : value,
    ]),
  ) as T;
}
