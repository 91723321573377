import { Notification } from "@/domain/notification";
import { NotificationPayload } from "@/domain/notificationPayload";
import { NotificationDto } from "@/domain/dto/notificationDto";
import { DemandeType } from "@domain/enum/demandeType";
import { UserEventType } from "@domain/enum/userEventType";
import { User } from "@domain/dto/user";
import NotificationSoundMP3 from "../assets/sounds/notificationSound.mp3";

const demandeCategoryByDemandeType = new Map([
  [DemandeType.GARANTIE_FINANCIERE_ACHEVEMENT, "GFA"],
  [DemandeType.CAUTION_INDEMNITE_IMMOBILISATION, "Caution"],
  [DemandeType.EASY_CAUTION_INDEMNITE_IMMOBILISATION, "Caution"],
  [DemandeType.GARANTIE_PAIEMENT_TERME, "Caution"],
  [DemandeType.CAUTION_PAIEMENT_TRAVAUX, "Caution"],
  [DemandeType.AUTRE_CAUTION, "Caution"],

  [DemandeType.CREDIT_TERRAIN, "Crédit"],
  [DemandeType.CREDIT_ACCOMPAGMENT, "Crédit"],
  [DemandeType.AUTRE_CREDIT, "Crédit"],
]);

export function toCategory(demandeType: DemandeType): string {
  const category = demandeCategoryByDemandeType.get(demandeType);
  return category || "type not found";
}

export function notificationPayloadHandler(
  notificationDtoList: NotificationDto[]
): Notification[] {
  return notificationDtoList.map((notificationDto) => {
    return {
      ...notificationDto,
      payload: notificationPayloadParser(notificationDto.payload),
    };
  });
}

export function notificationPayloadParser(
  stringPayload: string
): NotificationPayload {
  const parsedPayload = JSON.parse(stringPayload);
  return parsedPayload as NotificationPayload;
}

export function computeSingleDocumentUploadedNotificationLabel(
  notification: Notification
): string {
  return `${notification.payload.numberUploadedDocuments} document a été déposé dans l'opération`;
}

export function computeMultipleDocumentUploadedNotificationLabel(
  notification: Notification
): string {
  return `${notification.payload.numberUploadedDocuments} documents ont été déposés dans l'opération`;
}

export function isUserJoiningOperationNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.USER_JOINING_OPERATION;
}

export function isUserJoiningOperationWithNewPartnerNotif(
  userEventType: UserEventType
): boolean {
  return (
    userEventType === UserEventType.USER_JOINING_OPERATION_WITH_NEW_PARTNER
  );
}

export function isUserMessageMentionNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.USER_MESSAGE_MENTION;
}

export function isOperationDocumentUploadedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.OPERATION_DOCUMENT_UPLOADED;
}

export function isDemandeProjectUploadedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.DEMANDE_PROJECT_UPLOADED;
}

export function isDemandeProjectValidatedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.DEMANDE_PROJECT_VALIDATED;
}

export function isDemandeSignMethodChosenNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.DEMANDE_SIGN_METHOD_CHOSEN;
}

export function isDemandeSignedNotif(userEventType: UserEventType): boolean {
  return (
    userEventType === UserEventType.DEMANDE_SIGNED ||
    userEventType === UserEventType.POOL_DEMANDE_SIGNED
  );
}

export function isDemandeOutdatedNotif(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.DEMANDE_DATE_LIVRAISON_DEPASSEE;
}

export function isDemandeSharedWithBanqueNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.DEMANDE_SHARED_WITH_BANQUE;
}

export function isLettreAccordProjectUploadedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.LETTRE_ACCORD_PROJECT_UPLOADED;
}

export function isLettreAccordValidatedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.LETTRE_ACCORD_VALIDATED;
}

export function isLettreAccordSignReadyNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.LETTRE_ACCORD_SIGN_READY;
}

export function isLettreAccordSignedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.LETTRE_ACCORD_SIGNED;
}

export function isSureteCreatedNotif(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.SURETE_CREATED;
}

export function isSureteProjectUploadedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.SURETE_PROJECT_UPLOADED;
}

export function isSureteProjectValidatedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.SURETE_PROJECT_VALIDATED;
}

export function isSureteSignReadyNotif(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.SURETE_SIGN_READY;
}

export function isSureteSignedNotif(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.SURETE_SIGNED;
}

export function isActeSecondaireCreatedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ACTE_SECONDAIRE_CREATED;
}

export function isOperationChatSummaryNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.DAILY_OPERATION_MESSAGE_SUMMARY;
}

export function isMainleveeRequestNotif(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.DEMANDE_MAINLEVEE_REQUEST;
}

export function isMainleveeDocumentUploadedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.DEMANDE_MAINLEVEE_DOCUMENT_UPLOADED;
}

export function isMainleveeRefusedNotif(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.DEMANDE_MAINLEVEE_REFUSED;
}

export function isMainleveeValidatedNotif(
  userEventType: UserEventType
): boolean {
  return (
    userEventType === UserEventType.DEMANDE_MAINLEVEE_VALIDATED ||
    userEventType === UserEventType.POOL_DEMANDE_MAINLEVEE_VALIDATED
  );
}

export function isAccordCommercialProjetAjouteNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ACCORD_COMMERCIAL_PROJET_AJOUTE;
}

export function isAccordCommercialProjetValideNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ACCORD_COMMERCIAL_PROJET_VALIDE;
}

export function isAccordCommercialProjetDeclinedNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ACCORD_COMMERCIAL_PROJET_DECLINED;
}

export function isAccordCommercialProjetPretASigneNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ACCORD_COMMERCIAL_PROJET_PRET_A_SIGNE;
}

export function isAccordCommercialProjetSigneNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ACCORD_COMMERCIAL_PROJET_SIGNE;
}

export function isPoolAccordCommercialNotif(
  userEventType: UserEventType
): boolean {
  return [
    UserEventType.ACCORD_COMMERCIAL_PROJET_AJOUTE,
    UserEventType.ACCORD_COMMERCIAL_PROJET_VALIDE,
    UserEventType.ACCORD_COMMERCIAL_PROJET_DECLINED,
    UserEventType.ACCORD_COMMERCIAL_PROJET_PRET_A_SIGNE,
    UserEventType.ACCORD_COMMERCIAL_PROJET_SIGNE,
  ].includes(userEventType);
}

export function isPoolJoinDemande(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.POOL_JOIN_DEMANDE;
}

export function isCmpNewDocument(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.CONDITION_MISE_EN_PLACE_NEW_DOCUMENT;
}

export function isCmpSureteSigned(userEventType: UserEventType): boolean {
  return userEventType === UserEventType.CONDITION_MISE_EN_PLACE_SURETE_SIGNED;
}

export function isSuiviTravauxEtapeDeclaration(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.SUIVI_DE_TRAVAUX_STEP_VALIDATION;
}

export function isSuiviCommercialisationNewImport(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.SUIVI_DE_COMMERCIALISATION_NEW_IMPORT;
}

export function isSuiviCommercialisationEditionTranche(
  userEventType: UserEventType
): boolean {
  return (
    userEventType === UserEventType.SUIVI_DE_COMMERCIALISATION_EDITION_TRANCHE
  );
}

export function isEsgNouvelleVersionNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ESG_FORM_NEW_VERSION;
}

export function isEsgBanqueAskPromoteurForForm(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.ESG_FORM_ASKED_BY_BANQUE;
}

export function isOperationArchivageAuto(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.OPERATION_AUTO_ARCHIVE;
}

export function isOperationArchivedByPartner(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.OPERATION_ARCHIVED_BY_PARTNER;
}

export function isSuiviBudgetNotification(
  userEventType: UserEventType
): boolean {
  return [
    UserEventType.SUIVI_BUDGET_PROMOTEUR_IMPORT,
    UserEventType.SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_SUCCES,
    UserEventType.SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_ERREUR,
  ].includes(userEventType);
}

export function isSuiviBudgetPromoteurImportNotif(
  userEventType: UserEventType
): boolean {
  return userEventType === UserEventType.SUIVI_BUDGET_PROMOTEUR_IMPORT;
}

export function isSuiviBudgetPromoteurActualisationSucces(
  userEventType: UserEventType
): boolean {
  return (
    userEventType === UserEventType.SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_SUCCES
  );
}

export function isSuiviBudgetPromoteurActualisationErreur(
  userEventType: UserEventType
): boolean {
  return (
    userEventType === UserEventType.SUIVI_BUDGET_PROMOTEUR_ACTUALISATION_ERREUR
  );
}

export function getUniqueUserIdListFromNotificationList(
  notifications: Notification[]
): string[] {
  const userIdList: string[] = [];
  for (const notification of notifications) {
    const possibleReferentIdList = [
      notification.referentPromoteur1,
      notification.referentPromoteur2,
      notification.referentPromoteur3,
      notification.referentBanque1,
      notification.referentBanque2,
      notification.referentBanque3,
    ].filter((referent) => !!referent) as string[];

    for (const referentId of possibleReferentIdList) {
      if (!userIdList.includes(referentId)) {
        userIdList.push(referentId);
      }
    }
  }

  return userIdList;
}

export function buildToastMessageForSoundNotification(value: boolean): string {
  const active = value ? "activé" : "désactivé";
  return `Son ${active} pour les notifications !`;
}

export async function playSoundOnNewNotification(): Promise<void> {
  const audio = new Audio(NotificationSoundMP3);
  await audio.play();
}

export function isUserNotificationReferent(
  user: User,
  notification: Notification
): boolean {
  return (
    notification.referentBanque1 === user.id ||
    notification.referentBanque2 === user.id ||
    notification.referentBanque3 === user.id ||
    notification.referentPromoteur1 === user.id ||
    notification.referentPromoteur2 === user.id ||
    notification.referentPromoteur3 === user.id
  );
}

export function redirectFromNotification(
  notification: Notification,
  notificationPayload: NotificationPayload
): {
  path: string;
  query: { [key: string]: string };
} {
  let path = "";
  let query = {};
  if (notificationPayload.idOperation) {
    if (notification.type === UserEventType.USER_JOINING_OPERATION) {
      path = `/operations/${notificationPayload.idOperation}/demandes`;
      query = { chatOpen: "true" };
    } else if (notification.type === UserEventType.USER_MESSAGE_MENTION) {
      path = `/operations/${notificationPayload.idOperation}/demandes`;
      query = { chatOpen: "true", notificationRedirect: "true" };
    } else if (
      notification.type === UserEventType.POOL_DEMANDE_SIGNED ||
      notification.type === UserEventType.POOL_DEMANDE_MAINLEVEE_VALIDATED
    ) {
      path = `/operations/${notificationPayload.idOperation}/pool`;
    } else if (notificationPayload.idDemande) {
      path = `/operations/${notificationPayload.idOperation}/demandes/${notificationPayload.idDemande}`;
      query = { chatOpen: "true", notificationRedirect: "true" };
    } else if (notificationPayload.idLettreAccord) {
      path = `/operations/${notificationPayload.idOperation}/lettres-accord/${notificationPayload.idLettreAccord}`;
      query = { chatOpen: "true", notificationRedirect: "true" };
    } else if (
      [
        UserEventType.OPERATION_DOCUMENT_UPLOADED,
        UserEventType.CONDITION_MISE_EN_PLACE_NEW_DOCUMENT,
      ].includes(notification.type)
    ) {
      path = `/operations/${notificationPayload.idOperation}/documents`;
    } else if (
      notification.type === UserEventType.DAILY_OPERATION_MESSAGE_SUMMARY
    ) {
      path = `/operations/${notificationPayload.idOperation}/demandes`;
      query = { chatOpen: "true", notificationRedirect: "true" };
    } else if (isPoolJoinDemande(notification.type)) {
      path = `/operations/${notificationPayload.idOperation}/pool`;
    } else if (isPoolAccordCommercialNotif(notification.type)) {
      path = `/operations/${notificationPayload.idOperation}/accords-commerciaux/${notificationPayload.idAccordCommercial}`;
    } else if (isSuiviTravauxEtapeDeclaration(notification.type)) {
      path = `/operations/${notificationPayload.idOperation}/suivi`;
    } else if (
      isEsgNouvelleVersionNotif(notification.type) ||
      isEsgBanqueAskPromoteurForForm(notification.type)
    ) {
      path = `/operations/${notificationPayload.idOperation}/demandes`;
    } else if (isOperationArchivageAuto(notification.type)) {
      path = `/operations/${notificationPayload.idOperation}`;
    } else if (isSuiviCommercialisationEditionTranche(notification.type)) {
      path = `/operations/${notificationPayload.idOperation}/suivi`;
    } else if (isSuiviBudgetNotification(notification.type)) {
      path = `/operations/${notificationPayload.idOperation}/suivi`;
    }
  } else if (notificationPayload.idSocieteSupport) {
    if (notificationPayload.idSurete) {
      path = `/societes-support/${notificationPayload.idSocieteSupport}/suretes/${notificationPayload.idSurete}`;
    }
  } else if (isSuiviCommercialisationNewImport(notification.type)) {
    if (notificationPayload.authorGroupId) {
      path = `/suivis/commercialisation/promoteurs/${notificationPayload.authorGroupId}`;
    }
  }

  return {
    path,
    query,
  };
}
export const userEventTypeWithEnabledMail = [
  UserEventType.USER_JOINING_OPERATION,
  UserEventType.DEMANDE_PROJECT_UPLOADED,
  UserEventType.DEMANDE_PROJECT_VALIDATED,
  UserEventType.DEMANDE_SIGN_METHOD_CHOSEN,
  UserEventType.DEMANDE_SIGNED,
  UserEventType.DEMANDE_DATE_LIVRAISON_DEPASSEE,
  UserEventType.DEMANDE_SHARED_WITH_BANQUE,
  UserEventType.DEMANDE_MAINLEVEE_VALIDATED,
  UserEventType.DEMANDE_MAINLEVEE_REFUSED,
  UserEventType.DEMANDE_MAINLEVEE_REQUEST,
  UserEventType.DEMANDE_MAINLEVEE_DOCUMENT_UPLOADED,
  UserEventType.OPERATION_DOCUMENT_UPLOADED,
  UserEventType.LETTRE_ACCORD_PROJECT_UPLOADED,
  UserEventType.LETTRE_ACCORD_VALIDATED,
  UserEventType.LETTRE_ACCORD_SIGN_READY,
  UserEventType.LETTRE_ACCORD_SIGNED,
  UserEventType.POOL_DEMANDE_SIGNED,
  UserEventType.POOL_JOIN_DEMANDE,
  UserEventType.POOL_NEW_DOCUMENT_UPLOADED,
  UserEventType.POOL_DEMANDE_MAINLEVEE_VALIDATED,
  UserEventType.SURETE_CREATED,
  UserEventType.SURETE_PROJECT_UPLOADED,
  UserEventType.SURETE_PROJECT_VALIDATED,
  UserEventType.SURETE_SIGN_READY,
  UserEventType.SURETE_SIGNED,
  UserEventType.USER_MESSAGE_MENTION,
  UserEventType.DAILY_OPERATION_MESSAGE_SUMMARY,
  UserEventType.ACTE_SECONDAIRE_CREATED,
  UserEventType.CONDITION_MISE_EN_PLACE_NEW_DOCUMENT,
  UserEventType.ACCORD_COMMERCIAL_PROJET_AJOUTE,
  UserEventType.ACCORD_COMMERCIAL_PROJET_VALIDE,
  UserEventType.ACCORD_COMMERCIAL_PROJET_DECLINED,
  UserEventType.ACCORD_COMMERCIAL_PROJET_PRET_A_SIGNE,
  UserEventType.ACCORD_COMMERCIAL_PROJET_SIGNE,
  UserEventType.SUIVI_DE_TRAVAUX_STEP_VALIDATION,
  UserEventType.OPERATION_ARCHIVED_BY_PARTNER,
  UserEventType.OPERATION_AUTO_ARCHIVE,
  UserEventType.ESG_FORM_ASKED_BY_BANQUE,
  UserEventType.SUIVI_DE_COMMERCIALISATION_NEW_IMPORT,
  UserEventType.SUIVI_DE_COMMERCIALISATION_EDITION_TRANCHE,
  UserEventType.SUIVI_BUDGET_PROMOTEUR_IMPORT,
];
