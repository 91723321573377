import { apiRequest, ApiResponse } from "@/apiRequest";
import {
  DemandeEncours,
  DemandeEncoursResponse,
} from "@domain/dto/demandeEncours";
import { DemandeEncoursCreateRequest } from "@domain/dto/demandeEncoursCreateRequest";
import { DemandeEncoursImportLigneUpdateRequest } from "@domain/dto/demandeEncoursImportLigneUpdateRequest";
import { DemandeEncoursOrphanResponse } from "@domain/dto/demandeEncoursOrphanResponse";
import { OperationDemandeEncours } from "@domain/dto/operationDemandeEncours";
import { OperationDemandeEncoursExportRequest } from "@domain/dto/operationDemandeEncoursExportRequest";
import { SuiviGlobalEncoursConfiguration } from "@domain/dto/suiviGlobalEncoursConfiguration";
import { SuiviGlobalEncoursCreateRequest } from "@domain/dto/suiviGlobalEncoursCreateRequest";

export default {
  async fetchDemandeEcours(
    idOperation: string,
    idDemande: string
  ): Promise<ApiResponse<DemandeEncoursResponse | null>> {
    return apiRequest.get(
      `/operations/${idOperation}/demandes/${idDemande}/encours`
    );
  },

  async fetchOperationDemandeEncoursList(): Promise<
    ApiResponse<OperationDemandeEncours[]>
  > {
    return apiRequest.get(`/suivi/encours`, {
      validateStatus(status) {
        return status === 200;
      },
    });
  },

  async createDemandeEncours(
    idOperation: string,
    idDemande: string,
    encoursCreateRequest: DemandeEncoursCreateRequest
  ): Promise<ApiResponse<DemandeEncoursResponse>> {
    return apiRequest.post(
      `/operations/${idOperation}/demandes/${idDemande}/encours`,
      encoursCreateRequest
    );
  },

  async fetchOperationDemandeEncoursExport(
    encoursExportRequestList: OperationDemandeEncoursExportRequest[]
  ): Promise<ApiResponse<BlobPart>> {
    return apiRequest.post("/suivi/encours/export", encoursExportRequestList);
  },

  async saveImportConfiguration(
    configuration: SuiviGlobalEncoursConfiguration
  ): Promise<ApiResponse<SuiviGlobalEncoursConfiguration>> {
    return apiRequest.post("/suivi/encours/configuration", configuration);
  },

  async bulkCreateDemandeEncours(
    encoursCreateRequestList: SuiviGlobalEncoursCreateRequest[]
  ): Promise<ApiResponse<DemandeEncours[]>> {
    return apiRequest.post("/suivi/encours", encoursCreateRequestList);
  },

  async fetchImportConfiguration(): Promise<
    ApiResponse<SuiviGlobalEncoursConfiguration>
  > {
    return apiRequest.get("/suivi/encours/configuration");
  },

  async fetchLatestImportOrphanLignes(): Promise<
    ApiResponse<DemandeEncoursOrphanResponse | null>
  > {
    return apiRequest.get("/suivi/encours/orphan");
  },

  async linkOrphanLines(
    orphanLigneUpdateList: DemandeEncoursImportLigneUpdateRequest[]
  ): Promise<ApiResponse<DemandeEncours[]>> {
    return apiRequest.post("/suivi/encours/orphan", orphanLigneUpdateList);
  },
};
