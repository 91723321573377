import { useDemandeStore } from "@/store/demandeModule.pinia";
import useKeycloakStore from "@/store/keycloakModule.pinia";
import { computed, ref } from "vue";

import { useAnnuaireStore } from "@/store/annuaireModule.pinia";
import { useAccountStore } from "@/store/accountModule.pinia";
import { useDashboardStore } from "@/store/dashboardModule.pinia";
import { useOperationDocumentCheckStore } from "@/store/operationDocumentCheckModule.pinia";
import { usePanelStore } from "@/store/panelModule.pinia";
import { useGroupStore } from "@/store/groupModule.pinia";
import { usePoolAccordCommercialStore } from "@/store/poolAccordCommercialModule.pinia";
import { useUserStore } from "@/store/userModule.pinia";
import { useUtilStore } from "@/store/utilModule.pinia";
import { useOperationStore } from "@/store/operationModule.pinia";
import { useFeatureStore } from "@/store/featureModule.pinia";
import { useDocumentStore } from "@/store/documentModule.pinia";
import { useLettreAccordStore } from "@/store/lettreAccordModule.pinia";
import { useSureteStore } from "@/store/sureteModule.pinia";
import { usePoolFormaliseStore } from "@/store/poolFormalise.pinia";
import { useDocumentEditStore } from "@/store/editDocumentModule.pinia";
import { useSuiviCommercialisationStore } from "@/store/suiviCommercialisationModule.pinia";
import { useNotificationStore } from "@/store/notificationModule.pinia";
import { useSupportedBrowserInformation } from "@/store/supportedBrowserInformation.pinia";
import { usePoolStore } from "@/store/poolModule.pinia";
import { useSignatureStore } from "@/store/signatureModule.pinia";
import { useRouteStore } from "@/store/routeModule.pinia";
import { useUserOperationStore } from "@/store/userOperationModule.pinia";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { useReportingStore } from "@/store/reporting.pinia";
import { useOperationChatStore } from "@/store/operationChatModule.pinia";
import { usePredictionStore } from "@/store/predictionModule.pinia";
import { useReferentStore } from "@/store/referentModule.pinia";
import { useSocieteSupportStore } from "@/store/societeSupportModule.pinia";
import { useTiersStore } from "@/store/tiersModule.pinia";
import { useMainleveeStore } from "@/store/mainleveeModule.pinia";
import { useDashboardHommeDeArtStore } from "@/store/dashboardHommeDeArt.pinia";
import { useSuiviTravauxStore } from "@/store/suiviTravauxModule.pinia";
import { useOperationSuiviCommercialisationStore } from "@/store/operationSuiviCommercialisation.pinia";
import { useFacturationStore } from "@/store/facturation.pinia";
import { useESGStore } from "@/store/esgModule.pinia";
import { useDocumentESGEditStore } from "@/store/editESGDocumentModule.pinia";
import { useEditLettreAccordDocumentStore } from "@/store/editLettreAccordDocumentModule.pinia";
import { useLoaderStore } from "@/store/loaderModule.pinia";
import { useEditSureteDocumentStore } from "@/store/editSureteDocumentModule.pinia";
import { useSuiviTravauxMassifStore } from "@/store/suiviTravauxMassifModule.pinia";
import { useSuiviBudget } from "@/store/suiviBudgetModule.pinia";
import { useDemandeEncoursStore } from "@/store/demandeEncoursModule.pinia";
import { useDemandeEncoursImportStore } from "@/store/demandeEncoursImportModule.pinia";
import logger from "@/logger.ts";

export function getSetupContext() {
  const keycloakStore = useKeycloakStore();
  const userProfileStore = useUserProfileStore();
  const operationStore = useOperationStore();

  const isPromoteur = computed((): boolean => keycloakStore.isPromoteur);

  const isPromoteurOrBanqueUser = computed(
    (): boolean => operationStore.isInOperationAsBanqueOrPromoteur
  );
  const isContributeur = computed((): boolean => keycloakStore.isContributeur);
  const isBanque = computed((): boolean => keycloakStore.isBanque);
  const isPool = computed((): boolean => userProfileStore.isProfilePool);
  const isPoolOnly = computed(
    (): boolean => userProfileStore.isProfilePoolOnly
  );
  const isTiers = computed((): boolean => keycloakStore.isTiers);
  const isHommeDeArt = computed((): boolean => keycloakStore.isHommeDeArt);
  const isSuperUser = computed((): boolean => keycloakStore.isSuperUser);

  const loading = ref<boolean>(false);
  const refractory = ref<boolean>(false);
  const refractorySignatureNumberPromoteur = ref<boolean>(false);
  const refractorySignatureNumberBanque = ref<boolean>(false);
  const refractorySignatureNumberExterne = ref<boolean>(false);

  function loaderWrapper<T extends Function>(
    fn: T
  ): () => Promise<ReturnType<() => T>> {
    return async (...props) => {
      loading.value = true;

      try {
        const result = await fn(...props);
        loading.value = false;
        return result;
      } catch (error) {
        logger.error(error);
        loading.value = false;
        throw error;
      }
    };
  }
  function refractoryWrapper<T extends Function>(
    fn: T
  ): () => Promise<ReturnType<() => T>> {
    return async (...props) => {
      refractory.value = true;
      setTimeout(() => {
        refractory.value = false;
      }, 500);
      try {
        return await fn(...props);
      } catch (err) {
        refractory.value = false;
        throw err;
      }
    };
  }

  function refractorySignatureNumberBanqueWrapper<T extends Function>(
    fn: T
  ): () => Promise<ReturnType<() => T>> {
    return async (...props) => {
      refractorySignatureNumberBanque.value = true;
      setTimeout(() => {
        refractorySignatureNumberBanque.value = false;
      }, 500);
      try {
        return await fn(...props);
      } catch (err) {
        refractorySignatureNumberBanque.value = false;
        throw err;
      }
    };
  }

  function refractorySignatureNumberPromoteurWrapper<T extends Function>(
    fn: T
  ): () => Promise<ReturnType<() => T>> {
    return async (...props) => {
      refractorySignatureNumberPromoteur.value = true;
      setTimeout(() => {
        refractorySignatureNumberPromoteur.value = false;
      }, 500);
      try {
        return await fn(...props);
      } catch (err) {
        refractorySignatureNumberPromoteur.value = false;
        throw err;
      }
    };
  }
  function refractorySignatureNumberExterneWrapper<T extends Function>(
    fn: T
  ): () => Promise<ReturnType<() => T>> {
    return async (...props) => {
      refractorySignatureNumberExterne.value = true;
      setTimeout(() => {
        refractorySignatureNumberExterne.value = false;
      }, 500);
      try {
        return await fn(...props);
      } catch (err) {
        refractorySignatureNumberExterne.value = false;
        throw err;
      }
    };
  }
  return {
    loading,
    loaderWrapper,
    refractoryWrapper,
    refractorySignatureNumberBanqueWrapper,
    refractorySignatureNumberPromoteurWrapper,
    refractorySignatureNumberExterneWrapper,
    refractory,
    refractorySignatureNumberPromoteur,
    refractorySignatureNumberBanque,
    refractorySignatureNumberExterne,
    keycloakStore,
    demandeStore: useDemandeStore(),
    demandeEncoursStore: useDemandeEncoursStore(),
    demandeEncoursImportStore: useDemandeEncoursImportStore(),
    accountStore: useAccountStore(),
    dashboardStore: useDashboardStore(),
    annuaireStore: useAnnuaireStore(),
    operationChatStore: useOperationChatStore(),
    operationDocumentCheckStore: useOperationDocumentCheckStore(),
    panelStore: usePanelStore(),
    poolAccordCommercialStore: usePoolAccordCommercialStore(),
    groupStore: useGroupStore(),
    userStore: useUserStore(),
    utilStore: useUtilStore(),
    operationStore: useOperationStore(),
    documentStore: useDocumentStore(),
    featureStore: useFeatureStore(),
    poolFormaliseStore: usePoolFormaliseStore(),
    sureteStore: useSureteStore(),
    editSureteDocumentStore: useEditSureteDocumentStore(),
    lettreAccordStore: useLettreAccordStore(),
    editDocumentStore: useDocumentEditStore(),
    editLettreAccordDocumentStore: useEditLettreAccordDocumentStore(),
    poolStore: usePoolStore(),
    notificationStore: useNotificationStore(),
    userOperationStore: useUserOperationStore(),
    supportedBrowserInformationStore: useSupportedBrowserInformation(),
    suiviCommercialisationStore: useSuiviCommercialisationStore(),
    suiviBudgetStore: useSuiviBudget(),
    signatureStore: useSignatureStore(),
    userProfileStore: useUserProfileStore(),
    routeStore: useRouteStore(),
    reportingStore: useReportingStore(),
    predictionStore: usePredictionStore(),
    referentStore: useReferentStore(),
    tiersStore: useTiersStore(),
    societeSupportStore: useSocieteSupportStore(),
    mainleveeStore: useMainleveeStore(),
    dashboardHommeDeArtStore: useDashboardHommeDeArtStore(),
    suiviTravauxStore: useSuiviTravauxStore(),
    suiviTravauxMassifStore: useSuiviTravauxMassifStore(),
    operationSuiviCommercialisationStore:
      useOperationSuiviCommercialisationStore(),
    facturationStore: useFacturationStore(),
    esgStore: useESGStore(),
    editESGDocumentStore: useDocumentESGEditStore(),
    loaderStore: useLoaderStore(),
    isPromoteur,
    isContributeur,
    isTiers,
    isBanque,
    isPool,
    isHommeDeArt,
    isPoolOnly,
    isSuperUser,
    isPromoteurOrBanqueUser,
  };
}
