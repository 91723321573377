import { defineStore } from "pinia";
import * as Sentry from "@sentry/vue";
import demandeEncoursApi from "@/api/demandeEncoursApi";
import demandeApi from "@/api/demandeApi";
import { ModuleType } from "@/store/moduleType.pinia";
import { SuiviGlobalEncoursImportConfiguration } from "@/domain/suiviGlobalEncoursImportConfiguration";
import { SuiviGlobalEncoursImportConfigurationEtape } from "@/domain/suiviGlobalEncoursImportConfigurationEtape";
import { SuiviGlobalEncoursNumeroActeDuplicateError } from "@domain/dto/suiviGlobalEncoursNumeroActeDuplicateError";
import { EtapeSuiviGlobalEncoursImport } from "@/domain/enum/etapeSuiviGlobalEncoursImport";
import { EtapeImportConfigurationType } from "@/domain/enum/etapeImportConfigurationType";
import {
  buildInitialSuiviGlobalEncoursImportConfiguration,
  convertConfigurationResponseToInternalConfiguration,
  convertInternalConfigurationToCreationRequest,
  convertSuiviGlobalEncoursMatrixToCreationRequest,
  extractNumeroActeDuplicateErrors,
  formatMontantEncoursCentime,
  isMontantEncoursKiloEuro,
  isSuiviGlobalEncoursConfigurationReady,
  removeEmptyNumeroActeCreateRequests,
  validateEncoursCreationRequests,
} from "@/utils/encoursUtils";
import {
  convertImportMatrixToPreviewData,
  getConfiguredImportHeaderList,
  getDataOnlyImportMatrix,
  UNCONFIGURED_COLUMN_INDEX,
} from "@/utils/importConfigurationUtils";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { SuiviGlobalEncoursCreateRequest } from "@domain/dto/suiviGlobalEncoursCreateRequest";
import { formatEuroAmount } from "@/utils/numberUtils";
import { toRaw } from "vue";

interface DemandeEncoursImportStateType {
  previewMode: boolean;
  isConfigurationImported: boolean;
  rawDataMatrix: string[][];
  importConfigurationFile?: File;
  importConfiguration: SuiviGlobalEncoursImportConfiguration;
  importConfigurationSteps: SuiviGlobalEncoursImportConfigurationEtape[];
  duplicateNumeroActeErrorList: SuiviGlobalEncoursNumeroActeDuplicateError[];
  encoursCreateRequestList: SuiviGlobalEncoursCreateRequest[];
  visibleNumeroActeList: string[];
}

export const useDemandeEncoursImportStore = defineStore(
  ModuleType.DemandeEncoursImport,
  {
    state: (): DemandeEncoursImportStateType => ({
      previewMode: false,
      isConfigurationImported: false,
      rawDataMatrix: [],
      importConfiguration: buildInitialSuiviGlobalEncoursImportConfiguration(),
      importConfigurationSteps: [
        {
          number: 1,
          key: EtapeSuiviGlobalEncoursImport.CHOIX_PREMIERE_LIGNES,
          type: EtapeImportConfigurationType.HEADER,
          label: "En têtes de colonnes",
        },
        {
          number: 2,
          key: EtapeSuiviGlobalEncoursImport.CHOIX_NUMERO_ACTE,
          type: EtapeImportConfigurationType.COLUMN,
          label: "Numéro d'acte",
        },
        {
          number: 3,
          key: EtapeSuiviGlobalEncoursImport.CHOIX_MONTANT_ENCOURS,
          type: EtapeImportConfigurationType.COLUMN,
          label: "Montant encours",
        },
        {
          number: 4,
          key: EtapeSuiviGlobalEncoursImport.CHOIX_NOM_DEMANDE,
          type: EtapeImportConfigurationType.COLUMN,
          label: "Nom demande",
          optional: true,
          defaultValue: "",
        },
        {
          number: 5,
          key: EtapeSuiviGlobalEncoursImport.CHOIX_NOM_OPERATION,
          type: EtapeImportConfigurationType.COLUMN,
          label: "Opération",
          optional: true,
          defaultValue: "",
        },
        {
          number: 6,
          key: EtapeSuiviGlobalEncoursImport.CHOIX_NOM_SOCIETE_SUPPORT,
          type: EtapeImportConfigurationType.COLUMN,
          label: "Société support",
          optional: true,
          defaultValue: "",
        },
        {
          number: 7,
          key: EtapeSuiviGlobalEncoursImport.CHOIX_NOM_PROMOTEUR,
          type: EtapeImportConfigurationType.COLUMN,
          label: "Promoteur",
          optional: true,
          defaultValue: "",
        },
      ],
      duplicateNumeroActeErrorList: [],
      encoursCreateRequestList: [],
      visibleNumeroActeList: [],
    }),
    getters: {
      getPreviewMode(state): boolean {
        return state.previewMode;
      },

      getIsConfigurationImported(state): boolean {
        return state.isConfigurationImported;
      },

      getRawDataMatrix(state): string[][] {
        return state.rawDataMatrix;
      },

      getDataOnlyMatrix(state): string[][] {
        const configuredHeaderList = getConfiguredImportHeaderList(
          state.importConfigurationSteps,
          state.importConfiguration
        );

        return state.rawDataMatrix.slice(Math.max(...configuredHeaderList) + 1);
      },

      getImportConfigurationFile(state): File | undefined {
        return state.importConfigurationFile;
      },

      getImportConfiguration(state): SuiviGlobalEncoursImportConfiguration {
        return state.importConfiguration;
      },

      getImportConfigurationColumnLabels(state): string[] {
        const configuration = this.importConfiguration;
        return state.importConfigurationSteps
          .filter((step) => {
            const isColumn = step.type === EtapeImportConfigurationType.COLUMN;
            const isConfigured =
              configuration[step.key] !== UNCONFIGURED_COLUMN_INDEX;
            return isColumn && isConfigured;
          })
          .map((step) => step.label);
      },

      getImportConfigurationSteps(
        state
      ): SuiviGlobalEncoursImportConfigurationEtape[] {
        return state.importConfigurationSteps;
      },

      getDuplicateNumeroActeErrorList(
        state
      ): SuiviGlobalEncoursNumeroActeDuplicateError[] {
        return state.duplicateNumeroActeErrorList;
      },

      getEncoursCreateRequestList(state): SuiviGlobalEncoursCreateRequest[] {
        return state.encoursCreateRequestList;
      },

      getPreviewData(state): string[][] {
        const configuration = this.importConfiguration;
        const configurationSteps = this.importConfigurationSteps;
        const isConfigurationReady = isSuiviGlobalEncoursConfigurationReady(
          configuration,
          configurationSteps
        );

        if (!isConfigurationReady) {
          return [];
        }

        const montantMatrix = structuredClone(toRaw(state.rawDataMatrix));
        for (const row of montantMatrix) {
          const colonneMontantEncours =
            configuration[EtapeSuiviGlobalEncoursImport.CHOIX_MONTANT_ENCOURS];
          const montantEncours = row[colonneMontantEncours];
          const centime = formatMontantEncoursCentime(
            montantEncours,
            isMontantEncoursKiloEuro(configuration)
          );
          const formattedMontantEncours = formatEuroAmount(centime);
          row[colonneMontantEncours] = formattedMontantEncours;
        }

        return convertImportMatrixToPreviewData(
          montantMatrix,
          configurationSteps,
          configuration
        );
      },

      getVisibleNumeroActeList(state): string[] {
        return state.visibleNumeroActeList;
      },

      getMatchingNumeroActeCount(state): [matching: number, total: number] {
        const configuration = this.importConfiguration;
        const numeroActeColumnIndex = configuration.CHOIX_NUMERO_ACTE;
        const dataOnlyMatrix = this.getDataOnlyMatrix;

        const importedNumeroActeList = dataOnlyMatrix.map(
          (row) => row[numeroActeColumnIndex]
        );

        const visibleNumeroActeList = state.visibleNumeroActeList;
        const matchingCount = importedNumeroActeList.filter((numeroActe) =>
          visibleNumeroActeList.includes(numeroActe)
        ).length;

        return [matchingCount, importedNumeroActeList.length];
      },
    },
    actions: {
      InitializeImportMatrix(matrix: string[][]): void {
        this.rawDataMatrix = matrix;
      },

      SetPreviewMode(previewMode: boolean): void {
        this.previewMode = previewMode;
      },

      SetIsConfigurationImported(isImported: boolean): void {
        this.isConfigurationImported = isImported;
      },

      SetImportConfigurationFile(file: File): void {
        this.importConfigurationFile = file;
      },

      SetImportConfiguration(
        configuration: SuiviGlobalEncoursImportConfiguration
      ): void {
        this.importConfiguration = configuration;
      },

      SetDuplicateNumeroActeErrorList(
        errorList: SuiviGlobalEncoursNumeroActeDuplicateError[]
      ): void {
        this.duplicateNumeroActeErrorList = errorList;
      },

      SetEncoursCreateRequestList(
        createRequestList: SuiviGlobalEncoursCreateRequest[]
      ): void {
        this.encoursCreateRequestList = createRequestList;
      },

      SetEtapeImportConfiguration(
        etape: EtapeSuiviGlobalEncoursImport,
        value: number
      ): void {
        this.importConfiguration[etape] = value;
      },

      SetVisibleNumeroActeList(visibleNumeroActeList: string[]): void {
        this.visibleNumeroActeList = visibleNumeroActeList;
      },

      ResetImportConfiguration(): void {
        this.importConfiguration =
          buildInitialSuiviGlobalEncoursImportConfiguration();
      },

      ResetImportConfigurationStep(etape: EtapeSuiviGlobalEncoursImport): void {
        this.importConfiguration[etape] = UNCONFIGURED_COLUMN_INDEX;
      },

      async fetchImportConfiguration(): Promise<void> {
        const response = await demandeEncoursApi.fetchImportConfiguration();

        if (response.status === 200) {
          const configuration =
            convertConfigurationResponseToInternalConfiguration(response.data);
          this.SetImportConfiguration(configuration);
          this.SetPreviewMode(true);
          this.SetIsConfigurationImported(true);
        }
      },

      async fetchVisibleNumeroActeList(): Promise<void> {
        const response = await demandeApi.fetchVisibleDemandeNumeroActeList();

        if (response.status === 200) {
          this.SetVisibleNumeroActeList(response.data);
        }
      },

      async saveImportConfiguration(): Promise<void> {
        const configuration = this.importConfiguration;
        const configurationSteps = this.importConfigurationSteps;
        const isConfigurationReady = isSuiviGlobalEncoursConfigurationReady(
          configuration,
          configurationSteps
        );

        if (!isConfigurationReady) {
          toastError("La configuration d'import n'est pas complète");
          return;
        }

        try {
          const encoursConfigurationCreationRequest =
            convertInternalConfigurationToCreationRequest(configuration);

          const response = await demandeEncoursApi.saveImportConfiguration(
            encoursConfigurationCreationRequest
          );

          if (response.status === 201) {
            toastSuccess("Votre configuration a été sauvegardée avec succès");
          } else {
            toastError("Échec lors de la sauvegarde de votre configuration");
          }
        } catch (error) {
          Sentry.captureException(error);
          toastError("Échec lors de la sauvegarde de votre configuration");
        }
      },

      async prepareConfigurationJSONData(): Promise<void> {
        const configuration = this.importConfiguration;
        const configurationSteps = this.importConfigurationSteps;
        const isConfigurationReady = isSuiviGlobalEncoursConfigurationReady(
          configuration,
          configurationSteps
        );

        if (!isConfigurationReady || !this.importConfigurationFile) {
          return toastError("Échec lors de l'ajout des données des encours");
        }

        const dataOnlyMatrix = getDataOnlyImportMatrix(
          this.rawDataMatrix,
          configurationSteps,
          configuration
        );

        const encoursCreateRequestList = removeEmptyNumeroActeCreateRequests(
          convertSuiviGlobalEncoursMatrixToCreationRequest(
            dataOnlyMatrix,
            configuration
          )
        );

        const [cleanEncoursCreateRequestList, duplicateNumeroActeErrorList] =
          extractNumeroActeDuplicateErrors(encoursCreateRequestList);

        if (duplicateNumeroActeErrorList.length > 0) {
          this.SetDuplicateNumeroActeErrorList(duplicateNumeroActeErrorList);
          toastError(
            `Erreur à l'import de ${duplicateNumeroActeErrorList.length} encours pour la même GFA avec des montants différents.`
          );
        }

        if (cleanEncoursCreateRequestList.length === 0) {
          return toastError("Aucune donnée n'a pu être chargée");
        }

        this.SetEncoursCreateRequestList(cleanEncoursCreateRequestList);
      },

      async bulkCreateDemandeEncours(
        encoursCreateRequestList: SuiviGlobalEncoursCreateRequest[]
      ): Promise<boolean> {
        const validationErrorSet = validateEncoursCreationRequests(
          encoursCreateRequestList
        );

        if (validationErrorSet.size > 0) {
          for (const error of validationErrorSet) {
            toastError(error);
          }
          return false;
        }

        try {
          const response = await demandeEncoursApi.bulkCreateDemandeEncours(
            encoursCreateRequestList
          );

          if (response.status === 201 && response.data) {
            const createdEncoursCount = response.data.length;
            toastSuccess(`${createdEncoursCount} encours ont été mis à jour.`);
            return true;
          }

          return false;
        } catch (error) {
          Sentry.captureException(error);
          toastError("Erreur lors de la création des encours");
          return false;
        }
      },
    },
  }
);
