import { CellValue } from "@domain/cellValue";

export function isAmount(value: CellValue): boolean {
  if (typeof value !== "string" && typeof value !== "number") {
    return false; // Only strings and numbers can represent amounts
  }
  const stringValue = value.toString().replace(",", "."); // Normalize commas to dots
  return /^\d+(\.\d+)?$/.test(stringValue); // Check for numeric pattern
}

export function tauxVariationRelatif(
  previousValue: number,
  currentValue: number
): number {
  if (previousValue === 0) {
    return 0;
  }

  return (currentValue - previousValue) / previousValue;
}

export function extractNumber(value: CellValue): number {
  if (typeof value === "number") {
    return value;
  }

  if (typeof value === "string") {
    const maybeFloat = value.match(/-?\d+(\.\d+)?/);

    if (maybeFloat) {
      return parseFloat(maybeFloat[0]);
    }

    const maybeInt = value.match(/-?\d+/);

    if (maybeInt) {
      return parseInt(maybeInt[0], 10);
    }
  }

  return NaN;
}
