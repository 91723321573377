import { defineStore } from "pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import facturationApi from "@/api/facturationApi";
import operationApi from "@/api/operationApi";
import { FacturationRequest } from "@domain/dto/facturationRequest";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { Buffer } from "buffer";
import { Facture } from "@/domain/facture";
import { FacturationSuiviConsommation } from "@domain/dto/facturationSuiviConsommation";
import { toastError } from "@/utils/toastUtils";
import { OperationType } from "@domain/enum/operationType";
import logger from "@/logger.ts";

type FacturationStoreType = {
  factureList: Facture[];
  suiviConsommation: FacturationSuiviConsommation[];
  nombreLotsPrevisionnel: number[];
  operationTertiaireCount: number;
  selectedYear: number;
};

export const useFacturationStore = defineStore(ModuleType.Facturation, {
  state: (): FacturationStoreType => ({
    factureList: [],
    selectedYear: new Date().getFullYear(),
    suiviConsommation: [],
    nombreLotsPrevisionnel: [],
    operationTertiaireCount: 0,
  }),
  getters: {
    getFactureList(state): Facture[] {
      return state.factureList;
    },
    getSelectedYear(state): number {
      return state.selectedYear;
    },
    getSuiviConsommation(state): FacturationSuiviConsommation[] {
      return state.suiviConsommation;
    },
    getNombreLotsPrevisionnel(state): number[] {
      return state.nombreLotsPrevisionnel;
    },
    getOperationTertiaireCount(state): number {
      return state.operationTertiaireCount;
    },
  },
  actions: {
    // Actions
    SetSelectedYear(year: number): void {
      this.selectedYear = year;
    },

    SetSuiviConsommation(
      suiviConsommation: FacturationSuiviConsommation[]
    ): void {
      this.suiviConsommation = suiviConsommation;
    },

    SetNombreLotsPrevisionnel(nombreLotsPrevisionnel: number[]): void {
      this.nombreLotsPrevisionnel = nombreLotsPrevisionnel;
    },

    SetOperationTertiaireCount(operationTertiaireCount: number): void {
      this.operationTertiaireCount = operationTertiaireCount;
    },

    async fetchFacturationExport(): Promise<void> {
      const facturationRequest: FacturationRequest = {
        annee: this.selectedYear,
      };
      const response =
        await facturationApi.fetchFacturationExport(facturationRequest);

      const buffer = Buffer.from(response.data as string, "base64");
      downloadClickLink(buffer, {
        name: "export_facturation.xlsx",
        type: NemmoMimeType.EXCEL,
      });
    },

    async fetchFacturationSuiviConsommation(): Promise<void> {
      const response = await facturationApi.fetchFacturationSuiviConsommation(
        this.selectedYear
      );

      if (response.status === 200) {
        this.SetSuiviConsommation(response.data);
      } else {
        this.SetSuiviConsommation([]);
      }
    },

    async fetchFacturationLotPrevisionnel(): Promise<void> {
      try {
        const response = await facturationApi.fetchFacturationLotPrevisionnel();
        this.SetNombreLotsPrevisionnel(response.data);
      } catch (error) {
        logger.error(error);
        this.SetNombreLotsPrevisionnel([]);
      }
    },

    async fetchOperationTertiaireCount(): Promise<void> {
      const response = await operationApi.fetchOperationCount({
        annee: this.selectedYear,
        type: OperationType.TERTIAIRE,
      });

      if (response.status === 200) {
        this.SetOperationTertiaireCount(response.data.count);
      } else {
        this.SetOperationTertiaireCount(0);
      }
    },

    async initFacturation(): Promise<void> {
      this.fetchFacturationSuiviConsommation();
      this.fetchFacturationLotPrevisionnel();
      this.fetchOperationTertiaireCount();
    },

    async fetchFactureList(): Promise<void> {
      try {
        const response = await facturationApi.fetchFactureList();
        this.factureList = response.data;
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement des informations de facture");
      }
    },

    async downloadFacture(facture: Facture): Promise<void> {
      try {
        const response = await facturationApi.downloadFacture(facture.id);
        const documentStream = response.data;

        downloadClickLink(documentStream, {
          name: facture.title,
          type: NemmoMimeType.PDF,
        });
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement de la facture.");
      }
    },
  },
});
