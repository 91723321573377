export function isValidSiren(input: unknown): input is string {
  return (
    typeof input === "string" && !!RegExp(/^\d{9}$/).exec(input.toString())
  );
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isNonEmptyString(value: unknown): value is string {
  return isString(value) && value.length > 0;
}

export function isPositiveInteger(input: unknown): input is number {
  return typeof input === "number" && Number.isInteger(input) && input >= 0;
}
