import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import config from "@/config";
import { App } from "vue";
import useKeycloak from "./store/keycloakModule.pinia";
import logger from "@/logger.ts";

const TOKEN_EXPIRED_MESSAGE = "Token expired";

export interface ApiRequest {
  instance: AxiosInstance;

  getUri(config?: AxiosRequestConfig): string;

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R>;

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;

  head<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;

  isTokenValid(): boolean;
}

export const apiRequest: ApiRequest = {
  instance: axios.create({ baseURL: `${config.ROOT_API}` }),

  getUri(axiosRequestConfig?: AxiosRequestConfig): string {
    return this.instance.getUri(axiosRequestConfig);
  },
  request<T = any, R = AxiosResponse<T>>(
    axiosRequestConfig: AxiosRequestConfig
  ): Promise<R> {
    return this.isTokenValid()
      ? this.instance.request(axiosRequestConfig)
      : Promise.reject(TOKEN_EXPIRED_MESSAGE);
  },
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    axiosRequestConfig?: AxiosRequestConfig
  ): Promise<R> {
    return this.isTokenValid()
      ? this.instance.get(url, axiosRequestConfig)
      : Promise.reject(TOKEN_EXPIRED_MESSAGE);
  },
  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    axiosRequestConfig?: AxiosRequestConfig
  ): Promise<R> {
    return this.isTokenValid()
      ? this.instance.delete(url, axiosRequestConfig)
      : Promise.reject(TOKEN_EXPIRED_MESSAGE);
  },
  head<T = any, R = AxiosResponse<T>>(
    url: string,
    axiosRequestConfig?: AxiosRequestConfig
  ): Promise<R> {
    return this.isTokenValid()
      ? this.instance.head(url, axiosRequestConfig)
      : Promise.reject(TOKEN_EXPIRED_MESSAGE);
  },
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    axiosRequestConfig?: AxiosRequestConfig
  ): Promise<R> {
    return this.isTokenValid()
      ? this.instance.post(url, data, axiosRequestConfig)
      : Promise.reject(TOKEN_EXPIRED_MESSAGE);
  },
  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    axiosRequestConfig?: AxiosRequestConfig
  ): Promise<R> {
    return this.isTokenValid()
      ? this.instance.put(url, data, axiosRequestConfig)
      : Promise.reject(TOKEN_EXPIRED_MESSAGE);
  },
  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    axiosRequestConfig?: AxiosRequestConfig
  ): Promise<R> {
    return this.isTokenValid()
      ? this.instance.patch(url, data, axiosRequestConfig)
      : Promise.reject(TOKEN_EXPIRED_MESSAGE);
  },
  isTokenValid(): boolean {
    const keycloakStore = useKeycloak();
    return keycloakStore.auth ? !keycloakStore.auth.isTokenExpired() : false;
  },
};

export enum ThrottleResult {
  OK = "OK",
  THROTTLED = "THROTTLED",
}
interface ApiRequestThrottle {
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    millisecondWait?: number
  ): Promise<R>;

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    millisecondWait?: number
  ): Promise<R>;

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    millisecondWait?: number
  ): Promise<R>;

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig,
    millisecondWait?: number
  ): Promise<R>;
}

enum HttpVerb {
  POST = "POST",
  PATCH = "PATCH",
  PUT = "PUT",
  DELETE = "DELETE",
}
type NeomiRequest = {
  method: HttpVerb;
  url: string;
  date: number;
};

const requests: NeomiRequest[] = [];

function throttle(
  millisecondWait: number,
  verb: HttpVerb,
  url: string
): ThrottleResult {
  const oldRequest = requests.find(
    (request) => request.method === verb && request.url === url
  );
  if (oldRequest && new Date().getTime() - oldRequest.date < millisecondWait) {
    return ThrottleResult.THROTTLED;
  }

  if (oldRequest) {
    oldRequest.date = new Date().getTime();
  } else {
    requests.push({ method: verb, url, date: new Date().getTime() });
  }
  return ThrottleResult.OK;
}

export const apiRequestThrottle: ApiRequestThrottle = {
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    axiosRequestConfig?: AxiosRequestConfig,
    millisecondWait: number = 1000
  ): Promise<R> {
    const throttleResult = throttle(millisecondWait, HttpVerb.POST, url);
    if (throttleResult === ThrottleResult.OK) {
      return apiRequest.post(url, data, axiosRequestConfig);
    }
    return Promise.reject(new Error(`${throttleResult}`));
  },

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    axiosRequestConfig?: AxiosRequestConfig,
    millisecondWait: number = 1000
  ): Promise<R> {
    const throttleResult = throttle(millisecondWait, HttpVerb.PUT, url);
    if (throttleResult === ThrottleResult.OK) {
      return apiRequest.put(url, data, axiosRequestConfig);
    }
    return Promise.reject(new Error(`${throttleResult}`));
  },

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    axiosRequestConfig?: AxiosRequestConfig,
    millisecondWait: number = 1000
  ): Promise<R> {
    const throttleResult = throttle(millisecondWait, HttpVerb.PATCH, url);
    if (throttleResult === ThrottleResult.OK) {
      return apiRequest.patch(url, data, axiosRequestConfig);
    }
    return Promise.reject(new Error(`${throttleResult}`));
  },

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    axiosRequestConfig?: AxiosRequestConfig,
    millisecondWait: number = 1000
  ): Promise<R> {
    const throttleResult = throttle(millisecondWait, HttpVerb.DELETE, url);
    if (throttleResult === ThrottleResult.OK) {
      return apiRequest.delete(url, data, axiosRequestConfig);
    }
    return Promise.reject(new Error(`${throttleResult}`));
  },
};

export interface ApiResponse<T> {
  data: T;
  headers?: Record<string, string>;
  status: number;
}

export function setToken(bearerToken: string): void {
  apiRequest.instance.defaults.headers.common = {
    Authorization: `Bearer ${bearerToken}`,
  };
}

export function configureReroutingOnHttpError(vue: App): void {
  apiRequest.instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      return handleAxiosError(error, vue);
    }
  );
}

function handleAxiosError(error: AxiosError, vue: App): void {
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    if (error.response.status === 401 || error.response.status === 403) {
      vue.config.globalProperties.$router.push("/unAuthorized").then(() => {});
    }
    if (error.response.status === 404) {
      vue.config.globalProperties.$router
        .push("/resource-not-found")
        .then(() => {});
    } else {
      logger.error("Error response", error);
    }
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    logger.error(error.request);
  } else {
    // Something happened in setting update the request and triggered an Error
    logger.error("Error request", error.message);
  }
}
