import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./sass/main.scss";
import ToggleButton from "@vueform/toggle";
import vSelect from "vue-select";
import { configureReroutingOnHttpError } from "@/apiRequest";
import config from "./config";
import { initSentry } from "./security/sentry";
import VueDatePicker from "@vuepic/vue-datepicker";
import { createPinia } from "pinia";
import Popper from "vue3-popper";
import { globalEmitters } from "@/plugins/emitter";

// Pour fonctionner avec es6-buffer
(window as unknown as { global: Window }).global = window;

const vue = createApp(App);
vue.use(createPinia());
vue.component("VSelect", vSelect);
vue.component("ToggleButton", ToggleButton);
vue.component("VueDatePicker", VueDatePicker);
vue.component("Popper", Popper);

vue.use(router);
vue.use(globalEmitters);
if (config.SENTRY_DSN) {
  initSentry(
    vue,
    router,
    config.SENTRY_DSN,
    config.VERSION,
    config.NEOMI,
    config.SENTRY_REPLAYS_HIDE_TEXTS
  );
}

router.isReady().then(() => {
  vue.mount("#app");
});

vue.config.performance = true;
configureReroutingOnHttpError(vue);
