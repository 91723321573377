import logger from "@/logger.ts";

let neomi = "inconnu";
let rootApi = "";
let keycloakUrl = "";
let rootUrl = "";
let keycloakClient = "";
let keycloakRealm = "";
let matomoUrl = "";
let matomoId = "";
let nodeEnv = "";
let version = "";
let devCom = false;
let latestConditionGeneraleUtilisationVersion = "";
let collaboraBackendUrl = "";
let sentryDSN = "";
let sentryReplaysHideTexts = true;
let inactiviteInterval = 0;
let inactiviteCloseTimeout = 0;
let releaseNoteVersion = "";
let releaseNoteImageCount = 0;
let releaseNoteStartDate = "";
let collaboraVersion = "";

const win = window as any;

if (win.nemmoConfig) {
  neomi = win.nemmoConfig.NEOMI || "";
  rootApi = win.nemmoConfig.ROOT_API || "";
  keycloakUrl = win.nemmoConfig.KEYCLOAK_URL || "";
  rootUrl = win.nemmoConfig.ROOT_URL || "";
  keycloakClient = win.nemmoConfig.KEYCLOAK_CLIENT || "";
  keycloakRealm = win.nemmoConfig.KEYCLOAK_REALM || "";
  matomoUrl = win.nemmoConfig.MATOMO_URL || "";
  matomoId = win.nemmoConfig.MATOMO_ID || "";
  nodeEnv = win.nemmoConfig.NODE_ENV;
  version = win.nemmoConfig.VERSION || "0.0";
  collaboraBackendUrl = win.nemmoConfig.COLLABORA_BACKEND_URL || "";
  latestConditionGeneraleUtilisationVersion =
    win.nemmoConfig.LATEST_CONDITION_GENERALE_UTILISATION_VERSION;
  devCom = win.nemmoConfig.DEV_COM;
  sentryDSN = win.nemmoConfig.SENTRY_DSN;
  sentryReplaysHideTexts = win.nemmoConfig.SENTRY_REPLAYS_HIDE_TEXTS;
  inactiviteInterval = win.nemmoConfig.INACTIVITE_INTERVAL || 0;
  inactiviteCloseTimeout = win.nemmoConfig.INACTIVITE_CLOSE_TIMEOUT || 0;
  releaseNoteVersion = win.nemmoConfig.RELEASE_NOTE_VERSION || "";
  releaseNoteImageCount = win.nemmoConfig.RELEASE_NOTE_IMAGE_COUNT || 0;
  releaseNoteStartDate = win.nemmoConfig.RELEASE_NOTE_START_DATE || 0;
  collaboraVersion = win.nemmoConfig.COLLABORA_VERSION || "";
} else if (process.env.NODE_ENV === "test") {
  nodeEnv = "test";
} else {
  logger.error("Could not load environment variables");
}

export default {
  NEOMI: neomi,
  ROOT_API: rootApi,
  KEYCLOAK_URL: keycloakUrl,
  ROOT_URL: rootUrl,
  KEYCLOAK_CLIENT: keycloakClient,
  KEYCLOAK_REALM: keycloakRealm,
  MATOMO_URL: matomoUrl,
  MATOMO_ID: matomoId,
  NODE_ENV: nodeEnv,
  VERSION: version,
  LATEST_CONDITION_GENERALE_UTILISATION_VERSION:
    latestConditionGeneraleUtilisationVersion,
  DEV_COM: devCom,
  COLLABORA_BACKEND_URL: collaboraBackendUrl,
  SENTRY_DSN: sentryDSN,
  SENTRY_REPLAYS_HIDE_TEXTS: sentryReplaysHideTexts,
  INACTIVITE_INTERVAL: inactiviteInterval,
  INACTIVITE_CLOSE_TIMEOUT: inactiviteCloseTimeout,
  RELEASE_NOTE_VERSION: releaseNoteVersion,
  RELEASE_NOTE_IMAGE_COUNT: releaseNoteImageCount,
  RELEASE_NOTE_START_DATE: releaseNoteStartDate,
  COLLABORA_VERSION: collaboraVersion,
};
