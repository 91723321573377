import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { OperationHeavy, TiersOperationHeavy } from "@/domain/operationHeavy";
import {
  capitalizeOperationName,
  createOperationHeavy,
  createTiersOperationHeavy,
} from "@/utils/operationUtils";
import { OperationCreationRequest } from "@domain/dto/operationCreationRequest";
import { OperationFormErrorType } from "@/domain/enum/operationFormErrorType";
import { usePanelStore } from "./panelModule.pinia";
import operationApi from "@/api/operationApi";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { OperationUpdateRequest } from "@domain/dto/operationUpdateRequest";
import { Member } from "@/domain/member";
import { formatFirstNameLastName } from "@/utils/formatNameUtils";
import { useUserOperationStore } from "@/store/userOperationModule.pinia";
import { useOperationChatStore } from "@/store/operationChatModule.pinia";
import { getGroupRole } from "@/utils/groupUtils";
import { Role } from "@domain/enum/role";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { OperationArchiveHeavy } from "@/domain/operationArchivedHeavy";
import {
  createArchivedOperationHeavy,
  isOperationArchived,
} from "@/utils/operationArchiveeUtils";
import router from "@/router";
import { OperationSurete } from "@domain/dto/operationSurete";
import operationSureteApi from "@/api/operationSureteApi";
import { OperationAccessAsRole } from "@/domain/enum/operationAccessAsRole";
import logger from "@/logger.ts";

type OperationStoreState = {
  operation: OperationHeavy;
  tierOperation: TiersOperationHeavy;
  archivedOperation: OperationArchiveHeavy;
  operationSureteList: OperationSurete[];
};

export const useOperationStore = defineStore(ModuleType.Operation, {
  state: (): OperationStoreState => ({
    operation: createOperationHeavy(),
    tierOperation: createTiersOperationHeavy(),
    archivedOperation: createArchivedOperationHeavy(),
    operationSureteList: [],
  }),
  getters: {
    getOperation(state): OperationHeavy {
      return state.operation;
    },
    getCurrentOperationId(state): string {
      return (
        state.operation.id ||
        state.tierOperation.id ||
        state.archivedOperation.id ||
        ""
      );
    },
    getTiersOperation(state): TiersOperationHeavy {
      return state.tierOperation;
    },
    getArchivedOperation(state): OperationArchiveHeavy {
      return state.archivedOperation;
    },
    getOperationSureteList(state): OperationSurete[] {
      return state.operationSureteList;
    },
    isArchived(state): boolean {
      return !!state.archivedOperation.id;
    },
    isInOperationAsBanqueOrPromoteur(state): boolean {
      return [
        OperationAccessAsRole.BANQUE,
        OperationAccessAsRole.PARTICIPANT_AND_BANQUE,
        OperationAccessAsRole.PROMOTEUR,
      ].includes(state.operation.operationAccessAsRole);
    },
    isSomeDemandeSigned(state): boolean {
      return !!state.operation.demandeList.find(
        (demande) => !!demande.signedAt
      );
    },
  },
  actions: {
    SetOperation(operation: OperationHeavy): void {
      this.operation = operation;
    },
    SetTiersOperation(operation: TiersOperationHeavy): void {
      this.tierOperation = operation;
    },
    SetArchivedOperation(operation: OperationArchiveHeavy): void {
      this.archivedOperation = operation;
    },

    ResetOperation(): void {
      this.operation = createOperationHeavy();
      this.tierOperation = createTiersOperationHeavy();
      this.archivedOperation = createArchivedOperationHeavy();
      this.operationSureteList = [];
    },

    async createOperation(
      operation: OperationCreationRequest
    ): Promise<OperationFormErrorType[]> {
      const panelStore = usePanelStore();

      const capitalizedOperation = capitalizeOperationName(operation);
      return operationApi
        .create({
          ...capitalizedOperation,
          idPromoteurOwner: operation.idPromoteurOwner
            ? operation.idPromoteurOwner
            : (useUserProfileStore().getUserProfile.groups.find((group) =>
                group.roles.includes(Role.PROMOTEUR)
              )?.id ?? ""),
        })
        .then((response) => {
          if (response && response.status === 201) {
            toastSuccess("Opération créée avec succès");
            const operationHeavy = response.data as OperationHeavy;
            this.SetOperation({
              ...operationHeavy,
            });
            panelStore.CloseSidePanel();
            return [];
          } else if (response && response.status === 409) {
            toastError("Le formulaire est incorrect");
            return response.data as OperationFormErrorType[];
          } else {
            toastError("Erreur l'opération n'a pas pu être créée...");
            panelStore.CloseSidePanel();
            return [];
          }
        })
        .catch(() => {
          toastError("Erreur l'opération n'a pas pu être créée...");
          panelStore.CloseSidePanel();
          return [];
        });
    },

    async updateOperation(updatedField: OperationUpdateRequest): Promise<void> {
      return operationApi
        .update(updatedField)
        .then((response) => {
          if (response && response.status === 200) {
            toastSuccess("Opération mise à jour");
            this.SetOperation(response.data);
          } else if (response && response.status === 409) {
            toastError("Cette référence existe déjà");
          } else {
            toastError(
              "Une erreur est survenue lors de la modification du champ"
            );
          }
        })
        .catch();
    },

    async updateOperationReferenceBanque(
      updatedField: OperationUpdateRequest
    ): Promise<void> {
      await operationApi
        .updateReferenceBanque(updatedField)
        .then((response) => {
          if (response && response.status === 200) {
            toastSuccess(`Opération mise à jour`);
            this.SetOperation({
              ...this.operation,
              referenceBanque: response.data.referenceBanque,
            });
          } else if (response && response.status === 409) {
            toastError("Cette référence existe déjà");
          } else {
            toastError(
              "Une erreur est survenue lors de la modification du champ"
            );
          }
        })
        .catch();
    },

    async updateOperationBanqueReferent(
      updatedField: OperationUpdateRequest
    ): Promise<void> {
      await operationApi
        .updateOperationBanqueReferent(updatedField)
        .then((response) => {
          if (response && response.status === 200) {
            toastSuccess("Référent banque mis à jour");
            this.SetOperation(response.data);
          } else {
            toastError(
              "Une erreur est survenue lors de la modification du champ"
            );
          }
        })
        .catch();
    },

    async fetchOperationById(id: string): Promise<void> {
      const response = await operationApi.fetchById(id);

      this.SetOperation(response.data);
    },

    async fetchOperationDetailsById(id: string): Promise<void> {
      const userRole = getGroupRole(useUserProfileStore().getRoles);

      try {
        if (userRole === Role.TIERS) {
          const response =
            await operationApi.fetchTiersOperationDetailsById(id);
          this.SetTiersOperation(response.data);
        } else {
          const response = await operationApi.fetchOperationDetailsById(id);

          if (response.status === 403) {
            toastError("Cette opération ne vous est plus partagée.");
            router.push(`/accueil`);
          }

          if (response.status === 200) {
            if (isOperationArchived(response.data)) {
              this.SetArchivedOperation(response.data);
            } else {
              this.SetOperation(response.data);
            }
          }
        }
      } catch (error) {
        logger.error(error);
        toastError(
          "Une erreur est survenue lors de la récupération des informations de l'opération"
        );
      }
    },

    async addMembers(members: Member[]): Promise<void> {
      const panelStore = usePanelStore();

      const idOperation = this.getCurrentOperationId;
      const idMemberList = members.map((member) => member.id);
      const result = await operationApi.addMembers(idOperation, idMemberList);

      if (result && result.status === 201) {
        await Promise.all([
          useUserOperationStore().fetchOperationMembers(idOperation),
          useOperationChatStore().fetchOperationRoomList(),
        ]);
        members.forEach((member) => {
          toastSuccess(
            formatFirstNameLastName(member) +
              " a bien été ajouté à l'opération."
          );
        });
      } else {
        toastError("Les membres n'ont pas pu être ajouté à l'opération.");
      }
      panelStore.CloseSidePanel();
    },

    async deleteOperation(): Promise<void> {
      const idOperation = this.getCurrentOperationId;
      await operationApi
        .delete(idOperation)
        .then((response) => {
          if (response.status == 200) {
            toastSuccess("Opération supprimée");
          } else {
            toastError(
              "Une erreur s'est produite lors de la suppression de l'opération"
            );
          }
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la suppression de l'opération"
          );
        });
    },

    async archiveOperation(): Promise<void> {
      const idOperation = this.getCurrentOperationId;
      try {
        const apiResponse = await operationApi.archive(idOperation);
        if (apiResponse.status == 200) {
          toastSuccess("Opération archivée");
        } else {
          toastError(
            "Une erreur s'est produite lors de l'archivage de l'opération"
          );
        }
      } catch (error) {
        logger.error(error);
        toastError(
          "Une erreur s'est produite lors de l'archivage de l'opération"
        );
      }
    },

    async unarchiveOperation(): Promise<void> {
      const idOperation = this.getCurrentOperationId;
      const errorMessage =
        "Une erreur s'est produite lors du désarchivage de l'opération";
      const response = await operationApi.unarchive(idOperation).catch(() => {
        toastError(errorMessage);
      });

      if (response?.status === 200 && response.data) {
        toastSuccess("Opération désarchivée");
      } else {
        toastError(errorMessage);
      }
    },
    async fetchOperationSureteList(idOperation: string): Promise<void> {
      const response = await operationSureteApi.getByIdOperation(idOperation);
      if (response.status === 200) {
        this.operationSureteList = response.data;
      }
    },
  },
});
