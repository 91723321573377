import { SuiviGlobalEncoursDataTableEntry } from "@/domain/suiviGlobalEncoursDataTableEntry";
import { NeomiEventBus } from "@/domain/neomiEventBus";
import { Row, RowData } from "@tanstack/vue-table";
import { convertEuroToCentime, formatEuroAmount } from "./numberUtils";
import { OperationDemandeEncoursExportRequest } from "@domain/dto/operationDemandeEncoursExportRequest";
import { enumVariants } from "@domain/utils/enumUtils";
import { EtapeSuiviGlobalEncoursImport } from "@/domain/enum/etapeSuiviGlobalEncoursImport";
import {
  buildInitialImportConfiguration,
  getImportConfigurationErrors,
  UNCONFIGURED_COLUMN_INDEX,
} from "@/utils/importConfigurationUtils";
import { SuiviGlobalEncoursImportConfiguration } from "@/domain/suiviGlobalEncoursImportConfiguration";
import { SuiviGlobalEncoursImportConfigurationEtape } from "@/domain/suiviGlobalEncoursImportConfigurationEtape";
import { SuiviGlobalEncoursCreateRequest } from "@domain/dto/suiviGlobalEncoursCreateRequest";
import { SuiviGlobalEncoursConfiguration } from "@domain/dto/suiviGlobalEncoursConfiguration";
import { groupInMap } from "@domain/utils/functionUtils";
import { SuiviGlobalEncoursNumeroActeDuplicateError } from "@domain/dto/suiviGlobalEncoursNumeroActeDuplicateError";
import { EventCollection } from "@/plugins/emitter";
import { extractNumber } from "@domain/utils/numberUtils";
import { normalizeString } from "@/utils/wordUtils";
import { EuroAmountUnit } from "@domain/enum/euroAmountUnit";
import { DemandeEncoursImportLigne } from "@domain/dto/demandeEncoursImportLigne";

export interface EncoursEmitterEvents extends EventCollection {
  [NeomiEventBus.TOGGLE_SUIVI_ENCOURS_GFA_NON_RENSEIGNE]: void;
  [NeomiEventBus.RELOAD_SUIVI_ENCOURS]: void;
}

export function computeMontantEncoursGlobal(
  encoursDataEntries: SuiviGlobalEncoursDataTableEntry[]
): number {
  return encoursDataEntries.reduce((acc, curr) => {
    const montant = curr.montantActualise || 0;
    return acc + montant;
  }, 0);
}

export function computeTotalEncours(
  encoursDataEntries: SuiviGlobalEncoursDataTableEntry[]
): number {
  return encoursDataEntries.reduce((acc, curr) => {
    return curr.children.length ? acc + curr.children.length : acc + 1;
  }, 0);
}

export function computeTotalUndefinedEncours(
  encoursDataEntries: SuiviGlobalEncoursDataTableEntry[]
): number {
  return encoursDataEntries.reduce((acc, curr) => {
    if (curr.children.length === 0) {
      return !curr.montantActualise ? acc + 1 : acc;
    }
    const unfilledRow = curr.children.filter((row) => !row.montantActualise);
    return acc + unfilledRow.length;
  }, 0);
}

export const UNDEFINED_AMOUNT_LABEL = "N/A";

export function formatMontantColumnValue<T extends RowData>(
  columnId: string,
  row: Row<T>
): string {
  const montantStr = row.getValue<string>(columnId);
  const montant = extractNumber(montantStr);

  if (row.subRows.length) {
    const hasNoMontantForGfa = row.subRows.every((r) => !r.getValue(columnId));
    return hasNoMontantForGfa
      ? UNDEFINED_AMOUNT_LABEL
      : formatEuroAmount(montant, UNDEFINED_AMOUNT_LABEL);
  }

  return formatEuroAmount(montant, UNDEFINED_AMOUNT_LABEL);
}

export function buildOperationDemandeEncoursExportRequest(
  encoursDataEntries: SuiviGlobalEncoursDataTableEntry[]
): OperationDemandeEncoursExportRequest[] {
  return encoursDataEntries.map((entry) => {
    return {
      idOperation: entry.id,
      idDemandeList: entry.children.map((child) => child.id),
    };
  });
}

export function buildInitialSuiviGlobalEncoursImportConfiguration(): SuiviGlobalEncoursImportConfiguration {
  const stepKeys = enumVariants(EtapeSuiviGlobalEncoursImport);
  return {
    ...buildInitialImportConfiguration(stepKeys),
    choixUniteEuro: EuroAmountUnit.EURO,
  };
}

export function isSuiviGlobalEncoursConfigurationReady(
  configuration: SuiviGlobalEncoursImportConfiguration,
  configurationSteps: SuiviGlobalEncoursImportConfigurationEtape[]
): boolean {
  const errors = getImportConfigurationErrors(
    configuration,
    configurationSteps
  );
  return errors.length === 0;
}

function getOptionalColumnConfiguredIndex(
  configuration: SuiviGlobalEncoursImportConfiguration,
  step: EtapeSuiviGlobalEncoursImport
): number | undefined {
  return configuration[step] !== UNCONFIGURED_COLUMN_INDEX
    ? configuration[step]
    : undefined;
}

export function convertInternalConfigurationToCreationRequest(
  configuration: SuiviGlobalEncoursImportConfiguration
): SuiviGlobalEncoursConfiguration {
  return {
    premiereLigne:
      configuration[EtapeSuiviGlobalEncoursImport.CHOIX_PREMIERE_LIGNES],
    numeroActe: configuration[EtapeSuiviGlobalEncoursImport.CHOIX_NUMERO_ACTE],
    montantEncours:
      configuration[EtapeSuiviGlobalEncoursImport.CHOIX_MONTANT_ENCOURS],
    choixUniteEuro: configuration.choixUniteEuro,
    demandeName: getOptionalColumnConfiguredIndex(
      configuration,
      EtapeSuiviGlobalEncoursImport.CHOIX_NOM_DEMANDE
    ),
    operationName: getOptionalColumnConfiguredIndex(
      configuration,
      EtapeSuiviGlobalEncoursImport.CHOIX_NOM_OPERATION
    ),
    societeSupportName: getOptionalColumnConfiguredIndex(
      configuration,
      EtapeSuiviGlobalEncoursImport.CHOIX_NOM_SOCIETE_SUPPORT
    ),
    promoteurName: getOptionalColumnConfiguredIndex(
      configuration,
      EtapeSuiviGlobalEncoursImport.CHOIX_NOM_PROMOTEUR
    ),
  };
}

function getColumnIndexOrDefault(value: number | undefined): number {
  return value ?? UNCONFIGURED_COLUMN_INDEX;
}

export function convertConfigurationResponseToInternalConfiguration(
  response: SuiviGlobalEncoursConfiguration
): SuiviGlobalEncoursImportConfiguration {
  return {
    [EtapeSuiviGlobalEncoursImport.CHOIX_PREMIERE_LIGNES]:
      response.premiereLigne,
    [EtapeSuiviGlobalEncoursImport.CHOIX_NUMERO_ACTE]: response.numeroActe,
    [EtapeSuiviGlobalEncoursImport.CHOIX_MONTANT_ENCOURS]:
      response.montantEncours,
    choixUniteEuro: response.choixUniteEuro,
    [EtapeSuiviGlobalEncoursImport.CHOIX_NOM_DEMANDE]: getColumnIndexOrDefault(
      response.demandeName
    ),
    [EtapeSuiviGlobalEncoursImport.CHOIX_NOM_OPERATION]:
      getColumnIndexOrDefault(response.operationName),
    [EtapeSuiviGlobalEncoursImport.CHOIX_NOM_SOCIETE_SUPPORT]:
      getColumnIndexOrDefault(response.societeSupportName),
    [EtapeSuiviGlobalEncoursImport.CHOIX_NOM_PROMOTEUR]:
      getColumnIndexOrDefault(response.promoteurName),
  };
}

export function formatMontantEncoursCentime(
  value: string,
  isKEuro: boolean
): number {
  const amount = extractNumber(value);

  if (isNaN(amount)) {
    return 0;
  }

  return convertEuroToCentime(amount, isKEuro);
}

export function isMontantEncoursKiloEuro(
  configuration:
    | SuiviGlobalEncoursImportConfiguration
    | SuiviGlobalEncoursConfiguration
): boolean {
  return configuration.choixUniteEuro === EuroAmountUnit.KILO_EURO;
}

export function convertSuiviGlobalEncoursMatrixToCreationRequest(
  dataOnlyMatrix: string[][],
  importConfiguration: SuiviGlobalEncoursImportConfiguration
): SuiviGlobalEncoursCreateRequest[] {
  const configuration =
    convertInternalConfigurationToCreationRequest(importConfiguration);

  return dataOnlyMatrix.map<SuiviGlobalEncoursCreateRequest>((row) => ({
    numeroActe: row[configuration.numeroActe],
    montantEncoursEuroCentime: formatMontantEncoursCentime(
      row[configuration.montantEncours],
      isMontantEncoursKiloEuro(configuration)
    ),
    demandeName: configuration.demandeName
      ? row[configuration.demandeName]
      : undefined,
    operationName: configuration.operationName
      ? row[configuration.operationName]
      : undefined,
    societeSupportName: configuration.societeSupportName
      ? row[configuration.societeSupportName]
      : undefined,
    promoteurName: configuration.promoteurName
      ? row[configuration.promoteurName]
      : undefined,
  }));
}

export type EncoursDuplicateErrorExtractionResult = [
  SuiviGlobalEncoursCreateRequest[],
  SuiviGlobalEncoursNumeroActeDuplicateError[],
];

export function extractNumeroActeDuplicateErrors(
  createRequestList: SuiviGlobalEncoursCreateRequest[]
): EncoursDuplicateErrorExtractionResult {
  const createRequestListByNumeroActe = groupInMap(
    createRequestList,
    (request) => normalizeString(request.numeroActe.trim())
  );

  const extractionResult: EncoursDuplicateErrorExtractionResult = [[], []];

  for (const [_, createRequestList] of createRequestListByNumeroActe) {
    const [createRequest] = createRequestList;

    if (createRequestList.length === 1) {
      extractionResult[0].push(createRequest);
      continue;
    }

    // vérifier si les montants sont identiques
    // si oui, on ne considère pas l'erreur et on ajoute la première entrée
    // si non, on considère l'erreur
    const montantEncoursMap = new Set(
      createRequestList.map((request) => request.montantEncoursEuroCentime)
    );

    if (montantEncoursMap.size === 1) {
      extractionResult[0].push(createRequest);
      continue;
    }

    const duplicateNumeroActeErrorList =
      createRequestList.map<SuiviGlobalEncoursNumeroActeDuplicateError>(
        (request) => ({
          numeroActe: request.numeroActe,
          montantEncoursEuro: request.montantEncoursEuroCentime,
        })
      );

    extractionResult[1].push(...duplicateNumeroActeErrorList);
  }

  return extractionResult;
}

export function removeEmptyNumeroActeCreateRequests(
  createRequestList: SuiviGlobalEncoursCreateRequest[]
): SuiviGlobalEncoursCreateRequest[] {
  return createRequestList.filter((request) => request.numeroActe);
}

export enum EncoursValidationError {
  INVALID_AMOUNT = "Certains montants d'encours sont invalides (entre 0 et 1Mrd €)",
  INVALID_NUMERO_ACTE = "Certains numéros d'acte sont invalides",
}

export function validateEncoursCreationRequests(
  createRequestList: SuiviGlobalEncoursCreateRequest[]
): Set<EncoursValidationError> {
  const errors = new Set<EncoursValidationError>();

  for (const request of createRequestList) {
    if (
      !request.montantEncoursEuroCentime ||
      isNaN(extractNumber(request.montantEncoursEuroCentime)) ||
      request.montantEncoursEuroCentime <= 0 ||
      request.montantEncoursEuroCentime > 100_000_000_000
    ) {
      errors.add(EncoursValidationError.INVALID_AMOUNT);
    }

    if (!request.numeroActe) {
      errors.add(EncoursValidationError.INVALID_NUMERO_ACTE);
    }
  }

  return errors;
}

function formatOperationDemandeName(
  encoursOrphanLigne: DemandeEncoursImportLigne
): string {
  let name = encoursOrphanLigne.operationName || "";

  if (!!encoursOrphanLigne.demandeName) {
    name += name
      ? ` - ${encoursOrphanLigne.demandeName}`
      : encoursOrphanLigne.demandeName;
  }

  return name;
}

export function buildSuiviGlobalEncoursDataTableEntryFromOrphanLigne(
  encoursOrphanLigne: DemandeEncoursImportLigne
): SuiviGlobalEncoursDataTableEntry {
  return {
    id: encoursOrphanLigne.id,
    name: formatOperationDemandeName(encoursOrphanLigne),
    numeroActe: encoursOrphanLigne.numeroActe,
    montantActualise: encoursOrphanLigne.montantEncoursEuroCentime,
    montantInitial: null,
    dateEcheance: null,
    societeSupport: encoursOrphanLigne.societeSupportName || "",
    banque: "",
    banqueSousGroupe: "",
    promoteur: encoursOrphanLigne.promoteurName || "",
    promoteurSousGroupe: "",
    updatedAt: encoursOrphanLigne.updatedAt,
    children: [],
  };
}
