import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import demandeEncoursApi from "@/api/demandeEncoursApi";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { DemandeEncoursResponse } from "@domain/dto/demandeEncours";
import { DemandeEncoursCreateRequest } from "@domain/dto/demandeEncoursCreateRequest";
import { OperationDemandeEncours } from "@domain/dto/operationDemandeEncours";
import { None, Option, Some } from "@hqoss/monads";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { OperationDemandeEncoursExportRequest } from "@domain/dto/operationDemandeEncoursExportRequest";
import { SuiviGlobalEncoursView } from "@/domain/enum/suiviGlobalEncoursView";
import { DemandeEncoursOrphanResponse } from "@domain/dto/demandeEncoursOrphanResponse";
import { DemandeEncoursImportLigneUpdateRequest } from "@domain/dto/demandeEncoursImportLigneUpdateRequest";
import logger from "@/logger";

interface DemandeEncoursStateType {
  demandeEncours: Option<DemandeEncoursResponse> | null;
  operationDemandeEncoursList: OperationDemandeEncours[];
  view: SuiviGlobalEncoursView;
  encoursOrphans: DemandeEncoursOrphanResponse | null;
  orphanUpdateRequestList: DemandeEncoursImportLigneUpdateRequest[];
}

export const useDemandeEncoursStore = defineStore(ModuleType.DemandeEncours, {
  state: (): DemandeEncoursStateType => ({
    demandeEncours: null,
    operationDemandeEncoursList: [],
    view: SuiviGlobalEncoursView.INITIAL,
    encoursOrphans: null,
    orphanUpdateRequestList: [],
  }),
  getters: {
    getDemandeEncours(state): Option<DemandeEncoursResponse> | null {
      return state.demandeEncours;
    },

    getOperationDemandeEncoursList(state): OperationDemandeEncours[] {
      return state.operationDemandeEncoursList;
    },

    getCurrentView(state): SuiviGlobalEncoursView {
      return state.view;
    },

    getEncoursOrphans(state): DemandeEncoursOrphanResponse | null {
      return state.encoursOrphans;
    },

    getOrphanUpdateRequestList(
      state
    ): DemandeEncoursImportLigneUpdateRequest[] {
      return state.orphanUpdateRequestList;
    },
  },
  actions: {
    SetDemandeEncours(encours: DemandeEncoursResponse | null): void {
      this.demandeEncours = encours ? Some(encours) : None;
    },

    SetOperationDemandeEncoursList(
      encoursGlobal: OperationDemandeEncours[]
    ): void {
      this.operationDemandeEncoursList = encoursGlobal;
    },

    SetView(view: SuiviGlobalEncoursView): void {
      this.view = view;
    },

    SetEncoursOrphans(orphans: DemandeEncoursOrphanResponse | null): void {
      this.encoursOrphans = orphans;
    },

    AddOrphanUpdateRequest(
      request: DemandeEncoursImportLigneUpdateRequest
    ): void {
      const withoutPrevious = this.orphanUpdateRequestList.filter(
        (r) => r.idImportLigne !== request.idImportLigne
      );
      this.orphanUpdateRequestList = [...withoutPrevious, request];
    },

    ResetOrphanUpdateRequest(): void {
      this.orphanUpdateRequestList = [];
    },

    async fetchDemandeEncours(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      const { idOperation, idDemande } = payload;

      if (!idOperation || !idDemande) {
        return;
      }

      const response = await demandeEncoursApi.fetchDemandeEcours(
        idOperation,
        idDemande
      );

      if (response.status === 200) {
        this.SetDemandeEncours(response.data);
      }
    },

    async fetchOperationDemandeEncoursList(): Promise<void> {
      const response =
        await demandeEncoursApi.fetchOperationDemandeEncoursList();

      if (response.status === 200) {
        this.SetOperationDemandeEncoursList(response.data);
      }
    },

    async createDemandeEncours(payload: {
      idOperation: string;
      idDemande: string;
      montantEncoursEuro: number;
    }): Promise<boolean> {
      if (!payload.idOperation || !payload.idDemande) {
        return false;
      }

      const encoursCreateRequest: DemandeEncoursCreateRequest = {
        montantEncoursEuro: payload.montantEncoursEuro,
      };

      try {
        const response = await demandeEncoursApi.createDemandeEncours(
          payload.idOperation,
          payload.idDemande,
          encoursCreateRequest
        );

        if (response.status === 201) {
          this.SetDemandeEncours(response.data);
          toastSuccess("Encours créé avec succès");
          return true;
        }

        return false;
      } catch (err) {
        toastError("Erreur lors de la création de l'encours");
        return false;
      }
    },

    async fetchOperationDemandeEncoursExport(
      encoursExportRequestList: OperationDemandeEncoursExportRequest[]
    ): Promise<void> {
      const response =
        await demandeEncoursApi.fetchOperationDemandeEncoursExport(
          encoursExportRequestList
        );

      const buffer = Buffer.from(response.data as string, "base64");
      downloadClickLink(buffer, {
        name: "export_global_encours.xlsx",
        type: NemmoMimeType.EXCEL,
      });
    },

    async fetchLatestImportOrphanLignes(): Promise<void> {
      const response = await demandeEncoursApi.fetchLatestImportOrphanLignes();

      if (response.status === 200) {
        this.SetEncoursOrphans(response.data);
      }
    },

    async linkOrphanLines(): Promise<void> {
      try {
        const response = await demandeEncoursApi.linkOrphanLines(
          this.orphanUpdateRequestList
        );

        if (response.status === 201) {
          this.ResetOrphanUpdateRequest();
          await this.fetchLatestImportOrphanLignes();
          toastSuccess("Les encours ont été créés avec succès");
        }
      } catch (err) {
        logger.error(err);
        toastError("Erreur lors de la création des encours");
      }
    },
  },
});
