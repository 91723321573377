import mitt from "mitt";
import type { Emitter, EventHandlerMap, EventType } from "mitt";
import { inject, InjectionKey, Plugin } from "vue";
import { ObjectValues } from "@/utils/typeUtils";
import { EncoursEmitterEvents } from "@/utils/encoursUtils";

/* ---------------------------------- types --------------------------------- */

export type EventCollection = Record<EventType, unknown>;

type InferEmitter<T> = T extends InjectionKey<infer Emitter> ? Emitter : never;

type InferEvents<T> =
  InferEmitter<T> extends Emitter<infer Events> ? Events : never;

/* -------------------------------- registry -------------------------------- */

/** Emitter injection key collection */
export const GLOBAL_EMITTER = {
  encours: createEmitterInjectionKey<EncoursEmitterEvents>("encours"),
} as const;

/* --------------------------------- plugin --------------------------------- */

export const globalEmitters: Plugin = {
  install: (app) => {
    Object.values(GLOBAL_EMITTER).forEach((injectionKey) => {
      const emitter = createEmitter<InferEvents<typeof injectionKey>>();
      app.provide(injectionKey, emitter);
    });
  },
};

/* ---------------------------------- utils --------------------------------- */

export function createEmitterInjectionKey<Events extends EventCollection>(
  name: string
): InjectionKey<Emitter<Events>> {
  const key = `EmitterInjectionKey:${name}`;
  return Symbol(key);
}

export function createEmitter<Events extends EventCollection>(
  all?: EventHandlerMap<Events>
): Emitter<Events> {
  return mitt<Events>(all);
}

export function useGlobalEmitter<T extends ObjectValues<typeof GLOBAL_EMITTER>>(
  key: T
): InferEmitter<T> | undefined {
  return inject(key);
}
