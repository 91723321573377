import demandeApi from "@/api/demandeApi";
import documentApi from "@/api/documentApi";
import { Demande } from "@/domain/demande";
import { DemandeCreationRequest } from "@domain/dto/demandeCreationRequest";
import { DemandeUpdateRequest } from "@domain/dto/demandeUpdateRequest";
import { DemandeStep } from "@domain/enum/demandeStep";
import { SignMethod } from "@domain/enum/signMethod";
import { ValidationStatus } from "@domain/enum/validationStatus";
import { OperationDocument } from "@domain/dto/operationDocument";

import acteSecondaireApi from "@/api/acteSecondaireApi";
import signataireExterneApi from "@/api/demandeSignataireExterneApi";
import { DemandeDocumentValidation } from "@/domain/demandeDocumentValidation";
import { ActeSecondaireCreationRequest } from "@domain/dto/acteSecondaireCreationRequest";
import { CMPCreationRequest } from "@domain/dto/cmpCreationRequest";
import { CmpUpdateRequest } from "@domain/dto/cmpUpdateRequest";
import { DemandeDocumentValidationRequest } from "@domain/dto/demandeDocumentValidationRequest";
import { SignataireExterneUpdateRequest } from "@domain/dto/signataireExterneUpdateRequest";
import { SignataireHeavy } from "@domain/dto/signataireHeavy";
import { CMPStatus } from "@domain/enum/CMPStatus";
import { DemandeSharingType } from "@domain/enum/demandeSharingType";
import { ValidationCMPError } from "@/domain/enum/validationCMPError";
import { WorkflowRollbackType } from "@/domain/enum/workflowRollbackType";
import {
  buildDemandeNewSignataireBanqueCountCreateRequest,
  buildDemandeNewSignatairePromoteurCountCreateRequest,
  createDemande,
  demandeTypeLabel,
  getDemandePrincipale,
} from "@/utils/demandeUtils";
import { getDocExtension } from "@domain/utils/documentExtensionsUtils";
import { createOperationDocument } from "@/utils/documentUtils";
import {
  downloadClickLink,
  setupDocumentPreview,
} from "@/utils/downloadFileUtils";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import {
  computeExceedFileBatchSizeError,
  isFileBatchUploadable,
} from "@/utils/uploadFileUtils";
import { defineStore } from "pinia";
import useKeycloakStore from "./keycloakModule.pinia";
import { ModuleType } from "./moduleType.pinia";
import { useOperationStore } from "./operationModule.pinia";
import { usePanelStore } from "./panelModule.pinia";
import { DemandeSignataireCreateRequest } from "@domain/dto/demandeSignataireCreateRequest";
import { PendingDocument } from "@domain/dto/pendingDocument";
import {
  formatDocusignConfigurationErrorList,
  isSignataireExterneValid,
} from "@/utils/signatureUtils";
import { isDocusignConfigurationErrorList } from "@domain/utils/docusignUtils";
import { isDemandeSignatureCommonErrorType } from "@domain/dto/errors/demandeSignatureError";
import { DemandeSignatureErrorType } from "@domain/enum/demandeSignatureErrorType";
import logger from "@/logger.ts";

type DemandeStateType = {
  visibleStep: DemandeStep;
  demande: Demande;
  demandeProjectDocument: OperationDocument;
  demandeActeDocument: OperationDocument;
  demandeSignedActeDocument: OperationDocument;
  demandeCertificateDocument: OperationDocument;
  demandeDocuments: OperationDocument[];
  creationAvenantParentDemande: Demande;
  demandeAchevementMainLeveeDocumentList: OperationDocument[];
  idDemandeALever: string;
};

export const useDemandeStore = defineStore(ModuleType.Demande, {
  state: (): DemandeStateType => ({
    visibleStep: DemandeStep.PREPARATION,
    demande: createDemande(),
    demandeProjectDocument: createOperationDocument(),
    demandeActeDocument: createOperationDocument(),
    demandeSignedActeDocument: createOperationDocument(),
    demandeCertificateDocument: createOperationDocument(),
    demandeDocuments: [],
    creationAvenantParentDemande: createDemande(),
    demandeAchevementMainLeveeDocumentList: [],
    idDemandeALever: "",
  }),
  getters: {
    getDemande(state): Demande {
      return state.demande;
    },

    getDemandePrincipale(state): Demande {
      return getDemandePrincipale(state.demande);
    },

    getIdDemandeALever(state): string {
      return state.idDemandeALever;
    },

    getDemandeProjectDocument(state): OperationDocument {
      return state.demandeProjectDocument;
    },

    getDemandeActeDocument(state): OperationDocument {
      return state.demandeActeDocument;
    },

    getDemandeSignedActeDocument(state): OperationDocument {
      return state.demandeSignedActeDocument;
    },

    getDemandeCertificateDocument(state): OperationDocument {
      return state.demandeCertificateDocument;
    },

    getDemandeSignaturePromoteurCount(state): number {
      return state.demande.signatairePromoteurCount;
    },

    getDemandeSignatureBanqueCount(state): number {
      return state.demande.signataireBanqueCount;
    },

    getDemandeSignataireList(state): SignataireHeavy[] {
      return state.demande.signataireList;
    },

    hasDemande(state): boolean {
      return state.demande.id !== "";
    },

    getDemandeDocuments(state): OperationDocument[] {
      return state.demandeDocuments;
    },

    hasDemandeProjectDocument(): boolean {
      return this.getDemandeProjectDocument.id !== "";
    },

    hasDemandeActeDocument(): boolean {
      return this.getDemandeActeDocument.id !== "";
    },

    getDemandeType(state): string {
      return demandeTypeLabel(state.demande.type);
    },

    getCreationAvenantParentDemande(state): Demande {
      return state.creationAvenantParentDemande;
    },

    getDemandeSignatureConfigurationReady(state): boolean {
      const signataireCount =
        state.demande.signatairePromoteurCount +
        state.demande.signataireBanqueCount;
      const currentSignataireCount = state.demande.signataireList.length;

      if (this.isDemandeUnilaterale) {
        const currentSignataireExterneCount =
          state.demande.signataireExterneList.length;

        return (
          signataireCount ===
            currentSignataireCount + currentSignataireExterneCount &&
          state.demande.signataireExterneList.every((signataire) =>
            isSignataireExterneValid(signataire)
          )
        );
      }

      return signataireCount === currentSignataireCount;
    },

    getAchevementMainleveeDocumentList(state): OperationDocument[] {
      return state.demandeAchevementMainLeveeDocumentList;
    },

    getPrincipaleOrParentDemande(state): Demande {
      if (state.demande.demandePrincipale) {
        return state.demande.demandePrincipale;
      }
      if (state.demande.parent) {
        if (state.demande.parent.demandePrincipale)
          return state.demande.parent.demandePrincipale;
        return state.demande.parent;
      }
      return state.demande;
    },

    isDemandeUnilaterale(state): boolean {
      return state.demande.demandeSharingType === DemandeSharingType.UNILATERAL;
    },
  },
  actions: {
    SetDemande(demande: Demande): void {
      this.demande = demande;
      this.visibleStep = demande.step;
    },

    SetDemandeProjectDocument(demandeProjectDocument: OperationDocument): void {
      this.demandeProjectDocument = demandeProjectDocument;
    },

    SetDemandeActeDocument(demandeActeDocument: OperationDocument): void {
      this.demandeActeDocument = demandeActeDocument;
    },

    ResetDemandeSignedActeDocument(): void {
      this.demandeSignedActeDocument = createOperationDocument();
    },

    SetDemandeSignedActeDocument(
      demandeSignedActeDocument: OperationDocument
    ): void {
      this.demandeSignedActeDocument = demandeSignedActeDocument;
    },

    SetDemandeCertificateDocument(
      demandeCertificateDocument: OperationDocument
    ): void {
      this.demandeCertificateDocument = demandeCertificateDocument;
    },

    SetCreationAvenantParentDemande(demande: Demande): void {
      this.creationAvenantParentDemande = demande;
    },

    SetDemandeAchevementMainleveeDocumentList(
      demandeAchevementMainLeveeDocumentList: OperationDocument[]
    ): void {
      this.demandeAchevementMainLeveeDocumentList =
        demandeAchevementMainLeveeDocumentList;
    },

    ResetDemandeAchevementMainleveeDocuments(): void {
      this.demandeAchevementMainLeveeDocumentList = [];
    },

    SetIdDemandeALever(idDemande: string): void {
      this.idDemandeALever = idDemande;
    },

    ResetIdDemandeALever(): void {
      this.idDemandeALever = "";
    },

    async createActeSecondaire(
      acteCreationRequest: ActeSecondaireCreationRequest
    ): Promise<void> {
      const panelStore = usePanelStore();
      const idOperation = useOperationStore().getCurrentOperationId;
      const { attestationList } = acteCreationRequest;
      const toastSuccessSentence =
        attestationList.length > 1
          ? `${attestationList.length} actes ont été liés avec la demande`
          : "Une attestation à été lié avec cette demande";
      const toastErrorSentence =
        attestationList.length > 1
          ? "Échec lors de la création des actes"
          : "Échec lors de la création de l'acte";
      acteSecondaireApi
        .create(acteCreationRequest, idOperation)
        .then((response) => {
          if (response?.status === 201) {
            toastSuccess(toastSuccessSentence);
            this.fetchDemande({
              idOperation,
              idDemande: this.demande.id,
            });
          } else {
            toastError(toastErrorSentence);
          }
        })
        .catch((e) => toastError(e));
      panelStore.CloseSidePanel();
    },

    async createConditions(
      conditionCreationRequest: CMPCreationRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const idDemande = this.getDemande.id;
      demandeApi
        .createConditions(conditionCreationRequest, idOperation, idDemande)
        .then((response) => {
          if (response?.status === 201) {
            toastSuccess("Conditions créées avec succès");
            this.SetDemande(response.data);
          } else {
            toastError("Échec lors de la création des conditions");
          }
        })
        .catch((e) => toastError(e));
    },

    async updateConditions(
      conditionUpdateRequestList: CmpUpdateRequest[]
    ): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const idDemande = this.getDemande.id;
      demandeApi
        .updateConditions(conditionUpdateRequestList, idOperation, idDemande)
        .then((response) => {
          if (response?.status === 200) {
            toastSuccess("Conditions mises à jour avec succès");
            this.SetDemande(response.data);
          } else {
            toastError(
              "Une erreur est survenue lors de l'édition des conditions de mises en place"
            );
          }
        })
        .catch(() =>
          toastError(
            "Une erreur est survenue lors de l'édition des conditions de mises en place"
          )
        );
    },

    async createDemandes(demandes: DemandeCreationRequest[]): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const toastSuccessSentence =
        demandes.length > 1
          ? "Demandes créées avec succès"
          : "Demande créée avec succès";
      const toastErrorSentence =
        demandes.length > 1
          ? "Échec lors de la création de demandes"
          : "Échec lors de la création d'une demande";
      demandeApi
        .create(demandes, idOperation)
        .then((response) => {
          if (response?.status === 201) {
            toastSuccess(toastSuccessSentence);
            useOperationStore().fetchOperationDetailsById(idOperation);
          } else if (response?.status === 409) {
            toastError(toastErrorSentence);
          }
        })
        .catch((e) => toastError(e));
      usePanelStore().CloseSidePanel();
    },
    async createAvenant(avenant: DemandeCreationRequest): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const toastSuccessSentence = "Avenant créée avec succès";
      const toastErrorSentence = "Échec lors de la création de l'avenant";
      demandeApi
        .createAvenant(avenant, idOperation)
        .then((response) => {
          if (response?.status === 201) {
            toastSuccess(toastSuccessSentence);
            useOperationStore().fetchOperationDetailsById(idOperation);
          } else if (response?.status === 409) {
            toastError(toastErrorSentence);
          }
        })
        .catch((e) => toastError(e));
      usePanelStore().CloseSidePanel();
    },
    async createDemandeDocumentValidation(
      documentValidationRequest: DemandeDocumentValidationRequest,
      file: File
    ): Promise<PendingDocument | undefined> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const idDemande = this.demande.id;

      try {
        const result = await demandeApi.createDocumentValidation(
          idOperation,
          idDemande,
          documentValidationRequest,
          file
        );

        return result.data.shift();
      } catch (error) {
        logger.error(error);
        toastError(
          "Une erreur est survenue lors de l'ajout du document. Veuillez réessayer ultérieurement"
        );
        throw new Error();
      }
    },

    async downloadDemandeDocumentValidation(
      demandeDocumentValidation: DemandeDocumentValidation
    ): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const idDemande = this.demande.id;
      demandeApi
        .downloadDemandeDocumentValidation(
          idOperation,
          idDemande,
          demandeDocumentValidation.id
        )
        .then((response) => {
          const documentStream: BlobPart = response.data;
          downloadClickLink(documentStream, {
            name: demandeDocumentValidation.fileName,
            type: getDocExtension(demandeDocumentValidation.fileName),
          });
        })
        .catch(() => {
          toastError("Erreur lors du téléchargement du document.");
        });
    },

    async previewDemandeDocumentValidation(
      idDocumentValidation: string
    ): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const idDemande = this.demande.id;

      let response;
      try {
        response = await demandeApi.downloadDemandeDocumentValidation(
          idOperation,
          idDemande,
          idDocumentValidation
        );
        setupDocumentPreview(response.data);
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement du document.");
      }
    },

    async deleteDemandeDocumentValidation(
      idDocumentValidation: string
    ): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const idDemande = this.demande.id;
      const errorMessage = "Le document à valider n'a pas été retirée.";
      demandeApi
        .deleteDemandeDocumentValidation(
          idOperation,
          idDemande,
          idDocumentValidation
        )
        .then((result) => {
          if (result.status === 200) {
            toastSuccess(
              "Le document à valider a bien été retirée de la demande"
            );
            this.fetchDemande({
              idOperation,
              idDemande,
            });
          } else {
            toastError(errorMessage);
          }
        })
        .catch(() => toastError(errorMessage));
    },

    async uploadDemandeProject(
      document: File
    ): Promise<PendingDocument | undefined> {
      if (!isFileBatchUploadable([{ file: document }])) {
        toastError(computeExceedFileBatchSizeError([{ file: document }]));
        return Promise.reject();
      }

      const idOperation = useOperationStore().getCurrentOperationId;
      const idDemande = this.getDemande.id;

      const result = await documentApi.uploadDemandeProject(
        idOperation,
        idDemande,
        document
      );

      return result.data.shift();
    },

    async fetchDemande(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      const result = await demandeApi.fetchDemande(
        payload.idOperation,
        payload.idDemande
      );
      if (result.data) {
        this.SetDemande(result.data);
      } else {
        this.$reset();
      }
    },

    async fetchDemandeProject(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      const result = await demandeApi.fetchDemandeProject(
        payload.idOperation,
        payload.idDemande
      );

      if (result.data) {
        this.SetDemandeProjectDocument(result.data);
      } else {
        this.SetDemandeProjectDocument(createOperationDocument());
      }
    },

    async fetchDemandeActe(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      const result = await documentApi.fetchDemandeActe(
        payload.idOperation,
        payload.idDemande
      );

      if (result.data) {
        this.SetDemandeActeDocument(result.data);
      } else {
        this.SetDemandeActeDocument(createOperationDocument());
      }
    },

    async fetchDemandeSignedAct(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      this.ResetDemandeSignedActeDocument();

      const result = await demandeApi.fetchDemandeSignedAct(
        payload.idOperation,
        payload.idDemande
      );

      if (result.data) {
        this.SetDemandeSignedActeDocument(result.data);
      }
    },

    async fetchDemandeCertificate(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      const result = await demandeApi.fetchDemandeCertificate(
        payload.idOperation,
        payload.idDemande
      );

      if (result.data) {
        this.SetDemandeCertificateDocument(result.data);
      } else {
        this.SetDemandeCertificateDocument(createOperationDocument());
      }
    },

    async cancelDemandeValidation(): Promise<void> {
      const keycloakStore = useKeycloakStore();

      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      const isPromoteur: boolean = keycloakStore.isPromoteur;
      const isBanque: boolean = keycloakStore.isBanque;
      const demandeUpdateRequest: DemandeUpdateRequest = {};
      if (isPromoteur) {
        demandeUpdateRequest.statusPromoteur = ValidationStatus.UNCHECKED;
      } else if (isBanque) {
        demandeUpdateRequest.statusBanque = ValidationStatus.UNCHECKED;
      }
      const result = await demandeApi.update(
        idOperation,
        idDemande,
        demandeUpdateRequest
      );
      if (result.status === 200) {
        this.SetDemande(result.data);
        toastSuccess("Annulation réalisée avec succès");
      } else {
        toastError("L'annulation n'a pas fonctionné");
      }
    },

    async validateDemandeProject(
      demandeUpdateRequest: DemandeUpdateRequest
    ): Promise<number> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      const result = await demandeApi.update(
        idOperation,
        idDemande,
        demandeUpdateRequest
      );
      if (result.status === 200) {
        this.SetDemande(result.data);
        toastSuccess("Projet validé avec succès");

        if (result.data.step === DemandeStep.SIGNATURE) {
          await this.fetchDemandeActe({
            idOperation,
            idDemande,
          });
        }
      } else if (result.status === 409) {
        toastError("Ce numéro d'acte est deja utilisé");
      } else {
        toastError("Le projet n'a pas été validée");
      }
      return result.status;
    },

    async updateDemandeSignMethod(signMethod: SignMethod): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      const demandeUpdateRequest: DemandeUpdateRequest = {
        signMethod: signMethod,
      };
      return demandeApi
        .update(idOperation, idDemande, demandeUpdateRequest)
        .then((response) => {
          toastSuccess("Votre choix de signature a bien été enregistré");
          this.SetDemande(response.data);
        })
        .catch(() => {
          toastError("Votre choix de signature n'a pas pu être enregistré");
        });
    },

    async manuallySignDemande(): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      const demandeUpdateRequest: DemandeUpdateRequest = {
        incrementStep: true,
      };
      return demandeApi
        .update(idOperation, idDemande, demandeUpdateRequest)
        .then((response) => {
          toastSuccess("Votre signature a bien été enregistrée");
          this.SetDemande(response.data);
        })
        .catch(() => {
          toastError("Votre signature n'a pas pu être enregistrée");
        });
    },

    async initializeDemandeSignature(): Promise<void> {
      try {
        const idOperation = this.getDemande.idOperation;
        const idDemande = this.getDemande.id;
        const response = await demandeApi.initializeSignature(
          idOperation,
          idDemande
        );

        const status = response.status;
        const data = response.data;

        if (
          status === 200 &&
          !isDocusignConfigurationErrorList(data) &&
          !isDemandeSignatureCommonErrorType(data)
        ) {
          this.SetDemande(data);
          return toastSuccess("Enveloppe créée avec succès");
        }

        if (status === 409 && isDemandeSignatureCommonErrorType(data)) {
          if (data === DemandeSignatureErrorType.BAD_DOCUMENT_FORMAT) {
            return toastWarn(
              "Impossible de créer l'enveloppe de signature Docusign. Veuillez revenir à la co-construction et charger un fichier PDF.",
              15 * 1000
            );
          }

          return toastWarn(
            "Le document à signer n'est pas au format A4 portrait. Veuillez utiliser la signature libre pour positionner les tampons",
            15 * 1000
          );
        }

        if (status === 400 && isDocusignConfigurationErrorList(data)) {
          return toastError(formatDocusignConfigurationErrorList(data));
        }

        toastError("Erreur lors de la création de l'enveloppe");
      } catch (error) {
        logger.error(error);
        toastError("Une erreur est survenue. Veuillez réessayer");
      }
    },

    async startDemandeSignatureCeremonie(): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      return demandeApi
        .startDemandeSignatureCeremonie(idOperation, idDemande)
        .then((response) => {
          if (response.status === 200) {
            const url = response.data;
            window.location = url as unknown as Location;
          } else {
            toastError("Erreur lors de la création de la vue");
          }
        })
        .catch(() => {
          toastError("Erreur lors de la création de la vue");
        });
    },

    async updateDemande(
      demandeUpdateRequest: DemandeUpdateRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = demandeUpdateRequest.id ?? this.getDemande.id;
      const result = await demandeApi.update(
        idOperation,
        idDemande,
        demandeUpdateRequest
      );
      if (result.status === 200) {
        toastSuccess("Le champ a bien été modifié");
        this.SetDemande(result.data);
      } else if (result.status === 409) {
        toastError("Ce numéro d'acte est déjà utilisé");
      } else {
        toastError("Une erreur est survenue lors de la modification du champ");
      }
    },

    async updateAttestation(
      attestationUpdateRequestList: DemandeUpdateRequest[]
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;

      const result = await demandeApi.updateAttestationList(
        idOperation,
        attestationUpdateRequestList
      );
      if (result.status === 200) {
        toastSuccess("Les attestations ont bien été modifiées");
        await this.fetchDemande({
          idOperation,
          idDemande: this.demande.id,
        });
      } else {
        toastError(
          "Une erreur est survenue lors de la modification des attestations existantes"
        );
      }
    },

    async passToNextStep(): Promise<void> {
      const idOperation = this.getDemande.idOperation;
      const demande = this.getDemande;
      let demandeToUpdate: DemandeUpdateRequest = { incrementStep: true };
      if (demande.step == DemandeStep.CO_CONSTRUCTION) {
        demandeToUpdate = {
          ...demandeToUpdate,
          signMethod: SignMethod.ELECTRONIC,
        };
      }

      const result = await demandeApi.update(
        idOperation,
        demande.id,
        demandeToUpdate
      );
      if (result.status === 200) {
        this.SetDemande(result.data);
        if (result.data.step == DemandeStep.CO_CONSTRUCTION) {
          toastSuccess("Demande validée avec succès");
        }
      } else {
        toastError(
          "Une erreur est survenue lors de la mise à jour de la demande"
        );
      }
    },

    async updateSignataireExterne(
      idSignataire: string,
      signataireExterne: SignataireExterneUpdateRequest
    ): Promise<boolean> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      const result = await signataireExterneApi.update(
        idOperation,
        idDemande,
        idSignataire,
        signataireExterne
      );

      if (result.status === 201) {
        toastSuccess("Signataire modifié avec succès");
        await this.fetchDemande({
          idOperation,
          idDemande: this.demande.id,
        });
        return true;
      } else {
        toastError("Erreur lors de la modification du signataire");
        return false;
      }
    },

    async updateDemandeSignatureNewSignataireConfiguration(
      demandeSignataireCreateRequest: DemandeSignataireCreateRequest
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;

      return demandeApi
        .updateDemandeSignatureConfiguration(
          idOperation,
          idDemande,
          demandeSignataireCreateRequest
        )
        .then((result) => {
          if (result.status === 201 && result.data) {
            this.SetDemande(result.data);
            toastSuccess("Signataire ajouté avec succès");
          } else if (result.status === 409) {
            toastError(
              "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
            );
          }
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du signataire");
        });
    },

    async updateDemandeSignataireBanqueCountConfiguration(
      signataireBanqueCount: number
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      const demandeSignataireCreateRequest =
        buildDemandeNewSignataireBanqueCountCreateRequest(
          this.demande,
          signataireBanqueCount
        );
      return demandeApi
        .updateDemandeSignatureConfiguration(
          idOperation,
          idDemande,
          demandeSignataireCreateRequest
        )
        .then((result) => {
          if (result.status === 201 && result.data) {
            this.SetDemande(result.data);
          } else if (result.status === 409) {
            toastError(
              "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte"
            );
          }
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du signataire");
        });
    },

    async updateDemandeSignatairePromoteurCountConfiguration(
      signatairePromoteurCount: number
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      const idDemande = this.getDemande.id;
      const demandeSignataireCreateRequest =
        buildDemandeNewSignatairePromoteurCountCreateRequest(
          this.demande,
          signatairePromoteurCount
        );
      return demandeApi
        .updateDemandeSignatureConfiguration(
          idOperation,
          idDemande,
          demandeSignataireCreateRequest
        )
        .then((result) => {
          if (result.status === 201 && result.data) {
            this.SetDemande(result.data);
          } else if (result.status === 409) {
            toastError(
              "Un autre utilisateur a déjà sélectionné des signataires pour la signature de cet acte !"
            );
          }
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du signataire");
        });
    },

    async rollbackDemandeWorkflow(
      rollbackType: WorkflowRollbackType
    ): Promise<void> {
      const idOperation = this.getDemande.idOperation;
      const idDemande = this.getDemande.id;
      await demandeApi.rollbackWorkflow(idOperation, idDemande, rollbackType);
    },

    async deleteDemande(idDemandeToDelete: string): Promise<void> {
      const idDemande = idDemandeToDelete ?? this.getDemande.id;
      const idOperation = useOperationStore().getOperation.id;

      await demandeApi
        .deleteDemande(idOperation, idDemande)
        .then((response) => {
          if (response.status == 200) {
            toastSuccess("Demande supprimée");
            useOperationStore().fetchOperationDetailsById(idOperation);
          } else {
            toastError(
              "Une erreur s'est produite lors de la suppression de la demande"
            );
          }
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la suppression de la demande"
          );
        });
    },

    async deleteConditions(conditionIdList: string[]): Promise<void> {
      const idDemande = this.getDemande.id;
      const idOperation = useOperationStore().getOperation.id;
      const toastMessage =
        conditionIdList.length > 1
          ? `${conditionIdList.length} conditions supprimées`
          : `1 condition supprimée`;
      await demandeApi
        .deleteConditions(idOperation, idDemande, conditionIdList)
        .then((response) => {
          if (response.status === 200) {
            toastSuccess(toastMessage);
            this.SetDemande(response.data);
          } else {
            toastError(
              "Une erreur s'est produite lors de la suppression des conditions"
            );
          }
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la suppression des conditions"
          );
        });
    },

    async deleteAttestation(idAttestationToDelete: string): Promise<void> {
      const idOperation = useOperationStore().getOperation.id;
      await demandeApi
        .deleteAttestation(idOperation, idAttestationToDelete)
        .then((response) => {
          if (response.status == 200) {
            toastSuccess("Attestation supprimée");
          } else {
            toastError(
              "Une erreur s'est produite lors de la suppression de l'attestation"
            );
          }
        })
        .catch(() => {
          toastError(
            "Une erreur s'est produite lors de la suppression de l'attestation"
          );
        });
    },

    async fetchDemandeAchevementMainlevee(payload: {
      idOperation: string;
      idDemande: string;
    }): Promise<void> {
      this.SetDemandeAchevementMainleveeDocumentList([]);

      const result = await documentApi.fetchDemandeAchevementMainLevee(
        payload.idOperation,
        payload.idDemande
      );

      if (result.status === 200 && result.data) {
        this.SetDemandeAchevementMainleveeDocumentList(result.data);
      }
    },

    async validateCMP(payload: {
      idOperation: string;
      idDemande: string;
      idCondition: string;
      status: CMPStatus;
    }): Promise<boolean> {
      try {
        const response = await demandeApi.validateCMP(
          payload.idOperation,
          payload.idDemande,
          payload.idCondition,
          payload.status
        );
        if (response.status === 204) {
          await this.fetchDemande({
            idOperation: payload.idOperation,
            idDemande: payload.idDemande,
          });
          return true;
        } else if (response.status === 404) {
          throw new Error(ValidationCMPError.CONDITION_NOT_FOUND);
        } else {
          throw new Error("unknown_error");
        }
      } catch (error) {
        toastError("Échec lors de la validation de la condition.");
        logger.error(
          `Erreur de la mise à jour de la condition idCondition='${payload.idCondition}' idDemande='${payload.idDemande}'`,
          error
        );
        return false;
      }
    },
    async remindSignatureToRecipient(
      idOperation: string,
      idDemande: string,
      idRecipient: number
    ): Promise<void> {
      const response = await demandeApi.remindSignatureToRecipient(
        idOperation,
        idDemande,
        idRecipient
      );
      if (response.status != 201) {
        toastError("Erreur lors de la relance");
        return;
      }
      toastSuccess("Le mail de relance a bien été renvoyé");
    },

    async declareMainLeveeEffectuee(): Promise<void> {
      try {
        const idOperation = useOperationStore().getOperation.id;
        const idDemande = this.getDemande.id;
        const result = await demandeApi.declareMainLeveeEffectuee(
          idOperation,
          idDemande
        );
        if (result.status === 200) {
          this.SetDemande(result.data);
        }
      } catch (error) {
        toastError("Erreur à déclaration hors plateforme de la mainlevée");
      }
    },
  },
});
