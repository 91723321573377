import pino from "pino";

const logger = pino({
  level: "info",
  transport: {
    target: "pino-pretty",
    options: {
      translateTime: "yyyy-mm-dd HH:MM:ss", // Formats time
      ignore: "pid,hostname", // Hides unnecessary fields
    },
  },
});

export default logger;
