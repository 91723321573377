import { SuiviBudgetOperationImport } from "@domain/dto/suiviBudgetOperationImport";
import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import suiviBudgetApi from "@/api/suiviBudgetApi";
import { SuiviBudgetDocumentUpload } from "@/domain/suiviBudgetDocumentUpload";
import { buildSuiviBudgetImportCreation } from "@/utils/suiviBudgetImportPromoteurUtils";
import { downloadClickLink } from "@/utils/downloadFileUtils";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { SuiviBudgetResponse } from "@domain/dto/suiviBudgetResponse";
import { SuiviBudgetStep } from "@domain/enum/suiviBudgetStep";
import { useDocumentStore } from "@/store/documentModule.pinia";
import { useOperationStore } from "@/store/operationModule.pinia";
import { usePanelStore } from "@/store/panelModule.pinia";
import { SuiviBudgetPromoteurImportWithLignes } from "@domain/dto/suiviBudgetPromoteurImport";
import { getLatestByCreatedAt } from "@/utils/dateUtils";
import { SuiviBudgetConfigurationCreationRequest } from "@domain/dto/suiviBudgetConfigurationCreationRequest";
import { useUserProfileStore } from "@/store/userProfileModule.pinia";
import { toastError, toastSuccess, toastWarn } from "@/utils/toastUtils";
import { SuiviBudgetPromoteurLigne } from "@domain/dto/suiviBudgetPromoteurLigne";
import { SuiviBudgetPoste } from "@domain/enum/suiviBudgetPoste";
import {
  buildSuiviBudgetModeleCreationRequestFromCreationState,
  buildSuiviBudgetModeleLigneCreationInitialStateList,
} from "@/utils/suiviBudgetUtils";
import { SuiviBudgetModeleWithCompletion } from "@domain/dto/suiviBudgetModeleWithCompletion";

export enum SuiviBudgetVue {
  GLOBAL = "GLOBAL",
  TRANCHE = "TRANCHE",
}

export enum SuiviBudgetCardType {
  EXISTING_MODELE = "EXISTING_MODELE",
  NEW_MODELE = "NEW_MODELE",
}

export enum SuiviBudgetModeleConfigurationView {
  MODELE_CREATION = "MODELE_CREATION",
  MODELE_EDITION = "MODELE_EDITION",
}

export interface SuiviBudgetModeleLigneCreationState
  extends SuiviBudgetPromoteurLigne {
  selected: boolean;
  nomPosteNeomi: SuiviBudgetPoste;
}

export interface SuiviBudgetModeleCreationState {
  name: string;
  lignes: SuiviBudgetModeleLigneCreationState[];
}

type SuiviBudgetStateType = {
  suiviBudgetImportDocumentContent: string[][];
  suiviBudgetResponse: SuiviBudgetResponse;
  suiviBudgetConfigurationCreationRequest: SuiviBudgetConfigurationCreationRequest;
  suiviBudgetModeleCreationState: SuiviBudgetModeleCreationState;
  availableSuiviBudgetModeleList: SuiviBudgetModeleWithCompletion[];
  suiviBudgetCardSelected: SuiviBudgetCardType;
  existingModeleSelected: SuiviBudgetModeleWithCompletion | null;
  modeleConfigurationView: SuiviBudgetModeleConfigurationView | null;
  vue: SuiviBudgetVue;
};

export const useSuiviBudget = defineStore(ModuleType.SuiviBudget, {
  state: (): SuiviBudgetStateType => ({
    suiviBudgetImportDocumentContent: [],
    suiviBudgetResponse: {
      step: SuiviBudgetStep.INITIALIZATION,
      operationImportList: [],
      importList: [],
    },
    suiviBudgetConfigurationCreationRequest: {
      idOperation: "",
      idBanque: "",
      montantHt: -1,
      montantTtc: -1,
      nomPoste: -1,
      premiereLigne: -1,
    },
    suiviBudgetModeleCreationState: {
      name: "",
      lignes: [],
    },
    availableSuiviBudgetModeleList: [],
    suiviBudgetCardSelected: SuiviBudgetCardType.NEW_MODELE,
    existingModeleSelected: null,
    modeleConfigurationView: null,
    vue: SuiviBudgetVue.GLOBAL,
  }),

  getters: {
    getSuiviBudgetList(state): SuiviBudgetOperationImport[] {
      return state.suiviBudgetResponse.operationImportList;
    },
    getSuiviBudgetStep(state): SuiviBudgetStep {
      return state.suiviBudgetResponse.step;
    },
    getSuiviBudgetImportList(state): SuiviBudgetPromoteurImportWithLignes[] {
      return state.suiviBudgetResponse.importList;
    },
    getSuiviBudgetImportDocumentContent(state): string[][] {
      return state.suiviBudgetImportDocumentContent;
    },
    getSuiviBudgetConfigurationCreationRequest(
      state
    ): SuiviBudgetConfigurationCreationRequest {
      return state.suiviBudgetConfigurationCreationRequest;
    },
    getSuiviBudgetVue(state): SuiviBudgetVue {
      return state.vue;
    },
    getAvailableSuiviBudgetModeleList(
      state
    ): SuiviBudgetModeleWithCompletion[] {
      return state.availableSuiviBudgetModeleList;
    },
    getSuiviBudgetCardSelected(state): SuiviBudgetCardType {
      return state.suiviBudgetCardSelected;
    },
    getExistingModeleSelected(state): SuiviBudgetModeleWithCompletion | null {
      return state.existingModeleSelected;
    },
    getModeleConfigurationView(
      state
    ): SuiviBudgetModeleConfigurationView | null {
      return state.modeleConfigurationView;
    },
    getSuiviBudgetModeleCreationState(state): SuiviBudgetModeleCreationState {
      return state.suiviBudgetModeleCreationState;
    },
    getSuiviBudgetModeleLigneCreationStateList(
      state
    ): SuiviBudgetModeleLigneCreationState[] {
      return state.suiviBudgetModeleCreationState.lignes;
    },
    getMostRecentSuiviBudgetImport(
      state
    ): SuiviBudgetPromoteurImportWithLignes | undefined {
      return getLatestByCreatedAt(state.suiviBudgetResponse.importList);
    },
    getSelectedBanqueAsReference(): string | undefined {
      return this.suiviBudgetResponse.importList.find(
        (suiviImport) => !!suiviImport.idBanqueSelectedAsReference
      )?.idBanqueSelectedAsReference;
    },
  },
  actions: {
    SetSuiviBudgetVue(vue: SuiviBudgetVue): void {
      this.vue = vue;
    },
    SetSuiviBudgetImportDocumentContent(
      suiviBudgetImportDocumentContent: string[][]
    ): void {
      this.suiviBudgetImportDocumentContent = suiviBudgetImportDocumentContent;
    },
    SetAvailableSuiviBudgetModeleList(
      availableSuiviBudgetModeleList: SuiviBudgetModeleWithCompletion[]
    ): void {
      this.availableSuiviBudgetModeleList = availableSuiviBudgetModeleList;
    },
    SetSuiviBudgetCardSelected(
      suiviBudgetCardSelected: SuiviBudgetCardType
    ): void {
      this.suiviBudgetCardSelected = suiviBudgetCardSelected;
    },
    SetExistingModeleSelected(
      existingModeleSelected: SuiviBudgetModeleWithCompletion
    ): void {
      this.existingModeleSelected = existingModeleSelected;
    },
    SetAutoExistingModelSelected(): void {
      this.existingModeleSelected =
        this.availableSuiviBudgetModeleList.find(
          (model) => model.isLatestUsedInOperation
        ) ?? null;
    },

    SetModeleConfigurationView(
      modeleConfigurationView: SuiviBudgetModeleConfigurationView
    ): void {
      this.modeleConfigurationView = modeleConfigurationView;
    },
    SetSuiviBudgetConfigurationCreationRequest(
      suiviBudgetConfigurationCreationRequest: SuiviBudgetConfigurationCreationRequest
    ): void {
      this.suiviBudgetConfigurationCreationRequest =
        suiviBudgetConfigurationCreationRequest;
    },
    SetSuiviBudgetModeleCreationState(
      modeleCreationState: SuiviBudgetModeleCreationState
    ): void {
      this.suiviBudgetModeleCreationState = modeleCreationState;
    },
    SetSuiviBudgetResponse(suiviBudgetResponse: SuiviBudgetResponse): void {
      this.suiviBudgetResponse = suiviBudgetResponse;
    },
    InitSuiviBudgetModeleCreationState(
      modeleSelected?: SuiviBudgetModeleWithCompletion
    ): void {
      const mostRecentPromoteurImportLigneList =
        this.getMostRecentSuiviBudgetImport?.lignes || [];

      const initialLigneCreationStateList =
        buildSuiviBudgetModeleLigneCreationInitialStateList(
          mostRecentPromoteurImportLigneList,
          modeleSelected
        );

      this.SetSuiviBudgetModeleCreationState({
        name: modeleSelected?.name ?? "",
        lignes: initialLigneCreationStateList,
      });
    },
    ResetSuiviBudgetImportDocumentContent(): void {
      this.suiviBudgetImportDocumentContent = [];
    },
    ResetSuiviBudgetConfigurationCreationRequest(): void {
      this.suiviBudgetConfigurationCreationRequest = {
        idOperation: "",
        idBanque: "",
        montantHt: -1,
        montantTtc: -1,
        nomPoste: -1,
        premiereLigne: -1,
      };
    },
    ResetSuiviBudgetConfigurationColonnes(): void {
      const configuration = this.suiviBudgetConfigurationCreationRequest;
      this.suiviBudgetConfigurationCreationRequest = {
        ...configuration,
        montantHt: -1,
        montantTtc: -1,
        nomPoste: -1,
      };
    },
    ResetModeleConfigurationState(): void {
      this.availableSuiviBudgetModeleList = [];
      this.suiviBudgetCardSelected = SuiviBudgetCardType.NEW_MODELE;
      this.existingModeleSelected = null;
    },
    ResetSuiviBudgetModeleCreationState(): void {
      this.suiviBudgetModeleCreationState = {
        name: "",
        lignes: [],
      };
    },
    CloseModeleConfigurationView(): void {
      this.modeleConfigurationView = null;
    },
    ResetSuiviBudgetPanelState(): void {
      this.ResetSuiviBudgetImportDocumentContent();
      this.ResetSuiviBudgetConfigurationCreationRequest();
      this.ResetModeleConfigurationState();
      this.ResetSuiviBudgetModeleCreationState();
      this.CloseModeleConfigurationView();
      usePanelStore().CloseSidePanel();
    },

    async reloadSuiviBudgetList(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      await this.fetchSuiviBudgetList(idOperation);
    },

    async fetchSuiviBudgetList(idOperation: string): Promise<void> {
      const result = await suiviBudgetApi.fetchAllForOperation(idOperation);
      if (result.data) {
        this.SetSuiviBudgetResponse(result.data);
      }
    },

    async uploadSuiviBudgetDocumentList(
      idOperation: string,
      idBanque: string,
      documents: SuiviBudgetDocumentUpload[]
    ): Promise<void> {
      try {
        const importCreation = buildSuiviBudgetImportCreation(
          idOperation,
          idBanque,
          documents
        );
        await suiviBudgetApi.uploadSuiviBudgetPromoteur(importCreation);
      } catch (_error) {
        toastError("Erreur lors de l'ajout de document(s)");
      }
    },

    async downloadSuiviBudget(idOperation: string): Promise<void> {
      await suiviBudgetApi
        .downloadSuiviBudget(idOperation)
        .then((response) => {
          const buffer = Buffer.from(response.data, "base64");
          downloadClickLink(buffer, {
            name: "export_suivi_budget.xlsx",
            type: NemmoMimeType.EXCEL,
          });
        })
        .catch(() => {
          toastError("Erreur lors du téléchargement du document.");
        });
    },

    async fetchLatestSuiviBudgetImportDocumentContent(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const mostRecentImport = this.getMostRecentSuiviBudgetImport;

      if (!mostRecentImport) {
        return;
      }

      const response =
        await suiviBudgetApi.fetchSuiviBudgetImportDocumentContent(
          idOperation,
          mostRecentImport.id
        );

      if (response.data && response.status === 200) {
        this.SetSuiviBudgetImportDocumentContent(response.data);
      }
    },

    async createSuiviBudgetConfiguration(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const banque = useUserProfileStore().getUserBanqueGroup;

      if (!banque) {
        toastError(`Seul un utilisateur banque peut effectuer cette requête`);
        return;
      }

      const budgetConfiguration = {
        ...this.getSuiviBudgetConfigurationCreationRequest,
        idOperation,
        idBanque: banque.id,
      };
      const response =
        await suiviBudgetApi.createSuiviBudgetConfiguration(
          budgetConfiguration
        );
      if (response.status === 201) {
        await this.reloadSuiviBudgetList();
        toastSuccess(`Configuration sauvegardée`);
      }
    },

    async createSuiviBudgetModele(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const modeleCreationState = this.getSuiviBudgetModeleCreationState;

      const modeleCreationRequest =
        buildSuiviBudgetModeleCreationRequestFromCreationState(
          modeleCreationState
        );

      const response = await suiviBudgetApi.createSuiviBudgetModele(
        idOperation,
        modeleCreationRequest
      );

      if (response.status === 409) {
        toastError("Ce nom est utilisé par un autre modèle.");
        return;
      }

      if (response.status === 201) {
        await this.reloadSuiviBudgetList();
        toastSuccess("Modèle sauvegardé");
      }
    },

    async updateSuiviBudgetModele(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const currentModeleSelected = this.getExistingModeleSelected;
      const modeleCreationState = this.getSuiviBudgetModeleCreationState;

      if (!currentModeleSelected) {
        return;
      }

      const modeleCreationRequest =
        buildSuiviBudgetModeleCreationRequestFromCreationState(
          modeleCreationState
        );

      const response = await suiviBudgetApi.updateSuiviBudgetModele(
        idOperation,
        currentModeleSelected.id,
        modeleCreationRequest
      );

      if (response.status === 200) {
        await this.reloadSuiviBudgetList();
        toastSuccess("Modèle sauvegardé");
      }
    },

    async associateSuiviBudgetModeleToOperation(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const currentModeleSelected = this.getExistingModeleSelected;

      if (!currentModeleSelected) {
        return;
      }

      const response =
        await suiviBudgetApi.associateSuiviBudgetModeleToOperation(
          idOperation,
          currentModeleSelected.id
        );

      if (response.status === 204) {
        await this.reloadSuiviBudgetList();
        toastSuccess("Modèle ajouté à l'opération");
      }
    },

    async fetchAvailableSuiviBudgetModeleList(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;
      const banque = useUserProfileStore().getUserBanqueGroup;
      const mostRecentImport = this.getMostRecentSuiviBudgetImport;

      if (!mostRecentImport) {
        return;
      }

      if (!banque) {
        toastError(`Seul un utilisateur banque peut effectuer cette requête`);
        return;
      }

      const response =
        await suiviBudgetApi.fetchSuiviBudgetModeleListWithCompletion(
          idOperation,
          mostRecentImport.id
        );

      if (response.data && response.status === 200) {
        this.SetAvailableSuiviBudgetModeleList(response.data);
      }
    },

    async downloadSuiviBudgetDocumentsPromoteur(
      idOperation: string
    ): Promise<void> {
      try {
        const response =
          await suiviBudgetApi.downloadSuiviBudgetDocumentsPromoteur(
            idOperation
          );

        if (response.status === 200) {
          const idZip = response.data;
          const documentStore = useDocumentStore();
          const operation = useOperationStore().getOperation;
          documentStore.documentDownloadZipSubscribed[idZip] = operation.name;
        } else if (response.status === 409) {
          toastWarn("Aucun document de suivi de budget");
        }
      } catch (_error) {}
    },
  },
});
