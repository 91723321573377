import { defineStore } from "pinia";
import { ModuleType } from "./moduleType.pinia";
import { SuiviTravaux } from "@/domain/suiviTravaux";
import suiviTravauxApi from "@/api/suiviTravauxApi";
import { SuiviTravauxStepCreationRequest } from "@domain/dto/suiviTravauxStepCreationRequest";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import { SuiviTravauxStep } from "@/domain/SuiviTravauxStep";
import { SuiviTravauxStep as SuiviTravauxStepEnum } from "@domain/enum/SuiviTravauxStep";
import { SuiviTravauxUpdateRequest } from "@domain/dto/suiviTravauxUpdateRequest";
import { SuiviTravauxImportConfiguration } from "@/domain/suiviTravauxImportConfiguration";
import { EtapeSuiviTravauxImport } from "@/domain/enum/etapeSuiviTravauxImport";
import { SuiviTravauxImportConfigurationEtape } from "@/domain/suiviTravauxImportConfigurationEtape";
import { EtapeImportConfigurationType } from "@/domain/enum/etapeImportConfigurationType";
import {
  convertInternalConfigurationToCreationRequest,
  convertConfigurationResponseToInternalConfiguration,
  isSuiviTravauxConfigurationReady,
  convertSuiviTravauxMatrixToCreationRequest,
  extractReferenceTrancheDuplicateErrors,
  buildInitialSuiviTravauxImportConfiguration,
} from "@/utils/suiviTravauxMassifUtils";
import {
  convertImportMatrixToPreviewData,
  getConfiguredImportHeaderList,
  getDataOnlyImportMatrix,
  UNCONFIGURED_COLUMN_INDEX,
} from "@/utils/importConfigurationUtils";
import {
  getSuiviTravauxStepByMappedKey,
  getSuiviTravauxStepLabelByMappedKey,
} from "@/utils/suiviTravauxUtils";
import uniq from "lodash/uniq";
import { SuiviReferenceTrancheDuplicateError } from "@domain/dto/suiviReferenceTrancheDuplicateError";
import { useSuiviTravauxMassifStore } from "./suiviTravauxMassifModule.pinia";
import { formatDate } from "@/utils/dateUtils";
import logger from "@/logger.ts";

type SuiviTravauxStateType = {
  suiviTravaux: SuiviTravaux[];
  previewMode: boolean;
  isConfigurationImported: boolean;
  rawDataMatrix: string[][];
  importConfigurationFile?: File;
  importConfiguration: SuiviTravauxImportConfiguration;
  importConfigurationSteps: SuiviTravauxImportConfigurationEtape[];
  correspondanceStep: Record<string, SuiviTravauxStepEnum>;
  referenceTrancheDuplicateErrors: SuiviReferenceTrancheDuplicateError[];
};

export const useSuiviTravauxStore = defineStore(ModuleType.SuiviTravaux, {
  state: (): SuiviTravauxStateType => ({
    suiviTravaux: [],
    previewMode: false,
    isConfigurationImported: false,
    rawDataMatrix: [],
    importConfiguration: buildInitialSuiviTravauxImportConfiguration(),
    importConfigurationSteps: [
      {
        number: 1,
        key: EtapeSuiviTravauxImport.CHOIX_PREMIERE_LIGNES,
        type: EtapeImportConfigurationType.HEADER,
        label: "En têtes de colonnes",
      },
      {
        number: 2,
        key: EtapeSuiviTravauxImport.CHOIX_REFERENCE_PROMOTEUR,
        type: EtapeImportConfigurationType.COLUMN,
        label: "Référence promoteur",
      },
      {
        number: 3,
        key: EtapeSuiviTravauxImport.CHOIX_NOM_PROGRAMME,
        type: EtapeImportConfigurationType.COLUMN,
        label: "Nom du programme",
      },
      {
        number: 4,
        key: EtapeSuiviTravauxImport.CHOIX_TRANCHE,
        type: EtapeImportConfigurationType.COLUMN,
        label: "Tranche",
      },
      {
        number: 5,
        key: EtapeSuiviTravauxImport.CHOIX_DERNIERE_ETAPE_AVANCEMENT,
        type: EtapeImportConfigurationType.COLUMN,
        label: "Dernière étape d'avancement des travaux",
      },
      {
        number: 6,
        key: EtapeSuiviTravauxImport.CHOIX_DATE_ACHEVEMENT,
        type: EtapeImportConfigurationType.COLUMN,
        label: "Date d'achèvement de l'étape",
        optional: true,
        defaultValue: formatDate(new Date(), "dd/MM/yy"),
      },
    ],
    correspondanceStep: {},
    referenceTrancheDuplicateErrors: [],
  }),
  getters: {
    getSuiviTravaux(state): SuiviTravaux[] {
      return state.suiviTravaux;
    },
    getPreviewMode(state): boolean {
      return state.previewMode;
    },
    getImportConfiguration(state): SuiviTravauxImportConfiguration {
      return state.importConfiguration;
    },
    getImportConfigurationSteps(state): SuiviTravauxImportConfigurationEtape[] {
      return state.importConfigurationSteps;
    },
    getRawDataMatrix(state): string[][] {
      return state.rawDataMatrix;
    },

    getDataOnlyMatrix(state): string[][] {
      const configuredHeaderList = getConfiguredImportHeaderList(
        state.importConfigurationSteps,
        state.importConfiguration
      );

      return state.rawDataMatrix.slice(Math.max(...configuredHeaderList) + 1);
    },

    getIsConfigurationImported(state): boolean {
      return state.isConfigurationImported;
    },

    getImportConfigurationColumnLabels(state): string[] {
      const configuration = this.importConfiguration;
      return state.importConfigurationSteps
        .filter((step) => {
          const isColumn = step.type === EtapeImportConfigurationType.COLUMN;
          const isConfigured =
            configuration[step.key] !== UNCONFIGURED_COLUMN_INDEX;
          return isColumn && isConfigured;
        })
        .map((step) => step.label);
    },

    getPreviewData(state): string[][] {
      const configuration = this.importConfiguration;
      const configurationSteps = this.importConfigurationSteps;

      if (
        !isSuiviTravauxConfigurationReady(configuration, configurationSteps)
      ) {
        return [];
      }

      const convertedMatrix = convertImportMatrixToPreviewData(
        state.rawDataMatrix,
        configurationSteps,
        configuration
      );

      return convertedMatrix.map((row) => {
        row[3] = getSuiviTravauxStepLabelByMappedKey(
          this.correspondanceStep,
          row[3].toLowerCase()
        );
        return row;
      });
    },

    getUnkownStepList(): string[] {
      const suiviTravauxStepList = this.getPreviewData.flatMap(
        (data) => data[3]
      );

      return uniq(
        suiviTravauxStepList
          .filter((step) => Boolean(step))
          .filter(
            (step) =>
              !getSuiviTravauxStepByMappedKey(this.correspondanceStep, step)
          )
      );
    },

    getIsStepNotRecognized(): boolean {
      return this.getUnkownStepList.length > 0;
    },

    getReferenceTrancheDuplicateErrors(
      state
    ): SuiviReferenceTrancheDuplicateError[] {
      return state.referenceTrancheDuplicateErrors;
    },
  },
  actions: {
    SetPreviewMode(previewMode: boolean): void {
      this.previewMode = previewMode;
    },
    SetIsConfigurationImported(isImported: boolean): void {
      this.isConfigurationImported = isImported;
    },
    SetImportConfigurationFile(file: File): void {
      this.importConfigurationFile = file;
    },
    SetCorrespondanceStep(
      correspondance: Record<string, SuiviTravauxStepEnum>
    ): void {
      this.correspondanceStep = correspondance;
    },
    SetReferenceTrancheDuplicateErrors(
      errors: SuiviReferenceTrancheDuplicateError[]
    ): void {
      this.referenceTrancheDuplicateErrors = errors;
    },
    InitializeImportMatrix(matrix: string[][]): void {
      this.rawDataMatrix = matrix;
    },
    ResetImportMatrix(): void {
      this.rawDataMatrix = [];
    },
    ResetImportConfiguration(): void {
      this.importConfiguration = buildInitialSuiviTravauxImportConfiguration();
    },
    ResetImportConfigurationStep(etape: EtapeSuiviTravauxImport): void {
      this.importConfiguration[etape] = UNCONFIGURED_COLUMN_INDEX;
    },
    ResetCorrespondanceStep(): void {
      this.correspondanceStep = {};
    },
    ResetReferenceTrancheDuplicateErrors(): void {
      this.referenceTrancheDuplicateErrors = [];
    },
    SetImportConfiguration(
      configuration: SuiviTravauxImportConfiguration
    ): void {
      this.importConfiguration = configuration;
    },
    SetEtapeImportConfiguration(
      etape: EtapeSuiviTravauxImport,
      value: number
    ): void {
      this.importConfiguration[etape] = value;
    },
    UpdateOrAddStep(step: SuiviTravauxStep): void {
      const suivi = this.suiviTravaux.find(
        (suivi) => suivi.id === step.idSuiviTravaux
      );
      if (suivi) {
        suivi.steps[step.step] = step;
      }
    },

    UpdateSuiviTravaux(updatedSuiviTravaux: SuiviTravaux): void {
      const index = this.suiviTravaux.findIndex(
        (s) => s.id === updatedSuiviTravaux.id
      );
      if (index >= 0) {
        this.suiviTravaux[index] = updatedSuiviTravaux;
      }
    },

    DeleteSuiviTravaux(idDeleted: string): void {
      const index = this.suiviTravaux.findIndex((s) => s.id == idDeleted);
      if (index >= 0) {
        this.suiviTravaux.splice(index, 1);
      }
    },

    async fetchSuiviTravaux(idOperation: string): Promise<void> {
      const res =
        await suiviTravauxApi.fetchSuiviTravauxByIdOperation(idOperation);
      this.suiviTravaux = res.data;
    },

    async createSuiviTravaux(
      idOperation: string,
      numberOfStepperToCreate: number
    ): Promise<void> {
      suiviTravauxApi
        .createSuiviTravaux(idOperation, {
          numberOfStepperToCreate,
        })
        .then(async (result) => {
          if (result.status === 201) {
            this.suiviTravaux.push(...result.data);
            toastSuccess("La création a été correctement effectuée");
          }
        })
        .catch(() => {
          toastError("Une erreur est survenue à la création");
        });
    },

    async createSuiviTravauxStep(
      idOperation: string,
      suiviTravauxStepCreationRequest: SuiviTravauxStepCreationRequest,
      attestationFinTravaux: File | undefined
    ): Promise<void> {
      suiviTravauxApi
        .createSuiviTravauxStep(idOperation, suiviTravauxStepCreationRequest)
        .then(async (result) => {
          if (result.status === 201) {
            toastSuccess("Étape créée avec succès");
            if (attestationFinTravaux) {
              const createdSuiviStep = result.data;
              await suiviTravauxApi.addAttestationSuiviTravauxStep(
                idOperation,
                createdSuiviStep.id,
                attestationFinTravaux
              );
            }
            if (suiviTravauxStepCreationRequest.idSuiviTravaux) {
              this.UpdateOrAddStep(result.data);
            } else {
              await this.fetchSuiviTravaux(idOperation);
            }
          }
        })
        .catch(() => {
          toastError(
            "Une erreur est survenue lors de la création d'étape de suivi."
          );
        });
    },

    async updateSuiviTravaux(
      idOperation: string,
      idSuiviTravaux: string,
      updateRequest: SuiviTravauxUpdateRequest
    ): Promise<void> {
      suiviTravauxApi
        .updateSuiviTravaux(idOperation, idSuiviTravaux, updateRequest)
        .then((result) => {
          if (result.status === 200) {
            toastSuccess("La modification a bien été prise en compte.");
            this.UpdateSuiviTravaux(result.data);
          }
        })
        .catch(() => {
          toastError("Une erreur est survenue à la modification");
        });
    },

    async deleteSuiviTravaux(
      idOperation: string,
      idSuiviTravaux: string
    ): Promise<void> {
      suiviTravauxApi
        .deleteSuiviTravaux(idOperation, idSuiviTravaux)
        .then((result) => {
          if (result.status === 200) {
            toastSuccess("La modification a bien été prise en compte.");
            this.DeleteSuiviTravaux(idSuiviTravaux);
          }
        })
        .catch(() => {
          toastError("Une erreur est survenue à la suppression.");
        });
    },

    async saveImportConfiguration(): Promise<void> {
      const configuration = this.importConfiguration;
      const configurationSteps = this.importConfigurationSteps;

      if (
        !isSuiviTravauxConfigurationReady(configuration, configurationSteps)
      ) {
        toastError("La configuration d'import n'est pas complète");
        return;
      }

      try {
        const request =
          convertInternalConfigurationToCreationRequest(configuration);
        const response = await suiviTravauxApi.saveImportConfiguration(request);

        if (response.status === 200) {
          toastSuccess("Votre configuration a été sauvegardée avec succès");
        } else {
          toastError("Échec lors de la sauvegarde de votre configuration");
        }
      } catch (error) {
        logger.error(error);
        toastError("Échec lors de la sauvegarde de votre configuration");
      }
    },

    async prepareConfigurationJSONData(): Promise<void> {
      const configuration = this.importConfiguration;
      const configurationSteps = this.importConfigurationSteps;

      if (
        !isSuiviTravauxConfigurationReady(configuration, configurationSteps) ||
        !this.importConfigurationFile
      ) {
        return toastError(
          "Échec lors de l'ajout des données du suivi des travaux"
        );
      }

      const dataOnlyMatrix = getDataOnlyImportMatrix(
        this.rawDataMatrix,
        configurationSteps,
        configuration
      );

      const matrixConversionResult = convertSuiviTravauxMatrixToCreationRequest(
        dataOnlyMatrix,
        configuration,
        this.correspondanceStep
      );

      if (matrixConversionResult.isErr()) {
        return matrixConversionResult
          .unwrapErr()
          .forEach((error) => toastError(error));
      }

      const [createRequestList, referenceTrancheDuplicateErrors] =
        extractReferenceTrancheDuplicateErrors(matrixConversionResult.unwrap());

      if (referenceTrancheDuplicateErrors.length > 0) {
        this.SetReferenceTrancheDuplicateErrors(
          referenceTrancheDuplicateErrors
        );
        toastError(
          `Erreur à l'import de ${referenceTrancheDuplicateErrors.length} références promoteur avec des tranches identiques.`
        );
      }

      if (createRequestList.length === 0) {
        return toastError("Aucune donnée n'a pu être chargée");
      }

      useSuiviTravauxMassifStore().SetCreateRequestList(createRequestList);
    },

    async findImportConfiguration(): Promise<void> {
      const response = await suiviTravauxApi.findImportConfiguration();
      if (response.status === 200) {
        const configuration =
          convertConfigurationResponseToInternalConfiguration(response.data);
        this.SetImportConfiguration(configuration);
        this.SetPreviewMode(true);
        this.SetIsConfigurationImported(true);
      }
    },
  },
});
