import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";
import config from "@/config";

import { Role } from "@domain/enum/role";
import { NotificationDto } from "@/domain/dto/notificationDto";
import { useNotificationStore } from "@/store/notificationModule.pinia";
import { LettreAccordSockitNamespace } from "./lettreAccordSockit";
import { DemandeSockitNamespace } from "./demandeSockit";
import { ChatSockitNamespace } from "./chatSockit";
import { SignatureSockitNamespace } from "./signatureSockit";
import { FileSockitNamespace } from "./fileSockit";
import { SureteSockitNamespace } from "@/api/sockit/sureteSockit";
import logger from "@/logger.ts";

export enum SockitEvent {
  SEND_MESSAGE = "SEND_MESSAGE",
  SEND_NOTIFICATION_MESSAGE = "SEND_NOTIFICATION_MESSAGE",
  JOIN_OPERATION_CHAT = "JOIN_OPERATION_CHAT",
  LEAVE_OPERATION_CHAT = "LEAVE_OPERATION_CHAT",
  JOIN_DOCUMENT_EDIT_SESSION = "JOIN_DOCUMENT_EDIT_SESSION",
  LEAVE_DOCUMENT_EDIT_SESSION = "LEAVE_DOCUMENT_EDIT_SESSION",
  JOIN_ROOM = "JOIN_ROOM",
  LEAVE_ROOM = "LEAVE_ROOM",
  MESSAGE_RESPONSE = "MESSAGE_RESPONSE",
  ERROR = "ERROR",
  USER_JOINED_EDIT_SESSION = "USER_JOINED_EDIT_SESSION",
  USER_LEFT_EDIT_SESSION = "USER_LEFT_EDIT_SESSION",
  GET_MEMBERS_DEMANDE_ROOM = "GET_MEMBERS_DEMANDE_ROOM",
  USER_JOINED_ROOM = "USER_JOINED_ROOM",
  USER_LEFT_ROOM = "USER_LEFT_ROOM",
  REFRESH_EDITED_DOCUMENT = "REFRESH_EDITED_DOCUMENT",
  ALLOW_DOCUMENT_EDITION = "ALLOW_DOCUMENT_EDITION",
  BLOCK_DOCUMENT_EDITION = "BLOCK_DOCUMENT_EDITION",
  DOCUMENT_EDITION_BLOCKED = "DOCUMENT_EDITION_BLOCKED",
  MODIFIED_DOCUMENT_EDIT_SESSION = "MODIFIED_DOCUMENT_EDIT_SESSION",
  RECIPIENT_SIGNED = "RECIPIENT_SIGNED",
  HANDLE_RECIPIENT_SIGNED = "HANDLE_RECIPIENT_SIGNED",

  CHANGED_FILE_STATUS = "CHANGED_FILE_STATUS",

  NOTIFICATION = "NOTIFICATION",

  // Global check version
  CHECK_VERSION = "CHECK_VERSION",
}

export const socketOptions: Partial<ManagerOptions & SocketOptions> = {
  path: "/socketio",
  transports: ["polling", "websocket"],
  reconnectionDelayMax: 3000,
};

export interface RoomMemberType {
  idUser: string;
  startEditingAt: number;
  userRole: Role;
}

export default class SockitSingleton {
  private static instance: SockitSingleton;

  private notificationSockit: Socket;
  private globalSockit: Socket;

  private readonly demandeNamespace: DemandeSockitNamespace;
  private readonly lettreAccordNamespace: LettreAccordSockitNamespace;
  private readonly sureteNamespace: SureteSockitNamespace;
  private readonly chatNamespace: ChatSockitNamespace;
  private readonly signatureNamespace: SignatureSockitNamespace;
  private readonly fileNamespace: FileSockitNamespace;

  get demande(): DemandeSockitNamespace {
    return this.demandeNamespace;
  }

  get lettreAccord(): LettreAccordSockitNamespace {
    return this.lettreAccordNamespace;
  }

  get surete(): SureteSockitNamespace {
    return this.sureteNamespace;
  }

  get chat(): ChatSockitNamespace {
    return this.chatNamespace;
  }

  get signature(): SignatureSockitNamespace {
    return this.signatureNamespace;
  }

  get file(): FileSockitNamespace {
    return this.fileNamespace;
  }

  private constructor(token: string) {
    this.lettreAccordNamespace = LettreAccordSockitNamespace.getInstance(token);
    this.demandeNamespace = DemandeSockitNamespace.getInstance(token);
    this.sureteNamespace = SureteSockitNamespace.getInstance(token);
    this.chatNamespace = ChatSockitNamespace.getInstance(token);
    this.signatureNamespace = SignatureSockitNamespace.getInstance(token);
    this.fileNamespace = FileSockitNamespace.getInstance(token);

    this.notificationSockit = io(`${config.ROOT_URL}/notification`, {
      ...socketOptions,
      auth: { token },
    });
    this.globalSockit = io(`${config.ROOT_URL}`, {
      ...socketOptions,
      auth: { token },
    });

    //----------- Notification namespace --------------
    this.notificationSockit.on(
      SockitEvent.NOTIFICATION,
      async (payload: NotificationDto[]) => {
        await useNotificationStore().registerNotifications(payload);
      }
    );
    //----------- global --------------
    this.globalSockit.on(SockitEvent.CHECK_VERSION, async (version: string) => {
      logger.warn(`restarted back version: ${version}`);
      logger.warn(`current version ${config.VERSION}`);
      if (config.VERSION !== version) {
        document.location.reload();
      }
    });
  }

  public static getInstance(token: string): SockitSingleton {
    if (!SockitSingleton.instance) {
      SockitSingleton.instance = new SockitSingleton(token);
    }
    return SockitSingleton.instance;
  }
}
