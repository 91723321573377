<template>
  <div>
    <div :class="{ superuser: isSuperUser }" class="edit-div">
      <div class="edit-profile-image">
        <input
          accept="image/x-png, image/gif, image/jpeg"
          type="file"
          @change="updateProfileImage"
        />
        <img alt="éditer" class="edit-icon" src="@/assets/edit.svg" />
        <div class="edit-text">Remplacer mon image</div>
      </div>
      <div class="delete-profile-image" @click="deleteProfileImage">
        <img
          alt="supprimer"
          class="delete-icon"
          src="@/assets/cross_orange.svg"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Compressor from "compressorjs";
import { Role } from "@domain/enum/role";
import { hasRole } from "@/utils/groupUtils";

import { getSetupContext } from "@/utils/vueUtils";

import { computed, defineComponent } from "vue";
import logger from "@/logger.ts";
export default defineComponent({
  props: {
    idProfileImage: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { keycloakStore, userProfileStore } = getSetupContext();

    const isSuperUser = computed((): boolean => {
      return hasRole(userProfileStore.getUserProfile.roles, Role.SUPER_USER);
    });

    async function updateProfileImage(e: Event): Promise<void> {
      if (!e.target) {
        return;
      }
      const element = e.target as HTMLInputElement;

      if (element.files && element.files.length === 1) {
        const file = element.files[0];
        new Compressor(file, {
          quality: 0.6,
          width: 500,
          success(result) {
            userProfileStore.updateUserProfileImage({
              file: result,
            });
          },
          error(err) {
            logger.error(err.message);
          },
        });
      }
    }

    async function deleteProfileImage(): Promise<void> {
      const idUser = userProfileStore.getUserProfile.id;
      await userProfileStore.deleteUserProfileImage(props.idProfileImage);
      keycloakStore.setIdProfileImage("");
      await userProfileStore.fetchUserProfile(idUser);
    }

    return {
      // Computed
      isSuperUser,

      // Methods
      updateProfileImage,
      deleteProfileImage,
    };
  },
});
</script>

<style lang="scss" scoped>
@use "@/sass/variables.scss" as *;
@use "@/sass/media.scss" as *;

.edit-div {
  width: 144px;
  height: 144px;
  opacity: 0;
  margin: 8px;
  position: absolute;
  z-index: 2;
  border-radius: 50%;
  line-height: 12px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 30px;

  &:hover {
    opacity: 0.9;
    border: 2px solid black;

    .edit-profile-image {
      font-size: 12px;
      display: flex;
      align-content: center;
      justify-content: center;
      cursor: pointer;

      .edit-icon {
        margin-right: 6px;
      }
      .edit-text {
        text-align: left;
        width: 80px;
      }
    }

    .delete-profile-image {
      font-size: 12px;
      cursor: pointer;
    }

    &.superuser {
      border: 4px solid $primary-main;

      .profile-image {
        width: 136px;
        height: 136px;
      }
    }
  }
}

input[type="file"] {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 60%;
  cursor: pointer;
  color: transparent;
}
</style>
