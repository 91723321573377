import documentApi from "@/api/documentApi";
import sureteApi from "@/api/sureteApi";
import { ApiResponse } from "@/apiRequest";
import { CreationDocument } from "@/domain/creationDocument";
import { DocumentStatus } from "@domain/enum/documentStatus";
import { GedType } from "@domain/enum/gedType";
import { OperationDocumentCategory } from "@domain/enum/operationDocumentCategory";
import { GedDocument } from "@/domain/gedDocument";
import { OperationDocument } from "@domain/dto/operationDocument";
import { OperationDocumentWithPreviousVersions } from "@domain/dto/operationDocumentWithPreviousVersions";
import { SocieteSupportDocument } from "@domain/dto/societeSupportDocument";
import { SocieteSupportDocumentWithPreviousVersions } from "@/domain/societeSupportDocumentWithPreviousVersions";
import { UploadingDocument } from "@/domain/uploadingDocument";
import { useDemandeStore } from "@/store/demandeModule.pinia";
import { ModuleType } from "@/store/moduleType.pinia";
import { useOperationStore } from "@/store/operationModule.pinia";
import { useSocieteSupportStore } from "@/store/societeSupportModule.pinia";
import { getOperationDocumentListLatestVersion } from "@domain/utils/documentUtils";

import {
  createObjectUrl,
  downloadClickLink,
  setupDocumentPreview,
} from "@/utils/downloadFileUtils";
import { GED_TYPE_NOT_ACCEPTED_MESSAGE } from "@/utils/errorMessage";
import { FilterItem } from "@/utils/model/filterItem";
import { getSocieteSupportDocumentListLatestVersion } from "@/utils/societeSupportDocumentUtils";
import { toastError, toastSuccess } from "@/utils/toastUtils";
import {
  computeExceedFileBatchSizeError,
  isFileBatchUploadable,
} from "@/utils/uploadFileUtils";
import findIndex from "lodash/findIndex";
import uniq from "lodash/uniq";
import { defineStore } from "pinia";
import { PendingDocument } from "@domain/dto/pendingDocument";
import { DocumentUpdateRequest } from "@domain/dto/documentUpdateRequest";
import logger from "@/logger.ts";

type DocumentStateType = {
  documentUploadingList: UploadingDocument[];
  operationDocumentList: OperationDocument[];
  societeSupportDocumentList: SocieteSupportDocument[];
  gedType: GedType;
  categoryFilters: FilterItem[];
  contributorFilters: FilterItem[];
  previewDocument: string;
  isShowPreviewDocument: boolean;
  checkedDocuments: string[];
  activeGedBanque: string | undefined;
  editingDocumentAttachedCmp: OperationDocument | undefined;
  pendingDocumentUploadList: PendingDocument[];
  documentUploadProgress: number;
  totalDocumentsToUpload: number;
  totalDocumentsUploaded: number;
  totalProcessedDocumentsToUpload: number;
  latestDocumentComplementaireList: OperationDocument[];
  /**
   * Chaque ligne correspond à l'id du document à télécharger avec le nom de l'opération attaché
   */
  documentDownloadZipSubscribed: Record<string, string>;
};
export const useDocumentStore = defineStore(ModuleType.Document, {
  state: (): DocumentStateType => ({
    documentUploadingList: [],
    operationDocumentList: [],
    societeSupportDocumentList: [],
    gedType: GedType.OPERATION,
    categoryFilters: [],
    contributorFilters: [],
    previewDocument: "",
    isShowPreviewDocument: false,
    checkedDocuments: [],
    activeGedBanque: undefined,
    editingDocumentAttachedCmp: undefined,
    pendingDocumentUploadList: [],
    documentUploadProgress: 0,
    documentDownloadZipSubscribed: {},
    totalDocumentsToUpload: 0,
    totalDocumentsUploaded: 0,
    totalProcessedDocumentsToUpload: 0,
    latestDocumentComplementaireList: [],
  }),
  getters: {
    isOperationGed(state): boolean {
      return state.gedType === GedType.OPERATION;
    },

    isMultipleDocumentUpload(state): boolean {
      return state.totalDocumentsToUpload > 1;
    },

    isSingleDocumentUpload(state): boolean {
      return state.totalDocumentsToUpload <= 1;
    },

    getActiveGedBanque(state): string | undefined {
      return state.activeGedBanque;
    },

    isSocieteSupportGed(state): boolean {
      return state.gedType === GedType.SOCIETE_SUPPORT;
    },

    getEditingDocumentAttachedCmp(state): OperationDocument | undefined {
      return state.editingDocumentAttachedCmp;
    },

    getDocumentsLatestVersion(
      state
    ):
      | OperationDocumentWithPreviousVersions[]
      | SocieteSupportDocumentWithPreviousVersions[] {
      if (this.isOperationGed) {
        return getOperationDocumentListLatestVersion(
          state.operationDocumentList
        );
      } else if (this.isSocieteSupportGed) {
        return getSocieteSupportDocumentListLatestVersion(
          state.societeSupportDocumentList
        );
      }
      return [];
    },

    getCategoryFilters(
      state
    ): { value: string; label: string; isActive: boolean }[] {
      return state.categoryFilters;
    },

    getContributorFilters(
      state
    ): { value: string; label: string; isActive: boolean }[] {
      return state.contributorFilters;
    },

    getPreviewDocument(state): string {
      return state.previewDocument;
    },

    getIsShowPreviewDocument(state): boolean {
      return state.isShowPreviewDocument;
    },

    getCheckedDocuments(state): string[] {
      return state.checkedDocuments;
    },
    getProcessedDocumentCount(state): number {
      return state.totalProcessedDocumentsToUpload;
    },
    getPendingDocumentUploadList(state): PendingDocument[] {
      return state.pendingDocumentUploadList;
    },
    getTotalDocumentsToUpload(state): number {
      return state.totalDocumentsToUpload;
    },
    getTotalDocumentsUploaded(state): number {
      return state.totalDocumentsUploaded;
    },
    getLatestDocumentComplementaireList(state): OperationDocument[] {
      return state.latestDocumentComplementaireList;
    },
  },
  actions: {
    SetPreviewDocument(previewDocument: string): void {
      this.previewDocument = previewDocument;
    },

    SetActiveBanqueGed(gedBanque?: string): void {
      this.activeGedBanque = gedBanque;
    },

    ResetActiveBanqueGed(): void {
      this.activeGedBanque = undefined;
    },

    SetIsShowPreviewDocument(isShowPreviewDocument: boolean): void {
      this.isShowPreviewDocument = isShowPreviewDocument;
    },

    SetOperationDocuments(updatedDocumentsList: OperationDocument[]): void {
      this.operationDocumentList = updatedDocumentsList.map((document) => {
        if (
          document.category === OperationDocumentCategory.ACHEVEMENT_MAINLEVEE
        ) {
          document.category = OperationDocumentCategory.ACHEVEMENT;
        }
        return document;
      });
    },

    SetSocieteSupportDocuments(
      updatedDocumentsList: SocieteSupportDocument[]
    ): void {
      this.societeSupportDocumentList = updatedDocumentsList;
    },

    ResetDocuments(): void {
      if (this.gedType === GedType.OPERATION) {
        this.operationDocumentList = [];
      } else if (this.gedType === GedType.SOCIETE_SUPPORT) {
        this.societeSupportDocumentList = [];
      }
    },

    SetCategoryFilter(categoryFilter: {
      index: number;
      checked: boolean;
    }): void {
      this.categoryFilters[categoryFilter.index].isActive =
        categoryFilter.checked;
    },

    SetContributorFilter(contributorFilter: {
      index: number;
      checked: boolean;
    }): void {
      this.contributorFilters[contributorFilter.index].isActive =
        contributorFilter.checked;
    },

    InitCategoryFilters(document: GedDocument[]): void {
      this.categoryFilters = uniq(
        document.map((doc: GedDocument) => doc.category)
      ).map((category) => {
        return {
          value: category,
          label: category,
          isActive: false,
        };
      });
    },

    InitContributorFilters(document: GedDocument[]): void {
      this.contributorFilters = uniq(
        document.map(
          (doc) => `${doc.firstNameUploader} ${doc.lastNameUploader}`
        )
      ).map((contributorName) => {
        return {
          value: contributorName,
          label: contributorName,
          isActive: false,
        };
      });
    },

    SetGedType(gedType: GedType): void {
      this.gedType = gedType;
    },

    DeleteDocument(idDocument: string): void {
      if (this.gedType === GedType.OPERATION) {
        const index = findIndex(this.operationDocumentList, { id: idDocument });
        this.operationDocumentList.splice(index, 1);
      } else if (this.gedType === GedType.SOCIETE_SUPPORT) {
        const index = findIndex(this.societeSupportDocumentList, {
          id: idDocument,
        });
        this.societeSupportDocumentList.splice(index, 1);
      }
    },

    ResetCheckedDocuments(): void {
      this.checkedDocuments = [];
    },

    AddCheckedDocument(id: string): void {
      this.checkedDocuments.push(id);
    },

    RemoveCheckedDocument(id: string): void {
      while (this.checkedDocuments.includes(id)) {
        const index = this.checkedDocuments.indexOf(id);
        if (index >= 0) this.checkedDocuments.splice(index, 1);
      }
    },

    SetEditingDocumentAttachedCmp(document: OperationDocument): void {
      this.editingDocumentAttachedCmp = document;
    },

    MarkDocumentAsRead(idDocument: string): void {
      const document = this.operationDocumentList.find(
        (doc) => doc.id === idDocument
      );
      if (document) document.isDownloaded = true;
    },
    SetPendingDocumentUploadList(
      pendingDocumentUploadList: PendingDocument[]
    ): void {
      this.pendingDocumentUploadList = pendingDocumentUploadList;
    },
    ResetPendingDocumentUploadList(): void {
      this.pendingDocumentUploadList = [];
    },
    StartMultipleDocumentsUpload(count: number): void {
      this.totalDocumentsToUpload = count;
      this.totalProcessedDocumentsToUpload = 0;
      this.totalDocumentsUploaded = 0;
    },
    IncrementProcessedDocumentsToUpload(): void {
      this.totalProcessedDocumentsToUpload++;
    },
    IncrementTotalDocumentsUploaded(): void {
      this.totalDocumentsUploaded++;
    },
    FindOperationDocumentByIdDocument(
      idDocument: string
    ): OperationDocumentWithPreviousVersions | undefined {
      return getOperationDocumentListLatestVersion(
        this.operationDocumentList
      ).find((doc) => doc.idDocument === idDocument);
    },
    FindSocieteSupportDocumentByIdDocument(
      idDocument: string
    ): SocieteSupportDocumentWithPreviousVersions | undefined {
      return getSocieteSupportDocumentListLatestVersion(
        this.societeSupportDocumentList
      ).find((doc) => doc.idDocument === idDocument);
    },
    async uploadFiles(
      documentsToPost: CreationDocument[],
      { isSuiviComDetailsLots }: { isSuiviComDetailsLots?: boolean } = {}
    ): Promise<ApiResponse<PendingDocument[]> | void> {
      if (!isFileBatchUploadable(documentsToPost)) {
        toastError(computeExceedFileBatchSizeError(documentsToPost));
        return Promise.reject();
      }

      this.StartMultipleDocumentsUpload(documentsToPost.length);

      const actionCallback = async (
        response: ApiResponse<PendingDocument[]>
      ) => {
        if (response.status !== 201) {
          toastError("Erreur lors de l'ajout de document(s)");
        } else {
          this.SetPendingDocumentUploadList(response.data);
          return response;
        }
      };
      if (this.isOperationGed) {
        return this.uploadFilesOperation(documentsToPost, isSuiviComDetailsLots)
          .then(actionCallback)
          .catch((e) => {
            logger.error(e);
            toastError("Erreur lors de l'ajout de document(s)");
          });
      } else if (this.isSocieteSupportGed) {
        return this.uploadFilesSocieteSupport(documentsToPost)
          .then(actionCallback)
          .catch(() => {
            toastError("Erreur lors de l'ajout de document(s)");
          });
      } else {
        throw new Error(GED_TYPE_NOT_ACCEPTED_MESSAGE);
      }
    },

    async fetchAllDocuments(): Promise<void> {
      this.ResetDocuments();
      try {
        let response;
        if (this.isOperationGed) {
          const idGedEntity = useOperationStore().getCurrentOperationId;
          response = await documentApi.operationFetchAllDocuments(idGedEntity);
          this.SetOperationDocuments(response.data);
        } else if (this.isSocieteSupportGed) {
          const idGedEntity = useSocieteSupportStore().getSocieteSupport.id;
          response =
            await documentApi.societeSupportFetchAllDocuments(idGedEntity);
          this.SetSocieteSupportDocuments(response.data);
        } else {
          throw new Error(GED_TYPE_NOT_ACCEPTED_MESSAGE);
        }
        this.InitCategoryFilters(response.data);
        this.InitContributorFilters(response.data);
      } catch (error) {
        this.ResetDocuments();
      }
    },

    async editDocument(editedDocument: DocumentUpdateRequest): Promise<void> {
      editedDocument = {
        ...editedDocument,
        name: `${editedDocument.name}.${editedDocument.extension}`,
      };
      try {
        if (this.isOperationGed) {
          const idGedEntity = useOperationStore().getCurrentOperationId;
          await documentApi.operationEditDocument(idGedEntity, editedDocument);
        } else if (this.isSocieteSupportGed) {
          const idGedEntity = useSocieteSupportStore().getSocieteSupport.id;
          await documentApi.societeSupportEditDocument(
            idGedEntity,
            editedDocument
          );
        }
        toastSuccess("Document édité avec succès");
        await this.fetchAllDocuments();
      } catch (error) {
        toastError("Erreur lors de l'édition du document");
      }
    },

    async deleteDocument(idDocument: string): Promise<void> {
      try {
        if (this.isOperationGed) {
          const idGedEntity = useOperationStore().getCurrentOperationId;
          await documentApi.operationDeleteDocument(idGedEntity, idDocument);
        } else if (this.isSocieteSupportGed) {
          const idGedEntity = useSocieteSupportStore().getSocieteSupport.id;
          await documentApi.societeSupportDeleteDocument(
            idGedEntity,
            idDocument
          );
        } else {
          throw new Error(GED_TYPE_NOT_ACCEPTED_MESSAGE);
        }
        this.DeleteDocument(idDocument);
        toastSuccess("Document supprimé avec succès");
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors de la suppression du document");
      }
    },

    async gedDownloadDocument(payload: {
      idDocument: string;
      name: string;
      type?: string;
    }): Promise<void> {
      let response;
      try {
        if (this.isOperationGed) {
          const idGedEntity = useOperationStore().getCurrentOperationId;
          response = await documentApi.downloadDocument(
            idGedEntity,
            payload.idDocument
          );
        } else if (this.isSocieteSupportGed) {
          const idGedEntity = useSocieteSupportStore().getSocieteSupport.id;
          response = await documentApi.downloadSocieteSupportDocument(
            idGedEntity,
            payload.idDocument
          );
        } else {
          throw new Error(GED_TYPE_NOT_ACCEPTED_MESSAGE);
        }
        const documentStream: BlobPart = response.data;
        downloadClickLink(documentStream, {
          name: payload.name,
          type: payload.type,
        });
        if (this.isOperationGed) {
          this.MarkDocumentAsRead(payload.idDocument);
        }
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement du document.");
      }
    },

    async exposeSureteDocument(payload: {
      idSocieteSupport: string;
      idSurete: string;
      idDocument: string;
    }): Promise<void> {
      let response;
      try {
        response = await sureteApi.downloadDocument(
          payload.idSocieteSupport,
          payload.idSurete,
          payload.idDocument
        );

        setupDocumentPreview(response.data);
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement du document.");
      }
    },

    async getPreviewOperationDocumentUrl(
      idOperation: string,
      idOperationDocument: string,
      type?: string
    ): Promise<string> {
      const response = await documentApi.downloadDocument(
        idOperation,
        idOperationDocument
      );
      return createObjectUrl(response.data, type);
    },

    async previewOperationDocument(payload: {
      idOperation: string;
      idDocument: string;
    }): Promise<void> {
      try {
        const response = await documentApi.downloadDocument(
          payload.idOperation,
          payload.idDocument
        );
        setupDocumentPreview(response.data);
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement du document.");
      }
    },

    async exposeSocieteSupportDocument(payload: {
      idSocieteSupport: string;
      idDocument: string;
    }): Promise<void> {
      try {
        const response = await documentApi.downloadSocieteSupportDocument(
          payload.idSocieteSupport,
          payload.idDocument
        );
        setupDocumentPreview(response.data);
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement du document.");
      }
    },

    async uploadFilesDemande(payload: {
      documentsToPost: CreationDocument[];
      documentStatus: DocumentStatus;
      idDemande: string;
    }): Promise<void> {
      const idDemande = payload.idDemande;

      if (!isFileBatchUploadable(payload.documentsToPost)) {
        toastError(computeExceedFileBatchSizeError(payload.documentsToPost));
        return Promise.reject();
      }

      this.StartMultipleDocumentsUpload(payload.documentsToPost.length);

      const demandeStore = useDemandeStore();
      const idOperation = useOperationStore().getOperation.id;

      if (idOperation) {
        const result = await documentApi.demandeUploadDocuments(
          idOperation,
          idDemande,
          payload.documentsToPost,
          payload.documentStatus
        );
        await demandeStore.fetchDemande({
          idOperation,
          idDemande,
        });

        if (result?.status === 201) {
          await demandeStore.fetchDemandeAchevementMainlevee({
            idOperation: idOperation,
            idDemande: idDemande,
          });
        } else if (result.status === 400) {
          // TODO: gérer logique d'erreur côté back
          // multer renvoi une erreur batch pour tous les documents qui ne passent pas la validation
          // ce qui nous empêche de savoir combien de documents ont échoué
          const stillProcessingDocumentsCount =
            this.totalDocumentsToUpload - this.getProcessedDocumentCount;

          for (let i = 0; i < stillProcessingDocumentsCount; i++) {
            this.IncrementProcessedDocumentsToUpload();
          }

          if (
            result.data.code === 415 &&
            result.data.message === "file is not docx or pdf"
          ) {
            toastError("Le document téléversé doit être de type .docx ou .pdf");
          } else if (
            result.data.code === 415 &&
            result.data.message === "filename exceeds 87 characters"
          ) {
            toastError(
              "Le nom des documents ne doit pas dépasser 87 caractères"
            );
          } else {
            toastError("Erreur lors du téléversement du document");
          }
        }
      }
    },

    UpdateDocumentUploadProgress(progress: number): void {
      this.documentUploadProgress = progress;
    },

    async uploadFilesOperation(
      documentsToPost: CreationDocument[],
      isSuiviComDetailsLots?: boolean
    ): Promise<ApiResponse<PendingDocument[]>> {
      const idOperation = useOperationStore().getOperation.id;
      const gedBanqueId = this.activeGedBanque;

      const result = await documentApi.operationUploadDocuments(
        idOperation,
        documentsToPost,
        gedBanqueId,
        this.UpdateDocumentUploadProgress,
        isSuiviComDetailsLots
      );

      this.documentUploadProgress = 0;
      return result;
    },

    async importDocumentListToOtherGed(
      documentsToUpdate: OperationDocument[]
    ): Promise<void> {
      const idOperation = useOperationStore().getOperation.id || "";
      const idBanque = this.activeGedBanque ?? null;

      const result = await documentApi.importDocumentListToOtherGed(
        idOperation,
        documentsToUpdate,
        idBanque
      );
      if (result.status === 200) {
        toastSuccess("Document(s) ajouté(s)");
        await this.fetchAllDocuments();
      }
    },

    async downloadAllOperationDocuments(
      newDocuments: string[] | undefined,
      shouldDownloadWithCategorizedDirectories: boolean
    ): Promise<void> {
      const operation = useOperationStore().getOperation;
      const documents: string[] = newDocuments ?? this.getCheckedDocuments;
      try {
        const document = await documentApi.downloadAllOperationDocumentInZip(
          operation,
          documents,
          shouldDownloadWithCategorizedDirectories
        );
        this.documentDownloadZipSubscribed[document.id] = operation.name;

        documents.forEach((document) => {
          this.MarkDocumentAsRead(document);
        });
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement des documents.");
      }
    },

    async downloadOperationArchiveZip(operation: {
      id: string;
      name: string;
    }): Promise<void> {
      try {
        const response = await documentApi.downloadOperationArchive(
          operation.id
        );

        if (response.status === 200) {
          const document = response.data;
          this.documentDownloadZipSubscribed[document.id] = operation.name;
        } else {
          toastError("Erreur lors du téléchargement de l'archive");
        }
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement de l'archive");
      }
    },

    async downloadDocument(payload: {
      idDocument: string;
      idOperation: string;
      name: string;
      type?: string;
    }): Promise<void> {
      await documentApi
        .downloadDocument(payload.idOperation, payload.idDocument)
        .then((response) => {
          const documentStream: BlobPart = response.data;
          downloadClickLink(documentStream, {
            name: payload.name,
            type: payload.type,
          });
        })
        .catch(() => {
          toastError("Erreur lors du téléchargement du document.");
        });
    },

    async showPreviewDocument(payload: {
      idDocument: string;
      idOperation: string;
      name: string;
    }): Promise<void> {
      try {
        const response = await documentApi.downloadDocument(
          payload.idOperation,
          payload.idDocument
        );

        setupDocumentPreview(response.data);
      } catch (error) {
        logger.error(error);
        toastError("Erreur lors du téléchargement du document.");
      }
    },

    //------------------------------------ SocieteSupport Services ------------------------------------------------------
    async uploadFilesSocieteSupport(
      documentsToPost: CreationDocument[]
    ): Promise<ApiResponse<GedDocument[]>> {
      const idSocieteSupport = useSocieteSupportStore().getSocieteSupport.id;
      return documentApi.societeSupportUploadDocuments(
        idSocieteSupport,
        documentsToPost
      );
    },

    async fetchLatestDocumentSuiviComplementaireList(): Promise<void> {
      const idOperation = useOperationStore().getCurrentOperationId;

      if (!idOperation) {
        return Promise.resolve();
      }
      try {
        const response =
          await documentApi.fetchLatestDocumentSuiviComplementaireList(
            idOperation
          );
        if (response.status === 200) {
          this.latestDocumentComplementaireList = response.data;
        }
      } catch (_error) {
        this.latestDocumentComplementaireList = [];
      }
    },
  },
});
