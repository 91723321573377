/*
 * Si ajout de feature toggle, vérifiez à l'ajouter dans sanitizeUtils.featureToggleRoleList
 * */

export enum Role {
  ADMIN = "admin",

  BANQUE = "banque",
  PROMOTEUR = "promoteur",
  POOL = "pool",
  TIERS = "tiers",
  HOMME_DE_ART = "homme-de-art",

  SIGNATAIRE = "signataire",
  SUPER_USER = "super-user",

  CREATE_CUSTOM_USERNAME = "create-custom-username",
  GROUP_ADMIN = "group-admin",

  DOCUMENT_EDITING_VISIBILITY = "document-editing-visibility",
  MESSAGE_ABSENCE_VISIBILITY = "message-absence-visibility",
  LOGOUT_VISIBILITY = "logout-visibility",
  ACCORD_COMMERCIAL_VISIBILITY = "accord-commercial-visibility",
  HOMME_DE_ART_VISIBILITY = "homme-de-art-visibility",
  DEMANDE_UNILATERALE_VISIBILITY = "demande-unilaterale-visibility",
  SUIVI_TRAVAUX_VISIBILITY = "suivi-travaux-visibility",
  SUIVI_TRAVAUX_MASSIF_VISIBILITY = "suivi-travaux-massif-visibility",
  OPERATION_ARCHIVE_VISIBILITY = "operation-archive-visibility",
  SUIVI_BUDGET_VISIBILITY = "suivi-budget-visibility",
  ESG_VISIBILITY = "esg-visibility",

  SUIVI_ENCOURS_GLOBAL_VISIBILITY = "suivi-encours-global-visibility",

  EASY_CII = "easy-cii",
  CREATION_EASY_CII_VISIBILITY = "creation-easy-cii-visibility",

  FACTURATION_ACCESS = "facturation-access",

  AUTRE = "autre",
  NOTAIRE = "notaire",
  OFFBOARDING = "offboarding",

  CONTRIBUTEUR = "contributeur",
}
