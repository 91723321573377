<template>
  <div v-if="isAuthenticated" id="app">
    <ConditionGeneraleUtilisationModal
      v-if="showConditionGeneraleUtilisation"
    />
    <div v-else id="page">
      <MaintenanceMessageListBanner />
      <div id="nav">
        <SupportedBrowserInformationHeadband />
        <TheNavbar />
        <TheReleaseNote />
      </div>
      <div id="main" :class="mainPushClass">
        <router-view />
      </div>
      <ToastList />
      <TheModals />
      <TheFooter />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import TheNavbar from "@/components/TheNavbar.vue";
import TheFooter from "@/components/TheFooter.vue";
import ConditionGeneraleUtilisationModal from "@/components/conditionGeneraleUtilisation/ConditionGeneraleUtilisationModal.vue";
import SupportedBrowserInformationHeadband from "@/components/SupportedBrowserInformationHeadband.vue";
import MaintenanceMessageListBanner from "./components/MaintenanceMessageListBanner.vue";
import TheModals from "@/components/TheModals.vue";
import ToastList from "@/components/toast/ToastList.vue";
import TheReleaseNote from "@/components/TheReleaseNote.vue";

import { getSetupContext } from "@/utils/vueUtils";

const { keycloakStore, supportedBrowserInformationStore } = getSetupContext();

const showConditionGeneraleUtilisation = computed<boolean>(() => {
  return (
    keycloakStore.profileAvailable &&
    !keycloakStore.hasReadLatestConditionGeneraleUtilisation
  );
});

const isAuthenticated = computed<boolean>(() => {
  return keycloakStore.isAuthenticated;
});

const mainPushClass = computed<string>(() => {
  return supportedBrowserInformationStore.getIsWarningIE
    ? "main-push-headband"
    : "main-push";
});
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#main {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f9fafb;
  color: #1f1e21;
  min-width: 828px;
  padding-bottom: 46px;
}

.main-push-headband {
  margin-top: 152px;
}

#nav {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
}

#page {
  position: relative;
  min-height: 100vh;
}
</style>
