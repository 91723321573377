import { EtapeSuiviTravauxImport } from "@/domain/enum/etapeSuiviTravauxImport";
import { SuiviTravauxImportConfiguration } from "@/domain/suiviTravauxImportConfiguration";
import { SuiviTravauxMassifStep } from "@domain/dto/suiviTravauxMassif";
import { SuiviTravauxConfiguration } from "@domain/dto/suiviTravauxConfiguration";
import { SuiviTravauxMassifCreateRequest } from "@domain/dto/suiviTravauxMassifCreateRequest";
import { getSuiviTravauxStepByMappedKey } from "./suiviTravauxUtils";
import { SuiviTravauxStep } from "@domain/enum/SuiviTravauxStep";
import { SuiviReferenceTrancheDuplicateError } from "@domain/dto/suiviReferenceTrancheDuplicateError";
import groupBy from "lodash/groupBy";
import {
  buildInitialImportConfiguration,
  getImportConfigurationErrors,
  UNCONFIGURED_COLUMN_INDEX,
} from "./importConfigurationUtils";
import { enumVariants } from "@domain/utils/enumUtils";
import { Err, Ok, Result } from "@hqoss/monads";
import { SuiviTravauxImportConfigurationEtape } from "@/domain/suiviTravauxImportConfigurationEtape";
import { parseDate } from "@/utils/dateUtils.ts";

export function getSuiviTravauxLabelByStep(
  suiviTravauxStep: SuiviTravauxMassifStep
): string {
  switch (suiviTravauxStep) {
    case SuiviTravauxMassifStep.AUCUN:
      return "-";
    case SuiviTravauxMassifStep.DEMOLITION:
      return "Démolition";
    case SuiviTravauxMassifStep.TERRASSEMENT:
      return "Terrassement";
    case SuiviTravauxMassifStep.FONDATIONS:
      return "Fondations";
    case SuiviTravauxMassifStep.DALLE:
      return "Dalle";
    case SuiviTravauxMassifStep.RDC:
      return "RDC";
    case SuiviTravauxMassifStep.GROS_OEUVRE_INF_50_POURCENT:
      return "Gros Œuvre < 50%";
    case SuiviTravauxMassifStep.GROS_OEUVRE_SUP_50_POURCENT:
      return "Gros Œuvre > 50%";
    case SuiviTravauxMassifStep.HORS_EAU:
      return "Hors d'eau";
    case SuiviTravauxMassifStep.HORS_AIR:
      return "Hors d'air";
    case SuiviTravauxMassifStep.CLOISONS:
      return "Cloisons";
    case SuiviTravauxMassifStep.REVETEMENT_DE_SOLS:
      return "Revêtement de sols";
    case SuiviTravauxMassifStep.FINITIONS:
      return "Finitions";
    case SuiviTravauxMassifStep.ACHEVEMENT:
      return "Achèvement";
    case SuiviTravauxMassifStep.LIVRAISON:
      return "Livraison";
    case SuiviTravauxMassifStep.ARRET_DE_CHANTIER:
      return "Arrêt de chantier";
    case SuiviTravauxMassifStep.INCONNU:
      return "Ignoré";
  }
}

export function buildInitialSuiviTravauxImportConfiguration() {
  const stepKeys = enumVariants(EtapeSuiviTravauxImport);
  return buildInitialImportConfiguration(stepKeys);
}

export function isSuiviTravauxConfigurationReady(
  configuration: SuiviTravauxImportConfiguration,
  configurationSteps: SuiviTravauxImportConfigurationEtape[]
): boolean {
  const errors = getImportConfigurationErrors(
    configuration,
    configurationSteps
  );
  return errors.length === 0;
}

function getColumnIndexIfConfigured(
  configuration: SuiviTravauxImportConfiguration,
  step: EtapeSuiviTravauxImport
): number | undefined {
  return configuration[step] !== UNCONFIGURED_COLUMN_INDEX
    ? configuration[step]
    : undefined;
}

export function convertInternalConfigurationToCreationRequest(
  configuration: SuiviTravauxImportConfiguration
): SuiviTravauxConfiguration {
  return {
    premiereLigne: configuration[EtapeSuiviTravauxImport.CHOIX_PREMIERE_LIGNES],
    referencePromoteur:
      configuration[EtapeSuiviTravauxImport.CHOIX_REFERENCE_PROMOTEUR],
    nomProgramme: configuration[EtapeSuiviTravauxImport.CHOIX_NOM_PROGRAMME],
    tranche: configuration[EtapeSuiviTravauxImport.CHOIX_TRANCHE],
    derniereEtapeAvancement:
      configuration[EtapeSuiviTravauxImport.CHOIX_DERNIERE_ETAPE_AVANCEMENT],
    dateAchevement: getColumnIndexIfConfigured(
      configuration,
      EtapeSuiviTravauxImport.CHOIX_DATE_ACHEVEMENT
    ),
  };
}

function getColumnIndexOrDefault(value: number | undefined): number {
  return value ?? UNCONFIGURED_COLUMN_INDEX;
}

export function convertConfigurationResponseToInternalConfiguration(
  response: SuiviTravauxConfiguration
): SuiviTravauxImportConfiguration {
  return {
    [EtapeSuiviTravauxImport.CHOIX_PREMIERE_LIGNES]: response.premiereLigne,
    [EtapeSuiviTravauxImport.CHOIX_REFERENCE_PROMOTEUR]:
      response.referencePromoteur,
    [EtapeSuiviTravauxImport.CHOIX_NOM_PROGRAMME]: response.nomProgramme,
    [EtapeSuiviTravauxImport.CHOIX_TRANCHE]: response.tranche,
    [EtapeSuiviTravauxImport.CHOIX_DERNIERE_ETAPE_AVANCEMENT]:
      response.derniereEtapeAvancement,
    [EtapeSuiviTravauxImport.CHOIX_DATE_ACHEVEMENT]: getColumnIndexOrDefault(
      response.dateAchevement
    ),
  };
}

export function convertSuiviTravauxMatrixToCreationRequest(
  dataOnlyMatrix: string[][],
  importConfiguration: SuiviTravauxImportConfiguration,
  correspondanceStep: Record<string, SuiviTravauxStep>
): Result<SuiviTravauxMassifCreateRequest[], string[]> {
  const configuration =
    convertInternalConfigurationToCreationRequest(importConfiguration);

  const createRequestList: SuiviTravauxMassifCreateRequest[] = [];
  const errors: string[] = [];

  for (const row of dataOnlyMatrix) {
    const derniereEtapeAvancement = row[configuration.derniereEtapeAvancement];

    const step = getSuiviTravauxStepByMappedKey(
      correspondanceStep,
      derniereEtapeAvancement
    );

    if (!step) {
      const error = `L'étape "${derniereEtapeAvancement}" n'a pas été reconnue`;
      errors.push(error);
      continue;
    }

    const createRequest: SuiviTravauxMassifCreateRequest = {
      referencePromoteur: row[configuration.referencePromoteur],
      tranche: row[configuration.tranche],
      nomProgramme: row[configuration.nomProgramme],
      derniereEtapeAvancement: step,
      dateAchevement: configuration.dateAchevement
        ? parseDate(row[configuration.dateAchevement])
        : undefined,
    };

    createRequestList.push(createRequest);
  }

  if (errors.length > 0) {
    return Err(errors);
  }

  return Ok(createRequestList);
}

export function extractReferenceTrancheDuplicateErrors(
  createRequestList: SuiviTravauxMassifCreateRequest[]
): [SuiviTravauxMassifCreateRequest[], SuiviReferenceTrancheDuplicateError[]] {
  const createRequestListByReferenceTranche = groupBy(
    createRequestList,
    (item) => `${item.referencePromoteur}_${item.tranche}`
  );

  return Object.entries(createRequestListByReferenceTranche).reduce<
    [SuiviTravauxMassifCreateRequest[], SuiviReferenceTrancheDuplicateError[]]
  >(
    (result, [referenceTranche, creationRequest]) => {
      if (creationRequest.length === 1) {
        result[0].push(creationRequest[0]);
      } else {
        const [referencePromoteur, tranche] = referenceTranche.split("_");
        result[1].push({ referencePromoteur, tranche });
      }

      return result;
    },
    [[], []]
  );
}
