import { OperationHeavy } from "@/domain/operationHeavy";

import { Demande } from "@/domain/demande";

import {
  ActeSecondaireLightArchived,
  AvenantLightArchived,
  DemandeArchive,
  OperationArchiveHeavy,
} from "@/domain/operationArchivedHeavy";
import { AccessType } from "@/domain/enum/accessType";
import { OperationAccessAsRole } from "@/domain/enum/operationAccessAsRole";
import { OperationType } from "@domain/enum/operationType";
import { SocieteSupportType } from "@domain/enum/societeSupportType";
import { AvenantLight } from "@/domain/avenantLight";
import { ActeSecondaireLight } from "@/domain/acteSecondaireLight";
import { isDemandeSigned } from "./demandeUtils";

export const DELAI_RETRACTATION_JOURS = 30;
export function createArchivedOperationHeavy(): OperationArchiveHeavy {
  return {
    actesPurged: false,
    id: "",
    name: "",
    codePostal: "",
    idSocieteSupportOrigine: "",
    societeSupportOrigine: {
      id: "",
      idPromoteur: "",
      creationInProgress: false,
      name: "",
      siren: "",
      type: SocieteSupportType.FILIALE,
      accessType: AccessType.NORMAL,
      promoteur: {
        id: "",
        name: "",
        roles: [],
        users: [],
        monoSignaturePromoteur: [],
        doubleSignaturePromoteur: [],
        promoteursSignatairesGfa: [],
        reportingColor: "",
        logoColor: "",
        idLogoGroup: "",
      },
    },
    idPromoteurOwner: "",
    referencePromoteur: "",
    referenceBanque: "",
    euroCentimeChiffreAffaire: 0,
    region: "",
    ville: "",
    demandeList: [],
    idBanqueList: [],
    type: OperationType.RESIDENTIELLE,
    operationAccessAsRole: OperationAccessAsRole.NONE,
    groups: [],
    adresseProgramme: "",
    lettreAccordDocumentList: [],
  };
}

export function isOperationArchived(
  operation: OperationHeavy | OperationArchiveHeavy
): operation is OperationArchiveHeavy {
  return "archived" in operation;
}

export function isDemandeArchived(
  demande: Demande | DemandeArchive | AvenantLight
): demande is DemandeArchive {
  return "signedDocumentMetadataList" in demande;
}

export function isAvenantLightArchived(
  avenant: Demande | AvenantLight | AvenantLightArchived
): avenant is AvenantLightArchived {
  return "signedDocumentMetadataList" in avenant;
}

export function isDemandeArchiveOrActeSecondaireLightArchivee(
  demande:
    | DemandeArchive
    | Demande
    | ActeSecondaireLightArchived
    | ActeSecondaireLight
): demande is DemandeArchive | ActeSecondaireLightArchived {
  return "signedDocumentMetadataList" in demande;
}

export function isArchivable(
  demandeList: Demande[] | DemandeArchive[]
): boolean {
  return (
    demandeList.filter((demande) => !isDemandeSigned(demande.step)).length ===
      0 &&
    demandeList.length > 0 &&
    demandeList.filter((demande) =>
      demande.acteSecondaireList.some(
        (acteSecondaire) => !isDemandeSigned(acteSecondaire.step)
      )
    ).length === 0
  );
}
